

export const Stars = (props: {
  stars: undefined | 0 | 1 | 2 | 3 | 4 | 5
}) => {
  return <>
    { props.stars !== undefined &&
      <div className="star-rating">
        { [...Array(props.stars)].map((_, x) => (
          <i key={`star-filled-${x}`} className="sr-star czi-star-filled active" />
        ))}
        { [...Array(5 - props.stars)].map((_, x) => (
          <i key={`star-${x}`} className="sr-star czi-star" />
        ))}
      </div>
    }
  </>;
}