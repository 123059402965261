import { combineReducers, configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { User } from '@stack/frontend-core/cls';
import {
  acceptInvitationEpic,
  areYouSureReducer,
  AreYouSureState,
  deleteNotificationEpic,
  ecommerceReducer,
  EcommerceState,
  friendsReducer,
  FriendsState,
  generateInvitationCodeEpic,
  getCartEpic,
  getUserEpic,
  listFlagsEpic,
  listFriendsEpic,
  listNotificationsEpic,
  loadingReducer,
  LoadingState,
  logoutEpic,
  notificationsReducer,
  NotificationsState,
  placeOrderEpic,
  readNotificationEpic,
  rejectInvitationEpic,
  scheduleToastEndEpic,
  setCartItemEpic,
  shareReducer,
  ShareState,
  showShareModalEpic,
  toastsReducer,
  ToastsState,
  updateShareEpic,
  updateUserEpic,
  userReducer,
} from '@stack/frontend-core/cls/redux';

import { accountReducer, AccountState, deleteProfileEpic, getAccountEpic, saveProfileEpic, } from 'cls/redux/account';
import {
  addCellarItemEpic,
  addCellarItemToShoppingListEpic,
  addMenuItemEpic,
  addMenuItemsToShoppingListEpic,
  addMenuItemToShoppingListEpic,
  addPartyToShoppingListEpic,
  basicAllEpic,
  basicReducer,
  BasicState,
  createShoppingListEpic,
  deleteCellarItemEpic,
  deleteCellarItemFromShoppingListEpic,
  deleteEpic,
  deleteManualItemFromShoppingListEpic,
  deleteMenuItemEpic,
  deleteMenuItemFromShoppingListEpic,
  deletePartyEpic,
  deletePartyFromShoppingListEpic,
  deleteShoppingListEpic,
  getPartyEpic,
  getPremenuEpic,
  listCellarItemsEpic,
  listEditableEpic,
  listMenuItemsEpic,
  listMenuItemsUserEpic,
  listPartiesEpic,
  listPremenusEpic,
  listProfileEpic,
  listShoppingListsEpic,
  revalidateCacheEpic,
  setCellarItemBrokenEpic,
  setPurchasedStatusOnShoppingListEpic,
  setShoppingListStatusEpic,
  startPremenuEditionEpic,
  unsetCellarItemBrokenEpic,
  updateMenuItemEpic,
  upsertManualItemToShoppingListEpic,
  upsertPartyEpic,
  upsertPremenuEpic,
  upsertRecipeEpic,
  usePremenuEpic,
} from 'cls/redux/basic';
import EpicType from 'cls/redux/epictype';
import { getRecommendationsEpic, recommendationsReducer, RecommendationsState } from 'cls/redux/recommendations';
import { sidebarReducer, SidebarState } from 'cls/redux/sidebar';


export interface StoreState {
  areYouSure: AreYouSureState;
  friends: FriendsState;
  loading: LoadingState,
  notifications: NotificationsState,
  share: ShareState;
  toasts: ToastsState;
  user: User | null;
  ecommerce: EcommerceState;

  account: AccountState;
  basic: BasicState;
  recommendations: RecommendationsState,
  sidebar: SidebarState;
}

const rootReducer = combineReducers({
  areYouSure: areYouSureReducer,
  friends: friendsReducer,
  loading: loadingReducer,
  notifications: notificationsReducer,
  share: shareReducer,
  toasts: toastsReducer,
  user: userReducer,
  ecommerce: ecommerceReducer,

  account: accountReducer,
  basic: basicReducer,
  recommendations: recommendationsReducer,
  sidebar: sidebarReducer,
});

const epicMiddleware = createEpicMiddleware();

function protect(epic: EpicType): EpicType {
  return (action$: Observable<AnyAction>, state$: any, deps: any)  =>
    epic(action$, state$, deps).pipe(
      catchError(error => of<AnyAction>({ type: 'EPIC_FAILURE', error })),
    );
}

const epics: EpicType[] = [
  getAccountEpic, saveProfileEpic, deleteProfileEpic,
  basicAllEpic, revalidateCacheEpic,
  addMenuItemEpic,
  deleteMenuItemEpic, updateMenuItemEpic, listMenuItemsEpic, listMenuItemsUserEpic,
  setShoppingListStatusEpic, createShoppingListEpic, deleteShoppingListEpic, listShoppingListsEpic,
  addMenuItemToShoppingListEpic, addMenuItemsToShoppingListEpic, deleteMenuItemFromShoppingListEpic,
  upsertManualItemToShoppingListEpic, deleteManualItemFromShoppingListEpic,
  addCellarItemToShoppingListEpic, deleteCellarItemFromShoppingListEpic,
  addPartyToShoppingListEpic, deletePartyFromShoppingListEpic,
  setPurchasedStatusOnShoppingListEpic,
  addCellarItemEpic, deleteCellarItemEpic, listCellarItemsEpic, setCellarItemBrokenEpic, unsetCellarItemBrokenEpic,
  upsertRecipeEpic,
  getRecommendationsEpic,
  listProfileEpic,
  upsertPartyEpic, deletePartyEpic, listPartiesEpic, getPartyEpic,
  upsertPremenuEpic, listPremenusEpic, getPremenuEpic, startPremenuEditionEpic, usePremenuEpic,
  listEditableEpic,
  deleteEpic,


  listFlagsEpic,
  acceptInvitationEpic, rejectInvitationEpic, listFriendsEpic, generateInvitationCodeEpic,
  listNotificationsEpic, deleteNotificationEpic, readNotificationEpic,
  showShareModalEpic, updateShareEpic,
  scheduleToastEndEpic,
  getUserEpic, updateUserEpic, logoutEpic,
  getCartEpic, setCartItemEpic, placeOrderEpic,
];

const protectedEpics = Array.from(epics).map(protect);

const rootEpic: Epic<any, any, any, any> = combineEpics(...protectedEpics);

const store = configureStore({
  reducer: rootReducer,
  middleware: new MiddlewareArray().concat(epicMiddleware),
});
epicMiddleware.run(rootEpic);

export default store;

export type StoreDispatch = typeof store.dispatch;