// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Urls from 'cls/Urls';

interface Props {
}
interface State {
}

export default class FooterMobileScene extends React.Component<Props, State> {

  render() {

    return (
      <footer className="mobile-footer bg-primary d-flex justify-content-between d-lg-none">
        <LinkContainer to="/">
          <Button variant="link" className="text-white" href="/">
            <i className="czi-view-grid" />
            {process.env.REACT_APP_MAIN}
          </Button>
        </LinkContainer>
        <LinkContainer to={Urls.DB_SELECT}>
          <Button variant="link" className="text-white" href={Urls.DB_SELECT}>
            <i className="czi-book" />
            {process.env.REACT_APP_DATABASE}
          </Button>
        </LinkContainer>
        <LinkContainer to={Urls.MENU}>
          <Button variant="link" className="text-white" href={Urls.MENU}>
            <i className="czi-edit" />
            {process.env.REACT_APP_MENU}
          </Button>
        </LinkContainer>
        <LinkContainer to={Urls.SHOPPING_LISTS}>
          <Button variant="link" className="text-white" href={Urls.SHOPPING_LISTS}>
            <i className="czi-delivery" />
            {process.env.REACT_APP_SHOPPING}
          </Button>
        </LinkContainer>
        <LinkContainer to={Urls.OTHER_LINKS}>
          <Button variant="link" className="text-white" href={Urls.OTHER_LINKS}>
            <i className="czi-menu" />
            {process.env.REACT_APP_OTHER}
          </Button>
        </LinkContainer>
      </footer>
    );
  }
}
