/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as Price from "./Price";
export interface IPremenuArgs {
    id: string;
    name: string;
    description: string;
    tags?: Array<string>;
    category: string;
    owner: string;
    ownerName: string;
    price?: Price.Price;
    updated: string;
}
export class Premenu {
    public id: string;
    public name: string;
    public description: string;
    public tags?: Array<string>;
    public category: string;
    public owner: string;
    public ownerName: string;
    public price?: Price.Price;
    public updated: string;
    constructor(args: IPremenuArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[name] is unset!");
        }
        if (args != null && args.description != null) {
            this.description = args.description;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[description] is unset!");
        }
        if (args != null && args.tags != null) {
            this.tags = args.tags;
        }
        if (args != null && args.category != null) {
            this.category = args.category;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[category] is unset!");
        }
        if (args != null && args.owner != null) {
            this.owner = args.owner;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[owner] is unset!");
        }
        if (args != null && args.ownerName != null) {
            this.ownerName = args.ownerName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[ownerName] is unset!");
        }
        if (args != null && args.price != null) {
            this.price = args.price;
        }
        if (args != null && args.updated != null) {
            this.updated = args.updated;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[updated] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Premenu");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.name);
            output.writeFieldEnd();
        }
        if (this.description != null) {
            output.writeFieldBegin("description", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.description);
            output.writeFieldEnd();
        }
        if (this.tags != null) {
            output.writeFieldBegin("tags", thrift.Thrift.Type.LIST, 4);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.tags.length);
            this.tags.forEach((value_1: string): void => {
                output.writeString(value_1);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.category != null) {
            output.writeFieldBegin("category", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.category);
            output.writeFieldEnd();
        }
        if (this.owner != null) {
            output.writeFieldBegin("owner", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.owner);
            output.writeFieldEnd();
        }
        if (this.ownerName != null) {
            output.writeFieldBegin("ownerName", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.ownerName);
            output.writeFieldEnd();
        }
        if (this.price != null) {
            output.writeFieldBegin("price", thrift.Thrift.Type.STRUCT, 8);
            this.price.write(output);
            output.writeFieldEnd();
        }
        if (this.updated != null) {
            output.writeFieldBegin("updated", thrift.Thrift.Type.STRING, 9);
            output.writeString(this.updated);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Premenu {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.id = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.name = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.description = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_5: Array<string> = new Array<string>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_6: string = input.readString();
                            value_5.push(value_6);
                        }
                        input.readListEnd();
                        _args.tags = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.category = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_8: string = input.readString();
                        _args.owner = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_9: string = input.readString();
                        _args.ownerName = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_10: Price.Price = Price.Price.read(input);
                        _args.price = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_11: string = input.readString();
                        _args.updated = value_11;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.name !== undefined && _args.description !== undefined && _args.category !== undefined && _args.owner !== undefined && _args.ownerName !== undefined && _args.updated !== undefined) {
            return new Premenu(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Premenu from input");
        }
    }
}
