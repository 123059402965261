import { Col, Container, Row } from 'react-bootstrap';
import _ from 'underscore';

import { PricingPlan } from 'content/landing/PricingPlan';

export const PricingPlans = () => {
  const prices = [
    {
      title: process.env.REACT_APP_LANDING_PRICING_INDIVIDUAL || "",
      price: {
        amount: "9.99",
        promoAmount: "0",
        currency: "PLN",
      },
      functions: (process.env.REACT_APP_LANDING_PRICING_INDIVIDUAL_FUNCTIONS || "").split(";"),
    },
    {
      title: process.env.REACT_APP_LANDING_PRICING_FAMILY || "",
      recommended: true,
      price: {
        amount: "15.99",
        promoAmount: "0",
        currency: "PLN",
      },
      functions: (process.env.REACT_APP_LANDING_PRICING_FAMILY_FUNCTIONS || "").split(";"),
    },
  ];

  const functionsMax = _.max(prices.map((x) => x.functions.length));

  return (
    <Container>
      <Row>
        { prices.map((x) =>
          <Col xs={12} md={6} key={x.title} className="mb-5 mb-md-0">
            <PricingPlan
              title={x.title}
              functions={x.functions}
              functionsMax={functionsMax}
              price={x.price}
              recommended={x.recommended}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}