import { Container } from 'react-bootstrap';

import { Features } from 'content/landing/Features';
import { Header } from 'content/landing/Header';
import FooterScene from 'page/footer';


export const LandingFeaturesScene = () => {
  return (
    <>
      <Header />
      <Container fluid={true}>
        <div className="d-flex flex-column align-items-md-center justify-md-content-center under-nav mb-5">
          <h1 className="display-1 mt-6">{process.env.REACT_APP_FEATURES}</h1>
          <p className="lead">{process.env.REACT_APP_FEATURES_LEAD}</p>
        </div>
        <Features />
      </Container>
      <FooterScene />
    </>
  )
};