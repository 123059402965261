// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import Account from 'cls/Account';
import { ActionTypes } from 'cls/redux/actions';
import { StoreDispatch, StoreState } from 'cls/store';

import 'page/main/style.css';
import 'ui/profiles-modal/style.css';

interface Props {
}
interface ReduxStateProps {
  account?: Account;
}
interface ReduxDispatchProps {
  setProfile: (profile: string) => void;
}
interface State {
  profile?: string;
}

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {
  constructor(props: Props & ReduxDispatchProps & ReduxStateProps) {
    super(props);
    this.clickProfile = this.clickProfile.bind(this);
  }

  clickProfile(profileId: string) {
    const { setProfile } = this.props;
    setProfile(profileId);
  }

  render() {
    const { account } = this.props;
    if (account === undefined) {
      return '';
    }

    return (
      <Modal show={account.noProfile} animation={false} centered={true} size="xl" onHide={() => ({})}>
        <Modal.Header closeButton>
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <a className="nav-link active">
                <i className="czi-unlocked mr-2 mt-n1" />
                Wybierz profil
              </a>
            </li>
          </ul>
        </Modal.Header>
        <Modal.Body className="tab-content py-4">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            { account.profiles.map((p, pi) => (
              <div className="d-flex flex-column align-items-center"
                   key={`profile-modal-${pi}`}
                   style={{cursor: "pointer"}}
                   onClick={() => { this.clickProfile(p.id) }}>
                <span className="profile-circle" style={{color: p.color}}>⬤</span>
                <span>{ p.name }</span>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}


const mapStateToProps = (state: StoreState): ReduxStateProps => {
  return ({
    account: state.account.account === null ? undefined : state.account.account,
  });
};

const mapDispatchToProps = (dispatch: StoreDispatch) => {
  return {
    setProfile: (profile: string) => dispatch({ type: ActionTypes.SetProfile, profile }),
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const PickProfileModal = () => <ConnectedScene />;

export default PickProfileModal;
