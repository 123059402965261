import * as React from 'react';
import { Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Location, useLocation } from 'react-router-dom';
import * as _ from 'underscore';

import { ActionTypes as CoreActionTypes } from '@stack/frontend-core/cls/redux';
import { HeaderCart } from '@stack/frontend-core/ui/header-cart';

import Account, { Profile } from 'cls/Account';
import Api from 'cls/Api';
import Ingredient from 'cls/Ingredient';
import Recipe from 'cls/Recipe';
import { ActionTypes } from 'cls/redux/actions';
import { StoreDispatch, StoreState } from 'cls/store';
import Urls from 'cls/Urls';
import HeaderNotifications from 'page/header/notifications';
import AddMultiButton from 'ui/add-multi-button';
import { Logo } from 'ui/logo';
import PickProfileModal from 'ui/profiles-modal';
import Search from 'ui/search';

import 'page/main/style.css';

interface Props {
  location: Location,
}
interface ReduxStateProps {
  account?: Account;
  profile?: string;
  recipes: Recipe[];
  ingredients: Ingredient[];
}
interface ReduxDispatchProps {
  getAccount: () => void;
  getUser: () => void;
  getCellar: () => void;
  getBasicData: () => void;
  recache: () => void;
  toggleSidebar: () => void;
  setProfile: (profile: string) => void;
  listMenuItemsUser: (_: string) => void;
  listFlags: () => void;
}
interface State {
  navHidden: boolean,
}

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {
  constructor(props: Props & ReduxDispatchProps & ReduxStateProps) {
    super(props);
    this.state = {navHidden: true};
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleSearchOnlyNav = this.toggleSearchOnlyNav.bind(this);
    this.clickNav = this.clickNav.bind(this);
    this.clickProfile = this.clickProfile.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const {
      getAccount, getBasicData, getUser,
      getCellar, listMenuItemsUser,
      profile, listFlags,
      recache,
    } = this.props;
    getAccount();
    getBasicData();
    getUser();
    getCellar();
    if (profile !== undefined) {
      listMenuItemsUser(profile);
    }
    listFlags();
    _.delay(() => recache(), 2 * 1000);
  }

  componentDidUpdate(prevProps: Props & ReduxStateProps & ReduxDispatchProps) {
    const { profile, listMenuItemsUser } = this.props;
    if (profile !== undefined && prevProps.profile !== profile) {
      listMenuItemsUser(profile);
    }
  }

  toggleNavState() {
    const { navHidden } = this.state;
    const { toggleSidebar } = this.props;
    if (this.isWithSidebar()) {
      toggleSidebar();
      if (!navHidden) {
        this.setState({navHidden: !navHidden});
      }
    } else {
      this.setState({navHidden: !navHidden});
    }
  }

  toggleSearchOnlyNavState() {
    //if (this.isWithSidebar()) {
      const { navHidden } = this.state;
      this.setState({navHidden: !navHidden});
    //}
  }

  toggleNav(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.toggleNavState();
  }

  toggleSearchOnlyNav(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.toggleSearchOnlyNavState();
  }

  clickNav() {
    this.toggleNavState();
  }

  clickProfile(profileId: string) {
    const { setProfile } = this.props;
    setProfile(profileId);
  }

  isWithSidebar() {
    const { location } = this.props;
    const path = decodeURI(location.pathname);
    return [
      process.env.REACT_APP_URL_INGREDIENTS || '',
      process.env.REACT_APP_URL_RECIPES || '',
      process.env.REACT_APP_URL_PREMENUS || '',
      process.env.REACT_APP_URL_MENU || '',
    ].includes(path)
  }

  logout() {
    Api.logout().subscribe(() => window.location.reload());
  }

  render() {
    const { navHidden } = this.state;
    const { location, account } = this.props;
    const path = location.pathname;

    const profile: Profile | undefined = account?.currentProfile;

    const mainLinks = <>
      <LinkContainer to={Urls.MENU}>
        <Nav.Link href={Urls.MENU}
                  className={`${path === Urls.MENU ? 'active' : ''}`}
                  onClick={this.clickNav}>
          {process.env.REACT_APP_MENU}
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={Urls.SHOPPING_LISTS}>
        <Nav.Link href={Urls.SHOPPING_LISTS}
                  className={`${path === Urls.SHOPPING_LISTS ? 'active' : ''}`}
                  onClick={this.clickNav}>
          {process.env.REACT_APP_SHOPPING}
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={Urls.RECIPES}>
        <Nav.Link href={Urls.RECIPES}
                  className={`${path === Urls.RECIPES ? 'active' : ''}`}
                  onClick={this.clickNav}>
          {process.env.REACT_APP_RECIPES}
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={Urls.INGREDIENTS}>
        <Nav.Link href={Urls.INGREDIENTS}
                  className={`${path === Urls.INGREDIENTS ? 'active' : ''}`}
                  onClick={this.clickNav}>
          {process.env.REACT_APP_INGREDIENTS}
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to={Urls.PREMENUS}>
        <Nav.Link href={Urls.PREMENUS}
                  className={`${path === Urls.PREMENUS ? 'active' : ''}`}
                  onClick={this.clickNav}>
          {process.env.REACT_APP_PLANS}
        </Nav.Link>
      </LinkContainer>
    </>;

    const lgLinks = <div className="navbar-nav">{ mainLinks}</div>;

    const logo = <LinkContainer to="/">
      <Navbar.Brand href="/" className="d-flex align-items-center mr-4 order-lg-1">
        <Logo className="mr-2" />
        <span>{process.env.REACT_APP_NAME}</span>
      </Navbar.Brand>
    </LinkContainer>;

    const searchIcon =
      <a className="navbar-tool d-flex d-lg-none" href="#" onClick={this.toggleSearchOnlyNav}>
        <span className="navbar-tool-tooltip">{process.env.REACT_APP_SEARCH}</span>
        <div className="navbar-tool-icon-box">
          <i className="navbar-tool-icon czi-search" />
        </div>
      </a>;

    const togglerIcon =
      <a className="navbar-toggler" type="button" href="#" onClick={this.toggleNav}>
        <span className="navbar-toggler-icon" />
      </a>;

    const nameDropdown = <>
      { account !== undefined &&
        <div className="navbar-tool dropdown ml-3">
          &nbsp; { profile?.name } &nbsp;
          <div className="navbar-tool-icon-box icon-profile dropdown-toggle"
               style={{backgroundColor: profile?.color}}
               aria-label={process.env.REACT_APP_ACCOUNT}
          >
            <i className="navbar-tool-icon" />
          </div>
          <ul className="dropdown-menu">
            { account.profiles.filter((x) => x.id !== account?.profile).map((p, pi) => (
              <li key={`profile-${pi}`}>
                <Button
                  className="dropdown-item"
                  onClick={() => this.clickProfile(p.id)}
                  aria-label={`${process.env.REACT_APP_PICK} ${p.name}`}
                  variant="link"
                >
                  <span style={{color: p.color}}>⬤</span> &nbsp; { p.name }
                </Button>
              </li>
            ))}
            <li className="dropdown-divider" />
            <li>
              <LinkContainer to={Urls.PROFILES}>
                <a className="dropdown-item" href={Urls.PROFILES}>{process.env.REACT_APP_ACCOUNT}</a>
              </LinkContainer>
            </li>
            <li><a className="dropdown-item" href={Urls.HELP} target="_blank" rel="noreferrer">
              {process.env.REACT_APP_HELP}
            </a></li>
            <li>
              <Button
                className="dropdown-item"
                style={{cursor: 'pointer'}}
                onClick={() => this.logout()}
                variant="link"
              >
                {process.env.REACT_APP_LOGOUT}
              </Button>
            </li>
          </ul>
        </div>
      }
    </>;

    const headerLg = <header className="bg-light box-shadow-sm fixed-top d-none d-lg-block">
      <Navbar bg="light" expand="lg" sticky="top">
        <div className="container-fluid">
          { logo }
          <div className="d-flex flex-row">
            <a className="navbar-toggler" type="button" href="#" onClick={this.toggleNav}>
              <span className="navbar-toggler-icon" />
            </a>
          </div>
          <Navbar.Collapse id="navbar-collapse" className={`mr-auto order-lg-2 ${navHidden ? "" : " show"}`}>
            { lgLinks }
            <AddMultiButton />
            <Search inNav={true} className="mx-lg-4" />
            <div className="navbar-toolbar flex-shrink-0">
              <HeaderNotifications />
            </div>
            <div className="navbar-toolbar flex-shrink-0">
              { nameDropdown }
            </div>
            <HeaderCart />
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>

    const headerMd = <header className="bg-light box-shadow-sm fixed-top d-block d-lg-none">
      <Navbar bg="light" expand="lg" sticky="top">
        <div className="container-fluid">
          { logo }
          <div className="d-flex flex-row">
            { searchIcon }
            { this.isWithSidebar() && togglerIcon }
          </div>
          <Navbar.Collapse id="navbar-collapse" className={`mr-auto order-lg-2 ${navHidden ? "" : " show"}`}>
            <Search inNav={true} className="mx-lg-4" />
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>;

    return (
      <>
        { account !== undefined &&
          <PickProfileModal />
        }
        { headerLg }
        { headerMd }
      </>
    );
  }
}

const mapStateToProps = (state: StoreState): ReduxStateProps => {
  return ({
    account: state.account.account === null ? undefined : state.account.account,
    profile: state.account.profile === null ? undefined : state.account.profile,
    ingredients: state.basic.ingredients,
    recipes: state.basic.recipes,
  });
};

const mapDispatchToProps = (dispatch: StoreDispatch) => {
  return {
    listMenuItemsUser: (profile: string) => dispatch({ type: ActionTypes.ListMenuItemsUser, profile }),
    getAccount: () => dispatch({ type: ActionTypes.GetAccount }),
    getUser: () => dispatch({ type: CoreActionTypes.GetUser }),
    getCellar: () => dispatch({ type: ActionTypes.ListCellarItems }),
    getBasicData: () => dispatch({ type: ActionTypes.GetAllBasic }),
    recache: () => dispatch({ type: CoreActionTypes.RevalidateCache }),
    toggleSidebar: () => dispatch({ type: ActionTypes.ToggleSidebar }),
    setProfile: (profile: string) => dispatch({ type: ActionTypes.SetProfile, profile }),
    listFlags: () => dispatch({ type: CoreActionTypes.ListFlags }),
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const Header = () => (
  <ConnectedScene location={useLocation()} />
);

export default Header;
