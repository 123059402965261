import Measure from 'cls/Measure';
import Portion from 'cls/Portion';
import Quantity from 'cls/Quantity';
import { Q } from 'thriftgen';

export default class X {
  quantity: Quantity;
  q: Q;
  p: Portion | undefined;

  constructor(q: Q) {
    this.quantity = Quantity.init4(q, {});
    this.q = new Q({n: q.n, m: q.m});
  }

  setQuantity(quantity: Quantity) {
    this.quantity = quantity;
    this.q = new Q({n: quantity.n, m: quantity.m.q});
  }
  setQ(q: Q | undefined) {
    if (q === undefined) { return; }
    this.quantity = Quantity.init4(q, this.quantity.p);
    this.q = q;
  }
  setN(n: number) {
    this.quantity.n = n;
    this.q.n = n;
  }
  setMeasure(m: Measure) {
    this.quantity.m = m;
    this.q.m = m.q;
  }

  setPortion(portion: Portion | undefined) {
    const p = portion || new Portion({});
    this.quantity = new Quantity(this.quantity.n, this.quantity.m, p);
  }
  multiply(factor: number) {
    this.quantity.multiply(factor);
    this.q = new Q({n: this.quantity.n, m: this.quantity.m.q});
  }

  static zero(): X {
    return new X(Quantity.defaultZero().q);
  }
}
