
import {Urls as CoreUrls} from '@stack/frontend-core/cls/Urls';

export default class Urls extends CoreUrls {
  static ABOUT_US = process.env.REACT_APP_URL_ABOUT_US || '';
  static ACCOUNT = process.env.REACT_APP_URL_ACCOUNT || '';
  static CALENDAR = process.env.REACT_APP_URL_CALENDAR || '';
  static COMMUNITY = process.env.REACT_APP_URL_COMMUNITY || '';
  static CELLAR_ADD = process.env.REACT_APP_URL_CELLAR_ADD || '';
  static CELLAR = process.env.REACT_APP_URL_CELLAR || '';
  static DB_SELECT = process.env.REACT_APP_URL_DB_SELECT || '';
  static FREQ_DAILY = process.env.REACT_APP_URL_FREQ_DAILY || '';
  static FREQ_WEEKLY = process.env.REACT_APP_URL_FREQ_WEEKLY || '';
  static FREQ_YEARLY = process.env.REACT_APP_URL_FREQ_YEARLY || '';
  static FEATURES = process.env.REACT_APP_URL_FEATURES || '';
  static INGREDIENT = process.env.REACT_APP_URL_INGREDIENT || '';
  static INGREDIENTS = process.env.REACT_APP_URL_INGREDIENTS || '';
  static MENU = process.env.REACT_APP_URL_MENU || '';
  static NUTRITION = process.env.REACT_APP_URL_NUTRITION || '';
  static OTHER_LINKS = process.env.REACT_APP_URL_OTHER_LINKS || '';
  static PARTY = process.env.REACT_APP_URL_PARTY || '';
  static PARTY_EDIT = process.env.REACT_APP_URL_PARTY_EDIT || '';
  static PARTY_NEW = process.env.REACT_APP_URL_PARTY_NEW || '';
  static PARTIES = process.env.REACT_APP_URL_PARTIES || '';
  static PREMENU = process.env.REACT_APP_URL_PREMENU || '';
  static PREMENUS = process.env.REACT_APP_URL_PREMENUS || '';
  static PREMENUS_MY = process.env.REACT_APP_URL_PREMENUS_MY || '';
  static PREMENU_EDIT = process.env.REACT_APP_URL_PREMENU_EDIT || '';
  static PREMENU_NEW = process.env.REACT_APP_URL_PREMENU_NEW || '';
  static PRICING = process.env.REACT_APP_URL_PRICING || '';
  static PROFILES = process.env.REACT_APP_URL_PROFILES || '';
  static MY_GENERAL = process.env.REACT_APP_URL_MY_GENERAL || '';
  static RECIPE = process.env.REACT_APP_URL_RECIPE || '';
  static RECIPES = process.env.REACT_APP_URL_RECIPES || '';
  static RECIPES_MY = process.env.REACT_APP_URL_RECIPES_MY || '';
  static RECIPE_EDIT = process.env.REACT_APP_URL_RECIPE_EDIT || '';
  static RECIPE_NEW = process.env.REACT_APP_URL_RECIPE_NEW || '';
  static SHOPPING_LIST = process.env.REACT_APP_URL_SHOPPING_LIST || '';
  static SHOPPING_LISTS = process.env.REACT_APP_URL_SHOPPING_LISTS || '';
  static T_GENERAL = process.env.REACT_APP_URL_T_GENERAL || '';
  static T_VITAMINS = process.env.REACT_APP_URL_T_VITAMINS || '';
  static T_MINERALS = process.env.REACT_APP_URL_T_MINERALS || '';
  static T_LIPIDS = process.env.REACT_APP_URL_T_LIPIDS || '';
  static T_PROTEINS = process.env.REACT_APP_URL_T_PROTEINS || '';
  static T_CARBOHYDRATES = process.env.REACT_APP_URL_T_CARBOHYDRATES || '';
  static USER_INGREDIENT_LISTS = process.env.REACT_APP_URL_USER_INGREDIENT_LISTS || '';
  static USER_RECIPE_LISTS = process.env.REACT_APP_URL_USER_RECIPE_LISTS || '';
  static USER_NUTRITION_SUMMARY_INTRO = process.env.REACT_APP_URL_USER_NUTRITION_SUMMARY_INTRO || '';
  static USER_NUTRITION_SUMMARY = process.env.REACT_APP_URL_USER_NUTRITION_SUMMARY || '';
  static HELP = process.env.REACT_APP_URL_HELP || '';
  static SEND_FEEDBACK = '/send-feedback';

  static USER_INGREDIENT_LISTS_YEAR = Urls.USER_INGREDIENT_LISTS.replace(":frequency", Urls.FREQ_YEARLY);
  static RECIPE_ID = (id: string) => Urls.RECIPE.replace(":recipeId", id);
  static RECIPE_EDIT_ID = (id: string) => Urls.RECIPE_EDIT.replace(":recipeId", id);
  static INGREDIENT_ID = (id: string) => Urls.INGREDIENT.replace(":ingredientId", id);
  static USER_NUTRITION_SUMMARY_ID = (id: string, freq: string, type: string) => Urls.USER_NUTRITION_SUMMARY.replace(":profileId", id).replace(":frequency", freq).replace(":type", type);
  static PREMENU_ID = (id: string) => Urls.PREMENU.replace(":premenuId", id);
  static PREMENU_EDIT_ID = (id: string) => Urls.PREMENU_EDIT.replace(":premenuId", id);
  static SHOPPING_LIST_ID = (id: string) => Urls.SHOPPING_LIST.replace(":id", id);
  static PARTY_ID = (id: string) => Urls.PARTY.replace(":id", id);
  static PARTY_EDIT_ID = (id: string) => Urls.PARTY_EDIT.replace(":partyId", id);
}