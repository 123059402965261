
export const NavMedia = (props: {
  icons: [string, string, string, string],
  title: string,
  textEnvVarPrefix: string,
}) => {
  return <>
    <h3 className="h6 font-weight-normal text-center mt-2">
      {props.title}
    </h3>
    <ul className="nav nav-tabs media-tabs nav-justified">
      {[1, 2, 3, 4].map((x) =>
        <li key={x} className={`nav-item ${x === 1 && 'active'}`}>
          <div className={`nav-link ${x === 1 && 'active'}`}>
            <div className="media align-items-center">
              <div className="media-tab-media mr-3"><i className={props.icons[x - 1]} /></div>
              <div className="media-body">
                <div className="media-tab-subtitle text-muted font-size-xs mb-1">{process.env[`REACT_APP_STEP${x}`]}</div>
                <h6 className="media-tab-title text-nowrap mb-0">{process.env[`${props.textEnvVarPrefix}${x}`]}</h6>
              </div>
            </div>
          </div>
        </li>
      )}
    </ul>
  </>;
}

export const HowToStart = () => {
  return <NavMedia
    icons={["czi-pot", "czi-rocket", "czi-delivery", "czi-heart"]}
    title={process.env.REACT_APP_HOW_TO_START || ''}
    textEnvVarPrefix="REACT_APP_HOW_TO_START_S"
  />;
}

export const HowItWorks = () => {
  return <NavMedia
    icons={[
      "czi-rocket",
      "czi-delivery",
      "czi-pot",
      "czi-heart",
    ]}
    title={process.env.REACT_APP_HOW_TO_START || ''}
    textEnvVarPrefix="REACT_APP_HOW_IT_WORKS_S"
  />;
}