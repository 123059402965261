import { ManualItem as ThriftManualItem } from 'thriftgen/ManualItem';

export default class ManualItem {
  type: string = "manual-item";

  id: string;
  text: string;

  constructor(x: ThriftManualItem) {
    this.id = x.id;
    this.text = x.text;
  }

  static init = (xs: ThriftManualItem[]) => xs.map((x) => new ManualItem(x));
  static empty(): ManualItem {
    return new ManualItem(new ThriftManualItem({
      id: '',
      text: '',
    }))
  }

  thrift() {
    return new ThriftManualItem({
      id: this.id,
      text: this.text,
    });
  }
}
