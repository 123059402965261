import * as _ from 'underscore';

import Menu from 'cls/Menu';
import MenuItemsBase from 'cls/MenuItemsBase';
import Price from 'cls/Price';
import { Premenu as ThriftPremenu } from 'thriftgen/Premenu';

export default class Premenu extends MenuItemsBase {
  id: string;
  name: string;
  description: string;
  tags: string[];
  category: string;
  owner: string;
  ownerName: string;
  menu: Menu;
  price: Price;

  type = "premenu";

  constructor(x: ThriftPremenu, menu: Menu) {
    super(menu.items);
    this.id = x.id;
    this.name = x.name;
    this.description = x.description;
    this.category = x.category;
    this.tags = x.tags || [];
    this.owner = x.owner;
    this.ownerName = x.ownerName;
    this.menu = menu;
    this.price = new Price(x.price);
  }

  get minDate(): string {
    const xs = _.chain(this.items.map((x) => x.prepDay)).sort().compact().value();
    if (xs.length > 0) { return xs[0]; }
    return "";
  }

  copy(): Premenu {
    return new Premenu(this.thrift(), this.menu.copy());
  }

  thrift(): ThriftPremenu {
    return new ThriftPremenu({
      id: this.id,
      name: this.name,
      description: this.description,
      category: this.category,
      tags: this.tags,
      owner: this.owner,
      ownerName: this.ownerName,
      updated: "",
    });
  }

  multiply(factor: number): Premenu {
    const n = this.copy();
    n.items.forEach((x) => x.multiply(factor));
    return n;
  }

  edit(field: string, value: string) {
    if (
      field === 'name' ||
      field === 'description' ||
      field === 'category'
    ) {
      this[field] = value;
    }
    if (field === 'category' && this.tags.every((x) => x !== value)) {
      this.tags.push(value);
    }
  }

  toggleTag(tag: string) {
    const index = this.tags.indexOf(tag, 0);
    if (index === -1) {
      this.tags.push(tag);
    } else {
      this.tags.splice(index, 1);
    }
  }

  static empty(): Premenu {
    const profile = localStorage.getItem("profile") || "";
    return new Premenu(new ThriftPremenu({
      id: "",
      name: "",
      description: "",
      owner: profile,
      ownerName: "",
      tags: [] as string[],
      category: "",
      updated: "",
    }), Menu.empty());
  }

  static init(xs: ThriftPremenu[], menu: Menu): Premenu[] {
    return xs.map((x) => new Premenu(x, menu));
  }

  static allTags(xs: Premenu[]): string[] {
    return _.chain(xs)
      .map((x) => x.tags)
      .flatten()
      .uniq()
      .value();

  }

  static sort(sortField: string | undefined, sortOrderAsc: boolean) {
    return (a: Premenu, b: Premenu) => {
      const m = sortOrderAsc ? 1 : -1;
      if (sortField === 'name' || sortField === undefined) {
        return a.name.localeCompare(b.name) * m;
      }
      return 0;
    }
  }
}
