import * as _ from 'underscore';

import { Friend, Utils as CoreUtils } from '@stack/frontend-core/cls';

import { Meal } from 'cls/Account';
import Ingredient from 'cls/Ingredient';
import Measure from 'cls/Measure';
import Recipe from 'cls/Recipe';

export default class Utils extends CoreUtils {

  static randomStr = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static newShortId = () => Utils.randomStr(5);

  static polishPlural(
    singularNominativ: string,
    singularGenitive: string,
    pluralNominativ: string,
    pluralGenitive: string,
    value: number
  ): string {
    if (!Number.isInteger(value)) {
      return singularGenitive;
    } else if (value === 1) {
       return singularNominativ;
    } else if (
      value % 10 >= 2 &&
      value % 10 <= 4 &&
      (value % 100 < 10 || value % 100 >= 20)
    ) {
      return pluralNominativ;
    } else {
      return pluralGenitive;
    }
  }

  static parseNum(num: string): [number, Measure] {
   let n = num.replace(/[^\-?0-9.]+/g, '');
   let m = num.replace(/[-0-9. ]/g, '');
   if (n === "") { n = "1"; }
   return [Number(n), Measure.init(m)];
  }

  static multiplyNum(num: string, x: number): string {
    const parsed = Utils.parseNum(num);
    const n = parsed[0] * x;
    const m = parsed[1];
    return m.text(n);
  }

  static yesterdayDate(): string {
    const today = new Date()
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1)
    return Utils.printDate(yesterday);
  }

  static todayDate(): string {
    return Utils.printDate(new Date());
  }

  static tomorrowDate(): string {
    const today = new Date()
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1)
    return Utils.printDate(tomorrow);
  }

  static futureDates(xs: string[]): string[] {
    const today = Utils.todayDate();
    return xs.filter((x) => x >= today);
  }

  static prettyPrintDate(x: string): string {
    const yesterday = Utils.yesterdayDate();
    const today = Utils.todayDate();
    const tomorrow = Utils.tomorrowDate();
    if (today === x) { return process.env.REACT_APP_TODAY || ''; }
    if (tomorrow === x) { return process.env.REACT_APP_TOMORROW || ''; }
    if (yesterday === x) { return process.env.REACT_APP_YESTERDAY || ''; }
    return x;
  }

  static prettyPrintDateTime(xx: string): string {
    const yesterday = Utils.yesterdayDate();
    const today = Utils.todayDate();
    const tomorrow = Utils.tomorrowDate();
    const x = xx.slice(0, 10);
    if (today === x) { return process.env.REACT_APP_TODAY || ''; }
    if (tomorrow === x) { return process.env.REACT_APP_TOMORROW || ''; }
    if (yesterday === x) { return process.env.REACT_APP_YESTERDAY || ''; }
    return x;
  }

  static dateDayName(s: string): string {
    const d = new Date(s);
    const names: {[key: number]: string} = {
      0: process.env.REACT_APP_DAY_7 || '',
      1: process.env.REACT_APP_DAY_1 || '',
      2: process.env.REACT_APP_DAY_2 || '',
      3: process.env.REACT_APP_DAY_3 || '',
      4: process.env.REACT_APP_DAY_4 || '',
      5: process.env.REACT_APP_DAY_5 || '',
      6: process.env.REACT_APP_DAY_6 || ''
    };
    return names[d.getDay()];
  }

  static tagsName(tags: string[]): string {
    return _.map(tags, (x) => Utils.tagName(x)).join(" i ");
  }

  static tagName(tag: string | undefined): string {
   if (tag === undefined) { return ""; }
   if (tag.startsWith("owner_")) {
     return tag.replace("owner_", "");
   }
   return this.tagNames[tag];
  }

  static capitalize(s: string): string {
    return s[0].toUpperCase() + s.substring(1);
  }
  static tagNames: { [key: string]: string } = {
    '5b339254da14a5a2a37d225739793077': process.env.REACT_APP_TAG_OTHER || '',
    '0b5e1495d9d689aaab9c5f8db5426144': process.env.REACT_APP_TAG_FRUIT || '',
    '0b5e1495d9d689aaab9c5f8db5427533': process.env.REACT_APP_TAG_VEGETABLE || '',
    'eb80d6a0b7866896ada92dccb40a1927': process.env.REACT_APP_TAG_MEAT || '',
    'acfe5e25e7d9ae8967b7f9b9378e2c11': process.env.REACT_APP_TAG_FISH || '',
    '771136fd6b0d281d2fd14288a79d87d3': process.env.REACT_APP_TAG_DAIRY || '',
    'eb80d6a0b7866896ada92dccb40a1fb0': process.env.REACT_APP_TAG_SPICES_FRESH_F || '',
    'eb80d6a0b7866896ada92dccb40a1d81': process.env.REACT_APP_TAG_SPICES || '',
    'k7oYbFJmxpSGqaKHL539AhbP2CdemNei': process.env.REACT_APP_TAG_SPICES_MIXED_F || '',
    '5084db7339d0eb35164c5550d9ca8ec6': process.env.REACT_APP_TAG_RECIPE_BASE || '',
    '07873b15102892ef2c0c1e4464344808': process.env.REACT_APP_TAG_RECIPE_BREAKFAST || '',
    'a6ae51b22f6067272980b748e68e9dd6': process.env.REACT_APP_TAG_RECIPE_LUNCH || '',
    'f06811382117d130498dd73d7907937a': process.env.REACT_APP_TAG_RECIPE_SOUP || '',
    'eb80d6a0b7866896ada92dccb40a2cea': process.env.REACT_APP_TAG_VEGETABLE_ONION_F || '',
    '771136fd6b0d281d2fd14288a79d97d1': process.env.REACT_APP_TAG_VEGETABLE_PUMPKIN_F || '',
    '07873b15102892ef2c0c1e4464343959': process.env.REACT_APP_TAG_VEGETABLE_CABBAGE_F || '',
    'f06811382117d130498dd73d790791f5': process.env.REACT_APP_TAG_VEGETABLE_ROOT_F || '',
    'eb80d6a0b7866896ada92dccb40a31a5': process.env.REACT_APP_TAG_VEGETABLE_LEAF_F || '',
    '9cbfe4e42bb0aa7e71bf069fd91e7f21': process.env.REACT_APP_TAG_VEGETABLE_NIGHTSHADE_F || '',
    'ef30020dbf2e67cc3b19c59c897b4463': process.env.REACT_APP_TAG_RECIPE_BENTO || '',
    'eb80d6a0b7866896ada92dccb40a0bd9': process.env.REACT_APP_TAG_RECIPE_BENTO_500 || '',
    '771136fd6b0d281d2fd14288a79d807c': 'Bulwa',
    '9cbfe4e42bb0aa7e71bf069fd91e70f6': 'Bylina',
    '0b5e1495d9d689aaab9c5f8db5424579': process.env.REACT_APP_TAG_SUGAR || '',
    '7c10d3fb00f5980dbb500b464e4e1cc3': process.env.REACT_APP_TAG_FRUIT_CITRUS_F || '',
    '0b5e1495d9d689aaab9c5f8db54252a4': process.env.REACT_APP_TAG_RECIPE_DESSERT || '',
    'acfe5e25e7d9ae8967b7f9b9378e19af': process.env.REACT_APP_TAG_FRUIT_TREE_F || '',
    'f06811382117d130498dd73d79077c34': process.env.REACT_APP_TAG_EXTRACT || '',
    'acfe5e25e7d9ae8967b7f9b9378e1f66': process.env.REACT_APP_TAG_FUNGUS || '',
    'e83c5453aed3c1becc26d6d8165dce60': process.env.REACT_APP_TAG_EGG || '',
    'a6ae51b22f6067272980b748e68e7f02': process.env.REACT_APP_TAG_PICKLES || '',
    'a6ae51b22f6067272980b748e68e8e40': process.env.REACT_APP_TAG_FRUIT_BUSH_F || '',
    'wrdapcdpnpq3v8ozyh7zrn6762so9101': process.env.REACT_APP_TAG_FRUIT_BERRY_F || '',
    '07873b15102892ef2c0c1e446434159a': process.env.REACT_APP_TAG_FLOUR || '',
    'a6ae51b22f6067272980b748e68e9044': process.env.REACT_APP_TAG_DAIRY_YOGURT || '',
    'e83c5453aed3c1becc26d6d8165dd3f9': process.env.REACT_APP_TAG_DAIRY_BUTTER_COTTAGE_CHEESE || '',
    '07873b15102892ef2c0c1e44643422ec': process.env.REACT_APP_TAG_DRINK || '',
    'e83c5453aed3c1becc26d6d8165dd43d': process.env.REACT_APP_TAG_NUTS || '',
    '6ae287f1d8da8d5c0ac77b3e3563c668': process.env.REACT_APP_TAG_SEAFOOD || '',
    'e83c5453aed3c1becc26d6d8165ddeb6': process.env.REACT_APP_TAG_PASTA || '',
    '07873b15102892ef2c0c1e44643424c6': process.env.REACT_APP_TAG_BREAD || '',
    '771136fd6b0d281d2fd14288a79d8ba0': process.env.REACT_APP_TAG_CHEESE || '',
    'f06811382117d130498dd73d790788a0': process.env.REACT_APP_TAG_JUICE || '',
    'eb80d6a0b7866896ada92dccb40a2804': process.env.REACT_APP_TAG_SAUCE || '',
    '0b5e1495d9d689aaab9c5f8db54267a5': process.env.REACT_APP_TAG_RECIPE_SALAD || '',
    '7c10d3fb00f5980dbb500b464e4e2b55': process.env.REACT_APP_TAG_DRIES || '',
    'acfe5e25e7d9ae8967b7f9b9378e2d1e': process.env.REACT_APP_TAG_HAM || '',
    '07873b15102892ef2c0c1e4464342bac': process.env.REACT_APP_TAG_HAM_DRIED || '',
    'a6ae51b22f6067272980b748e68ea865': process.env.REACT_APP_TAG_RECIPE_SOUP_CREAM || '',
    'e83c5453aed3c1becc26d6d8165dead2': process.env.REACT_APP_TAG_RECIPE_SOUP_SUMMER || '',
    'f06811382117d130498dd73d79079779': process.env.REACT_APP_TAG_RECIPE_SOUP_WATER || '',
    'z7R0jneNGhpYB1mNWz4hosNuMg7AWHfk': process.env.REACT_APP_TAG_FAT || '',
    'WAULWYnOnehU7g2UlG1E9GXsdAaVmrvp': process.env.REACT_APP_TAG_COCONUT || '',
    'whdQ4htYH4UBX6QsXMRRgB366WjY0oDI': process.env.REACT_APP_TAG_COFFEE || '',
    'R4Ttt3GNdpRyh1rqdywDAGKyoNYOKoKq': process.env.REACT_APP_TAG_TEA || '',
    'kk5OGplg39LRUdCKzW9cwlwGKcN563XX': process.env.REACT_APP_TAG_CHOCOLATE || '',
    'inne': process.env.REACT_APP_TAG_OTHER || '',
    'all': '',
  };

  static mealNames: { [key: string]: string } = {
    "1": process.env.REACT_APP_MEAL_NAME_BREAKFAST || '',
    "2": process.env.REACT_APP_MEAL_NAME_SECOND_BREAKFAST || '',
    "3": process.env.REACT_APP_MEAL_NAME_SOUP || '',
    "4": process.env.REACT_APP_MEAL_NAME_MIDDAY_MEAL || '',
    "5": process.env.REACT_APP_MEAL_NAME_SECOND_LUNCH || '',
    "6": process.env.REACT_APP_MEAL_NAME_DINNER || '',
    "7": process.env.REACT_APP_MEAL_NAME_COFFEE || '',
    "8": process.env.REACT_APP_MEAL_NAME_SNACK || '',
    "9": process.env.REACT_APP_MEAL_NAME_LUNCH || '',
    "10": process.env.REACT_APP_MEAL_NAME_FRUIT || '',
    "101": process.env.REACT_APP_MEAL_NAME_1 || '',
    "102": process.env.REACT_APP_MEAL_NAME_2 || '',
    "103": process.env.REACT_APP_MEAL_NAME_3 || '',
    "104": process.env.REACT_APP_MEAL_NAME_4 || '',
    "21": process.env.REACT_APP_MEAL_NAME_LUNCH_1 || '',
    "22": process.env.REACT_APP_MEAL_NAME_LUNCH_2 || '',
    "": process.env.REACT_APP_MEAL_NAME_OTHER || '',
  };

  static allMeals(): {id: string, name: string}[] {
    return _.chain(Utils.mealNames).allKeys().map((k) => new Meal(k)).value();
  };

 static sortTags(x1: string, x2: string) {
   if (x1 === "all") { return -1; }
   if (x2 === "all") { return 1; }
  return Utils.tagName(x1).localeCompare(Utils.tagName(x2));
 }

 static getWeekNumber(x: Date) {
   const d = new Date(Date.UTC(x.getFullYear(), x.getMonth(), x.getDate()));
   const dayNum = d.getUTCDay() || 7;
   d.setUTCDate(d.getUTCDate() + 4 - dayNum);
   const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
   return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
 };

 static week(s: string): string {
   if (s === "") { return ''; }
   const d = new Date(s);
   if (d === undefined) { return ''; }
   const week = Utils.getWeekNumber(d);
   const year = d.getFullYear();
   return `${year}-${(week + '').padStart(2, '0')}`;
 }
  static year(s: string): string {
    if (s === "") { return ''; }
    const d = new Date(s);
    if (d === undefined) { return ''; }
    return d.getFullYear().toString(10);
  }

  static xDaysAgo(x: number): string {
    const d = new Date(Date.now() - x * 24 * 3600 * 1000);
    return Utils.printDate(d);
  }

  static xMinutesAgo(x: number): string {
    const d = new Date(Date.now() -  x * 60 * 1000);
    return Utils.printDateTime(d);
  }

  static xUTCMinutesAgo(x: number): string {
    const d = new Date(Date.now() -  x * 60 * 1000);
    return Utils.printUTCDateTime(d);
  }

  static search(
    text: string,
    ingredients: Ingredient[],
    recipes: Recipe[],
    friends: Friend[],
  ): {id: string, url: string, text: string, type: string}[] {
    const rx = recipes
      .map((x) => { x.setRecipes(recipes); return x; })
      .filter((x) => x.searchMatch(text))
      .map((x) => { return {
        id: x.id,
        url: `/przepis/${x.id}`,
        text: x.name,
        type: 'R'
      }; });
    const ix = ingredients
      .filter((x) => x.searchMatch(text))
      .map((x) => { return {
        id: x.id,
        url: `/składnik/${x.id}`,
        text: x.name,
        type: 'I'
      }; });
    const fx = friends
      .filter((x) => x.searchMatch(text))
      .map((x) => { return {
        id: x.id,
        url: `/profil/${x.id}`,
        text: x.name,
        type: 'F'
      }; });

    return _.sortBy([...rx, ...ix, ...fx], 'title');
  }

  static getCookie(cname: string): string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  static isLoggedIn() {
    const uid = Utils.getCookie('uid');
    const sid = Utils.getCookie('sid');
    return uid !== "" && sid !== "";
  }

  static downloadString(text: string, filename: string) {
    const blob = new Blob([text], { type: 'text/csv' });
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/csv' , a.download, a.href].join(':');
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);
  }

  static t(name: string, args: {[k: string]: string}): string {
    let result: string = process.env[name] || "";

    _.chain(args).each((v, k) => {
      result = result.replace(`{${k}}`, v);
    });

    return result;
  }
}
