import { Card, ListGroup } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

import Urls from 'cls/Urls';

const PriceAmount = (props: {
  amount: string,
  currency: string,
  del?: boolean,
  className?: string,
}) => {
  const amountParts = props.amount.split(".");

  if (props.del) {
    return <del className={`text-muted font-size-lg ${props.className !== undefined && props.className}`}>
      { amountParts[0] }
      { amountParts.length > 1 && <>.{amountParts[1]}</> }
      zł
    </del>;
  }

  return <span className={`h3 font-weight-normal text-accent ${props.className !== undefined && props.className}`}>
    { amountParts[0] }
    { amountParts.length > 1 && <>.<small>{amountParts[1]}</small></> }
    zł
  </span>;
}

const PriceAmountWithDiscount = (props: {
  amount: string,
  promoAmount?: string,
  currency: string,
}) => {

  if (props.promoAmount !== undefined) {
    return <>
      <PriceAmount amount={props.promoAmount} currency={props.currency} />
      <PriceAmount amount={props.amount} del={true} currency={props.currency} className="ml-1"/>
    </>;
  }

  return <PriceAmount amount={props.amount} currency={props.currency} />;
}

export const PricingPlan = (props: {
  title: string;
  recommended?: boolean,
  price: {
    amount: string,
    promoAmount?: string,
    currency: string,
    period?: string,
  },
  functions: string[];
  functionsMax: number;
}) => {

  return (
    <Card>
      <Card.Header className="p-0">
        { props.recommended ?
            <div
              className="bg-info text-white mb-3 px-4"
              style={{"borderRadius": "calc(0.4375rem - 1px) calc(0.4375rem - 1px) 0 0"}}
            >
              {process.env.REACT_APP_RECOMMENDS}
            </div> :
            <div className="mb-3"><b>&nbsp;</b></div>
        }
        <div className="pb-4 px-4">
          <b>{props.title}</b>
        </div>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <div className="mb-4">
            <span className="text-uppercase h2">
              <span style={{"textDecoration": "underline solid var(--primary) 0.3rem"}}>
                {process.env.REACT_APP_FREE}
              </span>
            </span> {process.env.REACT_APP_FOR_ONE_MONTH}
          </div>
          <div className="mb-3 align-middle">
            <span className="text-capitalize">{process.env.REACT_APP_THEN}</span><br />
            <PriceAmountWithDiscount
              amount={props.price.amount}
              promoAmount={props.price.promoAmount}
              currency={props.price.currency}
            /> / {process.env.REACT_APP_MONTH}
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <h6>{process.env.REACT_APP_FEATURES}</h6>
          <ul>
            {props.functions.map((x) => <li key={x}>{x}</li>)}
            <div>
              {Array(props.functionsMax - props.functions.length).fill(1).map((_, i) =>
                <li className="invisible" key={i}>&nbsp;</li>
              )}
            </div>
          </ul>
        </ListGroup.Item>
        <ListGroup.Item>
          <LinkContainer to={Urls.REGISTER}>
            <a href={Urls.REGISTER} className="btn btn-primary btn-lg my-4" type="button">
              {process.env.REACT_APP_LANDING_BUTTON}
            </a>
          </LinkContainer>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}