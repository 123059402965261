import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import Api from 'cls/Api';
import Recommendations from 'cls/Recommandation';
import { ActionTypes, AllActions, GetRecommendationsAction, RecommendationsActions } from 'cls/redux/actions';
import EpicType from 'cls/redux/epictype';
import { ofType } from 'cls/redux/util';

export type RecommendationsState = Recommendations | null;


export const recommendationsReducer = (state: RecommendationsState = null, action: RecommendationsActions): RecommendationsState => {
  switch (action.type) {
    case ActionTypes.GetRecommendationsSuccess: {
      const { item } = action;
      return item;
    }
    default:
      return state;
  }
};

export const getRecommendationsEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<GetRecommendationsAction>(ActionTypes.GetRecommendations),
  mergeMap(() => Api.getRecommendations()),
  map(item => ({ type: ActionTypes.GetRecommendationsSuccess, item }))
);
