import { concat, Observable, of } from 'rxjs';
import { flatMap, map, mergeMap } from 'rxjs/operators';

import { ActionTypes as CoreActionTypes } from '@stack/frontend-core/cls/redux';

import Account from 'cls/Account';
import Api from 'cls/Api';
import Constants from 'cls/Constants';
import {
  AccountActions,
  ActionTypes,
  AllActions,
  DeleteProfileAction,
  GetAccountAction,
  SaveProfileAction,
} from 'cls/redux/actions';
import EpicType from 'cls/redux/epictype';
import { ofType } from 'cls/redux/util';



export interface AccountState {
  account: Account | null;
  profile: string | null;
  price: string;
}

const localPrice = localStorage.getItem("price");

const initAccount = {
  account: null,
  profile: null,
  price: localPrice || Constants.DEFAULT_PRICE,
}

export const accountReducer = (state: AccountState = initAccount, action: AccountActions): AccountState => {
  switch (action.type) {
    case ActionTypes.GetAccountSuccess: {
      const { account } = action;
      let price = state.price;
      if (account.profiles.length === 1 && account.noProfile) {
        localStorage.setItem("profile", account.profiles[0].id);
        account.profile = account.profiles[0].id;
        account.currentProfile = account.profiles[0];
        price = account.currentProfile.price;
        localStorage.setItem("price", price);
      }
      if (!localStorage.getItem("price")) {
        price = account.currentProfile === undefined ? Constants.DEFAULT_PRICE : account.currentProfile.price;
        localStorage.setItem("price", price);
      }
      const lsp = localStorage.getItem("profile")
      return {
        ...state,
        account,
        price,
        profile: lsp || state.profile,
      };
    }
    case ActionTypes.SetProfile:
      const { account } = state;
      account?.setProfile(action.profile);
      const price = account === null || account.currentProfile === undefined ? state.price : account.currentProfile.price;
      localStorage.setItem("profile", action.profile);
      localStorage.setItem("price", price);
      return {
        price,
        account: account === null ? null : account?.copy(),
        profile: action.profile,
      };
    case ActionTypes.SaveProfileSuccess: {
      const { account } = state;
      if (account === null) { return state; }
      const copy = account.copy();
      const exists = copy.profiles.find((x) => x.id === action.item.id) !== undefined;
      if (exists) {
        copy.profiles = copy.profiles.map((x) => x.id === action.item.id ? action.item : x);
      } else {
        copy.profiles.push(action.item);
      }
      copy.setProfile(copy.profile);
      const price = account.currentProfile === undefined ? state.price : account.currentProfile.price;
      localStorage.setItem("price", price);
      return {
        ...state,
        account: copy,
        price,
      };
    }
    default:
      return state;
  }
};

export const getAccountEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<GetAccountAction>(ActionTypes.GetAccount),
  flatMap(() => Api.getAccount()),
  map(account => ({ type: ActionTypes.GetAccountSuccess, account: account }))
);

export const saveProfileEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<SaveProfileAction>(ActionTypes.SaveProfile),
  mergeMap((action) => {
    const item = action.profile;
    return concat(
      of<AllActions>({
        type: CoreActionTypes.LoadingStart,
        item,
      }),
      Api.saveProfile(action.profile).pipe(
        mergeMap((newId) => {
          item.id = newId;
          return of<AllActions[]>(
            {
              type: ActionTypes.SaveProfileSuccess,
              item,
            },
            {
              type: CoreActionTypes.LoadingEnd,
              item,
            },
          )
        })
      ),
    )
  }),
);

export const deleteProfileEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<DeleteProfileAction>(ActionTypes.DeleteProfile),
  flatMap(action => Api.deleteProfile(action.profileId)),
  map(() => ({ type: ActionTypes.GetAccount }))
);
