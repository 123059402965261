import * as _ from 'underscore';

import CellarItem from 'cls/CellarItem';
import Ingredient from 'cls/Ingredient';
import MenuItem from 'cls/MenuItem';
import MenuItemsBase from 'cls/MenuItemsBase';
import Quantity from 'cls/Quantity';
import Recipe from 'cls/Recipe';
import RecipeIngredient from 'cls/RecipeIngredient';
import ShoppingList from 'cls/ShoppingList';
import { Measure as MeasureEnum, Q, RecipeIngredient as ThriftRecipeIngredient } from 'thriftgen';



function multiply(num: number, xs: [string, {n: number, m: number}][]): [string, {n: number, m: number}][] {
  return xs.map(([id, q]) => [id, {n: q.n * num, m: q.m}]);
}

export default class IngredientsList {

  static getRecipeMultiplier(q: Q, serving: number, count: Q | undefined, recipeId: string): number {
    let mult = 1;

    if (q.m === MeasureEnum.COUNT) {
      mult = q.n;
    } else if (q.m === MeasureEnum.PORTION) {
      mult = q.n / serving;
    } else if (
      (q.m === MeasureEnum.L || q.m === MeasureEnum.ML) &&
      count !== undefined
    ) {
      const qMinor = Quantity.init4(count, undefined);
      if (q.m === MeasureEnum.L) {
        q.m = MeasureEnum.ML;
        q.n = q.n * 1000;
      }
      if (qMinor.m.q === MeasureEnum.L) {
        qMinor.m.q = MeasureEnum.ML;
        qMinor.n = qMinor.n * 1000;
      }
      mult = q.n / qMinor.n;
    } else if (q.m === MeasureEnum.G && count !== undefined) {
      const cq = Quantity.init4(count, undefined).normalize();
      if (cq.m.q === MeasureEnum.G) {
        mult = q.n / cq.n;
      } else {
        console.warn("No match for g recipe multiplier for ", recipeId);
      }
    } else {
      console.warn('Unknown recipe multiplier ', recipeId, q)
    }
    return mult;
  }

  static recipeIngredient(ri: RecipeIngredient, nutrition: boolean): RecipeIngredient[] {
    let nutr = 1;
    if (nutrition) {
      nutr = ri.nutrition;
    }
    if (ri.ingredient !== undefined) {
      return [ri.multiply(nutr)];
    }
    if (ri.recipe !== undefined) {
      const mult = IngredientsList.getRecipeMultiplier(
        ri.quantity.normalize().q, ri.recipe.serving, ri.recipe.count, ri.recipeId || ""
      )
      return IngredientsList.recipe(ri.recipe, nutrition).map((x) => x.multiply(nutr * mult));
    }
    return [];
  }

  static recipe(recipe: Recipe, nutrition: boolean): RecipeIngredient[] {
    const allIngredients = _.flatten(
      recipe.ingredients.map((x) => IngredientsList.recipeIngredient(x, nutrition))
    );
    return RecipeIngredient.sum(allIngredients);
  }

  static ingredient(x: Ingredient, q?: Q): RecipeIngredient[] {
    return [
      new RecipeIngredient(
        new ThriftRecipeIngredient({
          ingredientId: x.id,
          nutrition: 1,
          q: q === undefined ? Quantity.defaultOne().q : q,
        }),
        [x]
      )
    ];
  }

  static menuItem(menuItem: MenuItem | CellarItem, nutrition: boolean): RecipeIngredient[] {
    if (menuItem.ingredient !== undefined) {
      return IngredientsList.ingredient(menuItem.ingredient, menuItem.q);
    }
    if (menuItem.recipe === undefined) {
      return [];
    }

    let mult = IngredientsList.getRecipeMultiplier(
      menuItem.q, menuItem.recipe.serving, menuItem.recipe.count, menuItem.recipeId || ""
    );

    return IngredientsList.recipe(menuItem.recipe, nutrition).map((x) => x.multiply(mult));
  }

  static menuItemsBase(menuItemBase: MenuItemsBase, nutrition: boolean): RecipeIngredient[] {
    const allIngredients: RecipeIngredient[] = MenuItem.sum(menuItemBase.items).flatMap(
      (x) => IngredientsList.menuItem(x, nutrition)
    )
    return RecipeIngredient.sum(allIngredients);
  }

  static menuItems(xs: (MenuItem | CellarItem)[], nutrition: boolean) {
    const allIngredients: RecipeIngredient[] = xs.flatMap(
      (x) => IngredientsList.menuItem(x, nutrition)
    )
    return RecipeIngredient.sum(allIngredients);
  }

  static shoppingList(shoppingList: ShoppingList): RecipeIngredient[] {
    const xs1 = IngredientsList.menuItemsBase(shoppingList, false);
    const xs2 = IngredientsList.menuItems(shoppingList.cellar, false);
    const xs3 = shoppingList.ingredientsAsRecipeIngredients;
    return RecipeIngredient.subtract(
      RecipeIngredient.sum([...xs1, ...xs3]),
      xs2,
    );
  }

  static recipeIngredientN(ri: RecipeIngredient | MenuItem, nutrition: boolean): [string, {n: number, m: number}][] {
    let nutr = 1;
    if (nutrition) {
      nutr = ri.nutrition;
    }
    if (ri.ingredient !== undefined) {
      return multiply(nutr, IngredientsList.ingredientN(ri.ingredient, ri.quantity.normalize().q));
    }
    if (ri.recipe !== undefined) {
      const mult = IngredientsList.getRecipeMultiplier(
        ri.quantity.normalize().q, ri.recipe.serving, ri.recipe.count, ri.recipeId || ""
      );
      return multiply(nutr * mult, IngredientsList.recipeN(ri.recipe, nutrition));
    }
    return [];
  }

  static recipeN(recipe: Recipe, nutrition: boolean): [string, {n: number, m: number}][] {
    const ri = IngredientsList.recipe(recipe, nutrition);
    return ri.map((x) => [x.ingredientId || "", {n: x.q.n, m: x.q.m}]);
  }

  static ingredientN(x: Ingredient, q?: Q): [string, {n: number, m: number}][] {
    const qq = q === undefined ? Quantity.defaultOne().q : q;
    return [[x.id || "", {n: qq.n, m: qq.m}]];
  }

  static recipeIngredientsN(xs: RecipeIngredient[] | MenuItem[], nutrition: boolean): [string, {n: number, m: number}][] {
    return _.chain(xs)
      .map((r) => IngredientsList.recipeIngredientN(r, nutrition))
      .flatten(true)
      .value();
  }

  static menuItemsBaseN(menuItemBase: MenuItemsBase, nutrition: boolean) {
    const allIngredients: RecipeIngredient[] = menuItemBase.items.flatMap(
      (x) => IngredientsList.menuItem(x, nutrition)
    )
    return IngredientsList.recipeIngredientsN(RecipeIngredient.sum(allIngredients), nutrition);
  }
}