/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as Translation from "./Translation";
import * as ApiImg from "./ApiImg";
import * as Portion from "./Portion";
import * as Season from "./Season";
import * as Price from "./Price";
export interface IIngredientArgs {
    id: string;
    name: Map<string, Translation.Translation>;
    tags?: Array<string>;
    category: string;
    img?: ApiImg.ApiImg;
    measure: string;
    portions?: Portion.Portion;
    printAs?: Array<string>;
    season?: Season.Season;
    aliasNames: Map<string, Array<string>>;
    comment: string;
    price: Price.Price;
    updated: string;
}
export class Ingredient {
    public id: string;
    public name: Map<string, Translation.Translation>;
    public tags?: Array<string>;
    public category: string;
    public img?: ApiImg.ApiImg;
    public measure: string;
    public portions?: Portion.Portion;
    public printAs?: Array<string>;
    public season?: Season.Season;
    public aliasNames: Map<string, Array<string>>;
    public comment: string;
    public price: Price.Price;
    public updated: string;
    constructor(args: IIngredientArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[name] is unset!");
        }
        if (args != null && args.tags != null) {
            this.tags = args.tags;
        }
        if (args != null && args.category != null) {
            this.category = args.category;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[category] is unset!");
        }
        if (args != null && args.img != null) {
            this.img = args.img;
        }
        if (args != null && args.measure != null) {
            this.measure = args.measure;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[measure] is unset!");
        }
        if (args != null && args.portions != null) {
            this.portions = args.portions;
        }
        if (args != null && args.printAs != null) {
            this.printAs = args.printAs;
        }
        if (args != null && args.season != null) {
            this.season = args.season;
        }
        if (args != null && args.aliasNames != null) {
            this.aliasNames = args.aliasNames;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[aliasNames] is unset!");
        }
        if (args != null && args.comment != null) {
            this.comment = args.comment;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[comment] is unset!");
        }
        if (args != null && args.price != null) {
            this.price = args.price;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[price] is unset!");
        }
        if (args != null && args.updated != null) {
            this.updated = args.updated;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[updated] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Ingredient");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.MAP, 2);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.STRUCT, this.name.size);
            this.name.forEach((value_1: Translation.Translation, key_1: string): void => {
                output.writeString(key_1);
                value_1.write(output);
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        if (this.tags != null) {
            output.writeFieldBegin("tags", thrift.Thrift.Type.LIST, 3);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.tags.length);
            this.tags.forEach((value_2: string): void => {
                output.writeString(value_2);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.category != null) {
            output.writeFieldBegin("category", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.category);
            output.writeFieldEnd();
        }
        if (this.img != null) {
            output.writeFieldBegin("img", thrift.Thrift.Type.STRUCT, 5);
            this.img.write(output);
            output.writeFieldEnd();
        }
        if (this.measure != null) {
            output.writeFieldBegin("measure", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.measure);
            output.writeFieldEnd();
        }
        if (this.portions != null) {
            output.writeFieldBegin("portions", thrift.Thrift.Type.STRUCT, 7);
            this.portions.write(output);
            output.writeFieldEnd();
        }
        if (this.printAs != null) {
            output.writeFieldBegin("printAs", thrift.Thrift.Type.LIST, 8);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.printAs.length);
            this.printAs.forEach((value_3: string): void => {
                output.writeString(value_3);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.season != null) {
            output.writeFieldBegin("season", thrift.Thrift.Type.STRUCT, 9);
            this.season.write(output);
            output.writeFieldEnd();
        }
        if (this.aliasNames != null) {
            output.writeFieldBegin("aliasNames", thrift.Thrift.Type.MAP, 10);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.LIST, this.aliasNames.size);
            this.aliasNames.forEach((value_4: Array<string>, key_2: string): void => {
                output.writeString(key_2);
                output.writeListBegin(thrift.Thrift.Type.STRING, value_4.length);
                value_4.forEach((value_5: string): void => {
                    output.writeString(value_5);
                });
                output.writeListEnd();
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        if (this.comment != null) {
            output.writeFieldBegin("comment", thrift.Thrift.Type.STRING, 11);
            output.writeString(this.comment);
            output.writeFieldEnd();
        }
        if (this.price != null) {
            output.writeFieldBegin("price", thrift.Thrift.Type.STRUCT, 12);
            this.price.write(output);
            output.writeFieldEnd();
        }
        if (this.updated != null) {
            output.writeFieldBegin("updated", thrift.Thrift.Type.STRING, 13);
            output.writeString(this.updated);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Ingredient {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_6: string = input.readString();
                        _args.id = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_7: Map<string, Translation.Translation> = new Map<string, Translation.Translation>();
                        const metadata_1: thrift.TMap = input.readMapBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const key_3: string = input.readString();
                            const value_8: Translation.Translation = Translation.Translation.read(input);
                            value_7.set(key_3, value_8);
                        }
                        input.readMapEnd();
                        _args.name = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_9: Array<string> = new Array<string>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_10: string = input.readString();
                            value_9.push(value_10);
                        }
                        input.readListEnd();
                        _args.tags = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_11: string = input.readString();
                        _args.category = value_11;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_12: ApiImg.ApiImg = ApiImg.ApiImg.read(input);
                        _args.img = value_12;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_13: string = input.readString();
                        _args.measure = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_14: Portion.Portion = Portion.Portion.read(input);
                        _args.portions = value_14;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_15: Array<string> = new Array<string>();
                        const metadata_3: thrift.TList = input.readListBegin();
                        const size_3: number = metadata_3.size;
                        for (let i_3: number = 0; i_3 < size_3; i_3++) {
                            const value_16: string = input.readString();
                            value_15.push(value_16);
                        }
                        input.readListEnd();
                        _args.printAs = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_17: Season.Season = Season.Season.read(input);
                        _args.season = value_17;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 10:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_18: Map<string, Array<string>> = new Map<string, Array<string>>();
                        const metadata_4: thrift.TMap = input.readMapBegin();
                        const size_4: number = metadata_4.size;
                        for (let i_4: number = 0; i_4 < size_4; i_4++) {
                            const key_4: string = input.readString();
                            const value_19: Array<string> = new Array<string>();
                            const metadata_5: thrift.TList = input.readListBegin();
                            const size_5: number = metadata_5.size;
                            for (let i_5: number = 0; i_5 < size_5; i_5++) {
                                const value_20: string = input.readString();
                                value_19.push(value_20);
                            }
                            input.readListEnd();
                            value_18.set(key_4, value_19);
                        }
                        input.readMapEnd();
                        _args.aliasNames = value_18;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 11:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_21: string = input.readString();
                        _args.comment = value_21;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 12:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_22: Price.Price = Price.Price.read(input);
                        _args.price = value_22;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 13:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_23: string = input.readString();
                        _args.updated = value_23;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.name !== undefined && _args.category !== undefined && _args.measure !== undefined && _args.aliasNames !== undefined && _args.comment !== undefined && _args.price !== undefined && _args.updated !== undefined) {
            return new Ingredient(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Ingredient from input");
        }
    }
}
