import * as _ from 'underscore';

import Portion from 'cls/Portion';
import Quantity from 'cls/Quantity';
import Utils from 'cls/Utils';
import { Measure as MeasureEnum, Q } from 'thriftgen';

const MeasureEnumKeys: string[] = Object.keys(MeasureEnum).filter(
  (k) => typeof MeasureEnum[k as any] === "number"
);
const MeasureEnumValues: number[] = MeasureEnumKeys.map(
  (k) => Number(MeasureEnum[k as any])
);

export function pv(n: number | undefined, unit: string): string {
  if (n === undefined) {
    return '- ' + unit;
  }
  let sn = n.toFixed(2);
  sn = sn.replace(/0+$/, "");
  sn = sn.replace(/\.+$/, "");
  if (unit === '') {
    return sn;
  }
  return sn + ' ' + unit;
}

export default class Measure {
  q: MeasureEnum;
  singularNominativ: string;
  singularGenitive: string;
  pluralNominativ: string;
  pluralGenitive: string;

  constructor(
    q: MeasureEnum,
    singularNominativ: string,
    singularGenitive: string,
    pluralNominativ: string,
    pluralGenitive: string
  ) {
    this.q = q;
    this.singularNominativ = singularNominativ;
    this.singularGenitive = singularGenitive;
    this.pluralNominativ = pluralNominativ;
    this.pluralGenitive = pluralGenitive;
  }

  text(n: number) {
    const m = Utils.polishPlural(
      this.singularNominativ,
      this.singularGenitive,
      this.pluralNominativ,
      this.pluralGenitive,
      n
    );
    return pv(n, m);
  }

  shortText(n: number) {
    if (this.q === MeasureEnum.PORTION) {
      return pv(n, '');
    }
    return this.text(n);
  }


  private get allNames(): string[] {
    return [
      this.singularNominativ, this.singularGenitive,
      this.pluralNominativ, this.pluralGenitive
    ];
  }

  optionName(n: number): string {
    if (this.q === MeasureEnum.COUNT) {
      return Utils.polishPlural(
        "sztuka",
        "sztuki",
        "sztuki",
        "sztuk",
        n,
      );
    }
    return Utils.polishPlural(
      this.singularNominativ,
      this.singularGenitive,
      this.pluralNominativ,
      this.pluralGenitive,
      n,
    );
  }

  private is(s: string): boolean {
    const m: {[key: string]: MeasureEnum} = {
      "count": MeasureEnum.COUNT,
      "": MeasureEnum.COUNT,
      "g": MeasureEnum.G,
      "kg": MeasureEnum.KG,
      "ml": MeasureEnum.ML,
      "l": MeasureEnum.L,
      "teaspoon": MeasureEnum.TEASPOON,
      "tablespoon": MeasureEnum.TABLESPOON,
      "cup": MeasureEnum.CUP,
    }
    if (m[s] !== undefined) {
      return m[s] === this.q;
    }

    return this.allNames.indexOf(s) !== -1;
  }

  static init(s: string) {
    const measures = Measure.all();
    for (const x in MeasureEnumValues) {
      if (measures[x].is(s)) {
        return measures[x];
      }
    }
    console.warn("Cannot init value", s);
    return measures[MeasureEnum.UNKNOWN];
  }

  static all(): {[key: number]: Measure} {
    let measures: {[key: number]: Measure} = {}
    measures[MeasureEnum.TEASPOON] = new Measure(MeasureEnum.TEASPOON, "łyżeczka", "łyżeczki", "łyżeczki", "łyżeczek")
    measures[MeasureEnum.TABLESPOON] = new Measure(MeasureEnum.TABLESPOON, "łyżka", "łyżki", "łyżki", "łyżek")
    measures[MeasureEnum.CUP] = new Measure(MeasureEnum.CUP, "szklanka", "szklanki", "szklanki", "szklanek")
    measures[MeasureEnum.ML] = new Measure(MeasureEnum.ML, "ml", "ml", "ml", "ml")
    measures[MeasureEnum.L] = new Measure(MeasureEnum.L, "l", "l", "l", "l")
    measures[MeasureEnum.G] = new Measure(MeasureEnum.G, "g", "g", "g", "g")
    measures[MeasureEnum.UNKNOWN] = new Measure(MeasureEnum.UNKNOWN, "?", "?", "?", "?")
    measures[MeasureEnum.COUNT] = new Measure(MeasureEnum.COUNT, "", "", "", "")
    measures[MeasureEnum.HANDFUL] = new Measure(MeasureEnum.HANDFUL, "garść", "garści", "garście", "garści")
    measures[MeasureEnum.SLICE] = new Measure(MeasureEnum.SLICE, "plasterek", "plasterka", "plasterki", "plasterków")
    measures[MeasureEnum.PINCH] = new Measure(MeasureEnum.PINCH, "szczypta", "szczypty", "szczypty", "szczypt")
    measures[MeasureEnum.SPRIG] = new Measure(MeasureEnum.SPRIG, "gałązka", "gałązki", "gałązki", "gałązek")
    measures[MeasureEnum.BREAD_SLICE] = new Measure(MeasureEnum.BREAD_SLICE, "kromka", "kromki", "kromki", "kromek")
    measures[MeasureEnum.PORTION] = new Measure(MeasureEnum.PORTION, "porcja", "porcji", "porcje", "porcji")
    measures[MeasureEnum.BUNCH] = new Measure(MeasureEnum.BUNCH, "pęczek", "pęczka", "pęczki", "pęczków")
    measures[MeasureEnum.STALK] = new Measure(MeasureEnum.STALK, "łodyga", "łodygi", "łodygi", "łodyg")
    measures[MeasureEnum.KG] = new Measure(MeasureEnum.KG, "kg", "kg", "kg", "kg")
    return measures;
  }

  static allList(): Measure[] {
    return _.values(Measure.all());
  }


  static getDefaultMeasure(p: Portion, defaultMeasure: string): Measure {
    const all = Measure.all();
    if (defaultMeasure === "sprig") { return all[MeasureEnum.SPRIG]; }
    if (defaultMeasure === "count") { return all[MeasureEnum.COUNT]; }
    if (defaultMeasure === "g") { return all[MeasureEnum.G]; }
    if (defaultMeasure === "kg") { return all[MeasureEnum.KG]; }
    if (defaultMeasure === "ml") { return all[MeasureEnum.ML]; }
    if (defaultMeasure === "portion") { return all[MeasureEnum.PORTION]; }
    if (p.ml !== undefined) { return all[MeasureEnum.ML]; }
    if (p.medium !== undefined) { return all[MeasureEnum.COUNT]; }
    if (p.slice !== undefined) { return all[MeasureEnum.SLICE]; }
    if (p.sprig !== undefined) { return all[MeasureEnum.SPRIG]; }
    if (p.bunch !== undefined) { return all[MeasureEnum.BUNCH]; }
    if (p.stalk !== undefined) { return all[MeasureEnum.STALK]; }
    if (p.handful !== undefined) { return all[MeasureEnum.HANDFUL]; }
    if (p.count !== undefined) { return all[Quantity.init4(new Q(p.count)).normalize().m.q]; }
    return all[MeasureEnum.COUNT];
  }

  static getAvailableMeasures(p: Portion, defaultMeasure: string): Measure[] {
    const result: Measure[] = [];
    const all = Measure.all();
    if (defaultMeasure === "portion") { result.push(all[MeasureEnum.PORTION]); }
    if (defaultMeasure === "g") {
      result.push(all[MeasureEnum.G]);
      result.push(all[MeasureEnum.KG]);
    }
    if (defaultMeasure === "kg") {
      result.push(all[MeasureEnum.G]);
      result.push(all[MeasureEnum.KG]);
    }
    if (p.slice !== undefined) { result.push(all[MeasureEnum.SLICE]); }
    if (p.sprig !== undefined || defaultMeasure === "sprig") { result.push(all[MeasureEnum.SPRIG]); }
    if (p.bunch !== undefined) { result.push(all[MeasureEnum.BUNCH]); }
    if (p.stalk !== undefined) { result.push(all[MeasureEnum.STALK]); }
    if (p.handful !== undefined) { result.push(all[MeasureEnum.HANDFUL]); }
    if (p.medium !== undefined || defaultMeasure === "count") {
      result.push(all[MeasureEnum.COUNT]);
      result.push(all[MeasureEnum.G]);
      result.push(all[MeasureEnum.KG]);
    }
    if (p.ml !== undefined || defaultMeasure === "ml") {
      result.push(all[MeasureEnum.G]);
      result.push(all[MeasureEnum.KG]);
      result.push(all[MeasureEnum.ML]);
      result.push(all[MeasureEnum.L]);
      result.push(all[MeasureEnum.TABLESPOON]);
      result.push(all[MeasureEnum.TEASPOON]);
      result.push(all[MeasureEnum.CUP]);
      result.push(all[MeasureEnum.PINCH]);
    }
    if (p.count !== undefined) {
      const qn = Quantity.init4(new Q(p.count)).normalize();
      result.push(all[qn.m.q]);
    }
    return _.uniq(result);
  }
}
