/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as RecipeIngredient from "./RecipeIngredient";
export interface IPartyPersonArgs {
    factor: number;
    ingredients: Array<RecipeIngredient.RecipeIngredient>;
}
export class PartyPerson {
    public factor: number;
    public ingredients: Array<RecipeIngredient.RecipeIngredient>;
    constructor(args: IPartyPersonArgs) {
        if (args != null && args.factor != null) {
            this.factor = args.factor;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[factor] is unset!");
        }
        if (args != null && args.ingredients != null) {
            this.ingredients = args.ingredients;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[ingredients] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("PartyPerson");
        if (this.factor != null) {
            output.writeFieldBegin("factor", thrift.Thrift.Type.BYTE, 1);
            output.writeByte(this.factor);
            output.writeFieldEnd();
        }
        if (this.ingredients != null) {
            output.writeFieldBegin("ingredients", thrift.Thrift.Type.LIST, 2);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.ingredients.length);
            this.ingredients.forEach((value_1: RecipeIngredient.RecipeIngredient): void => {
                value_1.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): PartyPerson {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BYTE) {
                        const value_2: number = input.readByte();
                        _args.factor = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_3: Array<RecipeIngredient.RecipeIngredient> = new Array<RecipeIngredient.RecipeIngredient>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_4: RecipeIngredient.RecipeIngredient = RecipeIngredient.RecipeIngredient.read(input);
                            value_3.push(value_4);
                        }
                        input.readListEnd();
                        _args.ingredients = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.factor !== undefined && _args.ingredients !== undefined) {
            return new PartyPerson(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read PartyPerson from input");
        }
    }
}
