import * as _ from 'underscore';

import Constants from 'cls/Constants';
import IngredientsList from 'cls/IngredientsList';
import MenuItem from 'cls/MenuItem';
import { N } from 'cls/N';
import { NEPrint } from 'cls/NEPrint';
import RecipeIngredient from 'cls/RecipeIngredient';
import Utils from 'cls/Utils';


export default class Summary {
  static DAILY: string = 'd';
  static WEEKLY: string = 'w';
  static YEARLY: string = 'y';

  items: MenuItem[];
  itemsByDateTypeByProfileByDate: {[key: string]: {[key: string]: {[key: string]: MenuItem[]}}};

  shoppingListByDateTypeByProfileByDate: {[key: string]: {[key: string]: {[key: string]: RecipeIngredient[]}}};
  ingredientsByDateTypeByProfileByDate: {[key: string]: {[key: string]: {[key: string]: RecipeIngredient[]}}};
  ingredientsByDateTypeByProfileByDateByCategorySum: {[key: string]: {[key: string]: {[key: string]: {[key: string]: number}}}};

  ingredientsByDateTypeByProfileByDateByCategory: {[key: string]: {[key: string]: {[key: string]: {[key: string]: RecipeIngredient[]}}}};

  neaByDateTypeByProfileByDate: {[key: string]: {[key: string]: {[key: string]: number[]}}};
  neaByDateTypeByProfileByDateByCategory: {[key: string]: {[key: string]: {[key: string]: {[key: string]: number[]}}}};

  n?: N;

  constructor(xs: MenuItem[]) {
    this.items = xs;
    const itemsByProfile: {[key: string]: MenuItem[]} = _.groupBy(
      this.items, (x) => x.stock ? "" : x.ownerId
    );
    const itemsByProfileByDay = _.mapObject(
      itemsByProfile,
      (v) => _.groupBy(v, (x) => x.prepDay || "")
    );
    const itemsByProfileByWeek = _.mapObject(
      itemsByProfile,
      (v) => _.groupBy(v, (x) => Utils.week(x.prepDay || ""))
    );
    const shoppingListByProfileByYear = _.mapObject(
      itemsByProfile,
      (v) => _.mapObject(
        _.groupBy(v, (x) => Utils.year(x.prepDay || "")),
        (xs) => IngredientsList.menuItems(xs, false)
      )
    );
    const shoppingListByProfileByWeek = _.mapObject(
      itemsByProfile,
      (v) => _.mapObject(
        _.groupBy(v, (x) => Utils.week(x.prepDay || "")),
        (xs) => IngredientsList.menuItems(xs, false)
      )
    );
    const ingredientsByProfileByYear = _.mapObject(
      itemsByProfile,
      (v) => _.mapObject(
        _.groupBy(v, (x) => Utils.year(x.prepDay || "")),
        (xs) => RecipeIngredient.sum(
          xs.flatMap((x) => x.ingredients)
        )
      )
    );
    this.itemsByDateTypeByProfileByDate = {};
    this.itemsByDateTypeByProfileByDate[Summary.DAILY] = itemsByProfileByDay;
    this.itemsByDateTypeByProfileByDate[Summary.WEEKLY] = itemsByProfileByWeek;

    const ingredientsByProfileByDayByCategory = _.mapObject(
      itemsByProfile,
      (v) => _.mapObject(
        _.groupBy(v, (x) => x.prepDay || ""),
        (xs) => _.groupBy(
            xs.flatMap((x) => IngredientsList.menuItem(x, true)),
          (x) => x.category
          )
      )
    );
    const ingredientsByProfileByWeekByCategory = _.mapObject(
      itemsByProfile,
      (v) => _.mapObject(
        _.groupBy(v, (x) => Utils.week(x.prepDay || "")),
        (xs) => _.groupBy(
          xs.flatMap((x) => IngredientsList.menuItem(x, true)),
          (x) => x.category
        )
      )
    );
    const ingredientsByDateTypeByProfileByDateByCategorySum = _.mapObject(
      itemsByProfile,
      (v) => _.mapObject(
        _.groupBy(v, (x) => Utils.year(x.prepDay || "")),
        (xs) => _.mapObject(
          _.groupBy(
            xs.flatMap((x) => IngredientsList.menuItem(x, true)),
            (x) => x.category
          ),
          (ys) => _.reduce(
            ys.map((y) => y.quantity.inG()?.n || 0),
            (a, b) => a + b,
            0
          )
        )
      )
    );
    this.ingredientsByDateTypeByProfileByDateByCategory = {};
    this.ingredientsByDateTypeByProfileByDateByCategory[Summary.DAILY] = ingredientsByProfileByDayByCategory;
    this.ingredientsByDateTypeByProfileByDateByCategory[Summary.WEEKLY] = ingredientsByProfileByWeekByCategory;
    this.ingredientsByDateTypeByProfileByDateByCategorySum = {};
    this.ingredientsByDateTypeByProfileByDateByCategorySum[Summary.YEARLY] = ingredientsByDateTypeByProfileByDateByCategorySum;

    this.shoppingListByDateTypeByProfileByDate = {};
    this.shoppingListByDateTypeByProfileByDate[Summary.YEARLY] = shoppingListByProfileByYear;
    this.shoppingListByDateTypeByProfileByDate[Summary.WEEKLY] = shoppingListByProfileByWeek;
    this.ingredientsByDateTypeByProfileByDate = {}
    this.ingredientsByDateTypeByProfileByDate[Summary.YEARLY] = ingredientsByProfileByYear;

    this.neaByDateTypeByProfileByDate = {};
    this.neaByDateTypeByProfileByDate[Summary.DAILY] = {};
    this.neaByDateTypeByProfileByDate[Summary.WEEKLY] = {};
    this.neaByDateTypeByProfileByDateByCategory = {};
    this.neaByDateTypeByProfileByDateByCategory[Summary.DAILY] = {};
    this.neaByDateTypeByProfileByDateByCategory[Summary.WEEKLY] = {};
  }

  setN(n: N | null) {
    if (n === null) { return; }
    this.n = n;
    if (this.n === undefined) { return; }

    _.keys(this.itemsByDateTypeByProfileByDate[Summary.DAILY]).forEach((profile) => {
      this.neaByDateTypeByProfileByDate[Summary.DAILY][profile] = {};
      this.neaByDateTypeByProfileByDateByCategory[Summary.DAILY][profile] = {};
      _.keys(this.itemsByDateTypeByProfileByDate[Summary.DAILY][profile]).forEach((date) => {
        const ne = n.getForIngredients(IngredientsList.recipeIngredientsN(
          this.itemsByDateTypeByProfileByDate[Summary.DAILY][profile][date], true
        ));
        if (ne === undefined) { return; }
        this.neaByDateTypeByProfileByDate[Summary.DAILY][profile][date] = ne;

        this.neaByDateTypeByProfileByDateByCategory[Summary.DAILY][profile][date] = {};
        _.keys(this.ingredientsByDateTypeByProfileByDateByCategory[Summary.DAILY][profile][date]).forEach((category: string) => {
          const ne = n.getForIngredients(IngredientsList.recipeIngredientsN(
            this.ingredientsByDateTypeByProfileByDateByCategory[Summary.DAILY][profile][date][category], true
          ));
          if (ne === undefined) { return; }
          this.neaByDateTypeByProfileByDateByCategory[Summary.DAILY][profile][date][category] = ne;
        });
      });
    });
    _.keys(this.itemsByDateTypeByProfileByDate[Summary.WEEKLY]).forEach((profile) => {
      this.neaByDateTypeByProfileByDate[Summary.WEEKLY][profile] = {};
      this.neaByDateTypeByProfileByDateByCategory[Summary.WEEKLY][profile] = {};
      _.keys(this.itemsByDateTypeByProfileByDate[Summary.WEEKLY][profile]).forEach((date) => {
        const ne = n.getForIngredients(IngredientsList.recipeIngredientsN(
          this.itemsByDateTypeByProfileByDate[Summary.WEEKLY][profile][date], true
        ));
        if (ne === undefined) { return; }
        this.neaByDateTypeByProfileByDate[Summary.WEEKLY][profile][date] = ne;

        this.neaByDateTypeByProfileByDateByCategory[Summary.WEEKLY][profile][date] = {};
        _.keys(this.ingredientsByDateTypeByProfileByDateByCategory[Summary.WEEKLY][profile][date]).forEach((category: string) => {
          const ne = n.getForIngredients(IngredientsList.recipeIngredientsN(
            this.ingredientsByDateTypeByProfileByDateByCategory[Summary.WEEKLY][profile][date][category], true
          ));
          if (ne === undefined) { return; }
          this.neaByDateTypeByProfileByDateByCategory[Summary.WEEKLY][profile][date][category] = ne;
        });
      });
    });
  }

  proteinByCategory(profileId: string, date: string, frequency: string): string[] {
    const days = this.days(frequency);
    const categories = Constants.TAG_GROUP_PROTEIN_SUMMARY;
    const result = [
      new NEPrint(this.neaByDateTypeByProfileByDate[frequency][profileId][date])?.pv('protein', days).print,
    ];
    categories.forEach((category) => {
      if (this.neaByDateTypeByProfileByDateByCategory[frequency][profileId][date][category] === undefined) {
        result.push('0')
      } else {
        result.push(
          new NEPrint(this.neaByDateTypeByProfileByDateByCategory[frequency][profileId][date][category])?.pv('protein', days).print
        )
      }
    });
    return result;
  }
  datesIterator(profileId: string, frequency: string): string[] {
    return _.keys(this.itemsByDateTypeByProfileByDate[frequency][profileId]).sort().reverse();
  }
  days(frequency: string): number {
    if (frequency === Summary.DAILY) {
      return 1;
    }
    if (frequency === Summary.WEEKLY) {
      return 7;
    }
    return 0;
  }
}
