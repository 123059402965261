import { Col, Row } from "react-bootstrap";

import { Review } from 'content/landing/Review';


export const Reviews = () => {
  return <section className="bg-secondary py-5">
    <Row className="px-4">
      <Col xs={12} md={{offset: 2, span: 4}} className="mb-4">
        <Review
          text={process.env.REACT_APP_LANDING_REVIEW_1 || ""}
          rating={5}
          author={process.env.REACT_APP_LANDING_REVIEW_N1 || ""}
        />
      </Col>
      <Col xs={12} md={{span: 4}}>
        <Review
          text={process.env.REACT_APP_LANDING_REVIEW_2 || ""}
          rating={4}
          author={process.env.REACT_APP_LANDING_REVIEW_N2 || ""}
        />
      </Col>
    </Row>
  </section>;
};