import * as React from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { Friend } from '@stack/frontend-core/cls';
import { ActionTypes as CoreActionTypes } from '@stack/frontend-core/cls/redux';

import Ingredient from 'cls/Ingredient';
import Recipe from 'cls/Recipe';
import { StoreDispatch, StoreState } from 'cls/store';
import Utils from 'cls/Utils';

import 'page/main/style.css';

interface SearchResult {id: string, url: string, text: string, type: string}
interface OuterProps {
  ingredients?: boolean,
  recipes?: boolean,
  friends?: boolean,
  inNav?: boolean,
  className?: string,
  onSearchResultClicked?: (_: SearchResult) => void,
}
interface Props {
  useIngredients: boolean,
  useRecipes: boolean,
  useFriends: boolean,
  inNav: boolean,
  className: string,
  onSearchResultClicked?: (_: SearchResult) => void,
}
interface ReduxStateProps {
  recipes: Recipe[];
  ingredients: Ingredient[];
  friends?: Friend[];
}
interface ReduxDispatchProps {
  listFriends: () => void;
}
interface State {
  searchResults: SearchResult[],
  searchText: string,
}

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {
  constructor(props: Props & ReduxDispatchProps & ReduxStateProps) {
    super(props);
    this.state = {searchResults: [], searchText: ""};
    this.clickSearchResult = this.clickSearchResult.bind(this);
    this.onSearchTextChange = this.onSearchTextChange.bind(this);
    this.onSearchTextFocus = this.onSearchTextFocus.bind(this);
  }

  componentDidUpdate(prevProps: Props & ReduxStateProps & ReduxDispatchProps) {
    const { friends } = this.props;
    if (prevProps.friends === undefined && friends !== undefined) {
      this.redoSearchResults();
    }
  }

  clickSearchResult(searchResult: SearchResult) {
    this.setState({searchResults: [], searchText: ""});
    const { onSearchResultClicked } = this.props;
    if (onSearchResultClicked !== undefined) {
      onSearchResultClicked(searchResult);
    }
  }

  redoSearchResults() {
    const { searchText } = this.state;
    if (searchText.length < 2) {
      this.setState({searchResults: []});
    } else {
      // Api.search(searchText).subscribe((results) => {
      //   const searchResults = results.map((x) => (
      //     {
      //       id: x.id,
      //       url: `/składnik/${x.id}`,
      //       text: x.name,
      //       type: 'I'
      //     }
      //   ));
      //   this.setState({searchResults});
      // });
      const { ingredients, recipes, friends } = this.props;
      this.setState({
        searchResults: Utils.search(searchText, ingredients, recipes, friends || [])
      });
    }
  }

  onSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    this.setState({searchText: value}, this.redoSearchResults);
  }

  onSearchTextFocus() {
    const { friends, listFriends } = this.props;
    if (friends === undefined) {
      listFriends();
    }
  }

  render() {
    const { searchText, searchResults } = this.state;
    const { inNav, className, useFriends, useIngredients, useRecipes, onSearchResultClicked } = this.props;

    let placeholder = process.env.REACT_APP_SEARCH;
    if (useFriends && !useIngredients && !useRecipes) {
      placeholder = process.env.REACT_APP_SEARCH_ADD_FRIENDS_ONLY;
    }

    return (
      <div id={inNav ? "navbar-search" : ""} className={`search-component ${className}`}>
        <div className="input-group-overlay">
          <input className="form-control appended-form-control"
                 type="text"
                 value={searchText}
                 onChange={this.onSearchTextChange}
                 onFocus={this.onSearchTextFocus}
                 placeholder={placeholder} />
          <div className="input-group-append-overlay">
            <span className="input-group-text">
              <i className="czi-search" />
            </span>
          </div>
        </div>
        <div className="list-group search-component-results" id={inNav ? "search-results" : ""}>
          {searchResults.map((searchResult, i) => (
            <>
              {onSearchResultClicked === undefined ?
                <LinkContainer to={searchResult.url} key={`search-result-${i}`} activeClassName="">
                  <a href={searchResult.url}
                     onClick={() => this.clickSearchResult(searchResult)}
                     className="list-group-item list-group-item-action">
                    { searchResult.text }
                  </a>
                </LinkContainer> :
                <a key={`search-result-${i}`}
                   onClick={() => this.clickSearchResult(searchResult)}
                   className="list-group-item list-group-item-action">
                  { searchResult.text }
                </a>
              }
            </>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState, props: Props): ReduxStateProps => {
  return ({
    ingredients: props.useIngredients ? state.basic.ingredients : [],
    recipes: props.useRecipes ? state.basic.recipes : [],
    friends: state.friends.friends === null ? undefined : (props.useFriends ? state.friends.friends : []),
  });
};

const mapDispatchToProps = (dispatch: StoreDispatch) => {
  return {
    listFriends: () => dispatch({ type: CoreActionTypes.ListFriends }),
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const Search = (props: OuterProps) => (
  <ConnectedScene
    className={props.className === undefined ? '' : props.className}
    useIngredients={props.ingredients === undefined ? true : props.ingredients}
    useRecipes={props.recipes === undefined ? true : props.recipes}
    useFriends={props.friends === undefined ? true : props.friends}
    inNav={props.inNav === undefined ? false : props.inNav}
    onSearchResultClicked={props.onSearchResultClicked}
  />
);

export default Search;
