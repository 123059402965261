import Account from 'cls/Account';
import MenuItem from 'cls/MenuItem';

export default class MenuItemsBase {
  items: MenuItem[];

  constructor(items: MenuItem[]) {
    this.items = items;
  }

  addItem(item: MenuItem) {
    this.items.push(item);
  }
  removeItem(itemId: string) {
    const itemIndex = this.items.findIndex((x) => x.id === itemId);
    if (itemIndex === -1) { return; }
    this.items.splice(itemIndex, 1);
  }
  updateItem(item: MenuItem) {
    this.items = this.items.map((x) => x.id === item.id ? item : x);
  }

  byDayIterator(): string[] {
    const allDays: string[] = [];
    this.items.forEach((x) => {
      if (allDays.indexOf(x.datePrepDay || "") === -1) {
        allDays.push(x.datePrepDay || "");
      }
    });
    allDays.sort();
    return allDays;
  }
  byDayByMealIterator(account: Account | undefined): {[keys: string]: string[]} {
    const order: {[key: string]: number} = {};
    account?.active?.meals?.forEach((meal, index) => {
      order[meal.id] = index;
    });

    const allDays: string[] = [];
    const result: {[keys: string]: string[]} = {};
    this.items.forEach((x) => {
      if (allDays.indexOf(x.datePrepDay || "") === -1) {
        allDays.push(x.datePrepDay || "");
      }
      if (result[x.datePrepDay || ""] === undefined) {
        result[x.datePrepDay || ""] = [];
      }
      if (result[x.datePrepDay || ""].indexOf(x.meal || "") === -1) {
        result[x.datePrepDay || ""].push(x.meal || "");
      }
    });

    allDays.forEach((day) => {
      result[day].sort((a, b) => order[a] - order[b]);
    });
    return result;
  }
  get byDayByMeal(): {[a: string]: {[b: string]: MenuItem[]}} {
    const result : {[a: string]: {[b: string]: MenuItem[]}} = {};
    this.items.forEach((x) => {
      if (result[x.datePrepDay || ""] === undefined) {
        result[x.datePrepDay || ""] = {};
      }
      if (result[x.datePrepDay || ""][x.meal || ""] === undefined) {
        result[x.datePrepDay || ""][x.meal || ""] = [];
      }
      result[x.datePrepDay || ""][x.meal || ""].push(x);
    });
    return result;
  }

  get byType(): {[a: string]: MenuItem[]} {
    const result : {[a: string]: MenuItem[]} = {};

    this.items.forEach((x) => {
      const typeKey = `${x.itemId}:${x.quantity.text}`;
      if (result[typeKey] === undefined) {
        result[typeKey] = [];
      }
      result[typeKey].push(x);
    });
    return result;
  }
}
