/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as Translation from "./Translation";
import * as Q from "./Q";
import * as RecipeIngredient from "./RecipeIngredient";
import * as ManualItem from "./ManualItem";
import * as Price from "./Price";
export interface IRecipeArgs {
    id: string;
    name: Map<string, Translation.Translation>;
    owner: string;
    sources: Array<string>;
    serving: number;
    count?: Q.Q;
    tags: Array<string>;
    ingredients: Array<RecipeIngredient.RecipeIngredient>;
    category: string;
    steps: Map<string, Array<string>>;
    variationIds: Array<string>;
    comment: string;
    ownerName: string;
    manual: Array<ManualItem.ManualItem>;
    price?: Price.Price;
    updated: string;
}
export class Recipe {
    public id: string;
    public name: Map<string, Translation.Translation>;
    public owner: string;
    public sources: Array<string>;
    public serving: number;
    public count?: Q.Q;
    public tags: Array<string>;
    public ingredients: Array<RecipeIngredient.RecipeIngredient>;
    public category: string;
    public steps: Map<string, Array<string>>;
    public variationIds: Array<string>;
    public comment: string;
    public ownerName: string;
    public manual: Array<ManualItem.ManualItem>;
    public price?: Price.Price;
    public updated: string;
    constructor(args: IRecipeArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[name] is unset!");
        }
        if (args != null && args.owner != null) {
            this.owner = args.owner;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[owner] is unset!");
        }
        if (args != null && args.sources != null) {
            this.sources = args.sources;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[sources] is unset!");
        }
        if (args != null && args.serving != null) {
            this.serving = args.serving;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[serving] is unset!");
        }
        if (args != null && args.count != null) {
            this.count = args.count;
        }
        if (args != null && args.tags != null) {
            this.tags = args.tags;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[tags] is unset!");
        }
        if (args != null && args.ingredients != null) {
            this.ingredients = args.ingredients;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[ingredients] is unset!");
        }
        if (args != null && args.category != null) {
            this.category = args.category;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[category] is unset!");
        }
        if (args != null && args.steps != null) {
            this.steps = args.steps;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[steps] is unset!");
        }
        if (args != null && args.variationIds != null) {
            this.variationIds = args.variationIds;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[variationIds] is unset!");
        }
        if (args != null && args.comment != null) {
            this.comment = args.comment;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[comment] is unset!");
        }
        if (args != null && args.ownerName != null) {
            this.ownerName = args.ownerName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[ownerName] is unset!");
        }
        if (args != null && args.manual != null) {
            this.manual = args.manual;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[manual] is unset!");
        }
        if (args != null && args.price != null) {
            this.price = args.price;
        }
        if (args != null && args.updated != null) {
            this.updated = args.updated;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[updated] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Recipe");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.MAP, 2);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.STRUCT, this.name.size);
            this.name.forEach((value_1: Translation.Translation, key_1: string): void => {
                output.writeString(key_1);
                value_1.write(output);
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        if (this.owner != null) {
            output.writeFieldBegin("owner", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.owner);
            output.writeFieldEnd();
        }
        if (this.sources != null) {
            output.writeFieldBegin("sources", thrift.Thrift.Type.LIST, 4);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.sources.length);
            this.sources.forEach((value_2: string): void => {
                output.writeString(value_2);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.serving != null) {
            output.writeFieldBegin("serving", thrift.Thrift.Type.DOUBLE, 5);
            output.writeDouble(this.serving);
            output.writeFieldEnd();
        }
        if (this.count != null) {
            output.writeFieldBegin("count", thrift.Thrift.Type.STRUCT, 6);
            this.count.write(output);
            output.writeFieldEnd();
        }
        if (this.tags != null) {
            output.writeFieldBegin("tags", thrift.Thrift.Type.LIST, 7);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.tags.length);
            this.tags.forEach((value_3: string): void => {
                output.writeString(value_3);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ingredients != null) {
            output.writeFieldBegin("ingredients", thrift.Thrift.Type.LIST, 8);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.ingredients.length);
            this.ingredients.forEach((value_4: RecipeIngredient.RecipeIngredient): void => {
                value_4.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.category != null) {
            output.writeFieldBegin("category", thrift.Thrift.Type.STRING, 9);
            output.writeString(this.category);
            output.writeFieldEnd();
        }
        if (this.steps != null) {
            output.writeFieldBegin("steps", thrift.Thrift.Type.MAP, 10);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.LIST, this.steps.size);
            this.steps.forEach((value_5: Array<string>, key_2: string): void => {
                output.writeString(key_2);
                output.writeListBegin(thrift.Thrift.Type.STRING, value_5.length);
                value_5.forEach((value_6: string): void => {
                    output.writeString(value_6);
                });
                output.writeListEnd();
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        if (this.variationIds != null) {
            output.writeFieldBegin("variationIds", thrift.Thrift.Type.LIST, 11);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.variationIds.length);
            this.variationIds.forEach((value_7: string): void => {
                output.writeString(value_7);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.comment != null) {
            output.writeFieldBegin("comment", thrift.Thrift.Type.STRING, 12);
            output.writeString(this.comment);
            output.writeFieldEnd();
        }
        if (this.ownerName != null) {
            output.writeFieldBegin("ownerName", thrift.Thrift.Type.STRING, 13);
            output.writeString(this.ownerName);
            output.writeFieldEnd();
        }
        if (this.manual != null) {
            output.writeFieldBegin("manual", thrift.Thrift.Type.LIST, 14);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.manual.length);
            this.manual.forEach((value_8: ManualItem.ManualItem): void => {
                value_8.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.price != null) {
            output.writeFieldBegin("price", thrift.Thrift.Type.STRUCT, 15);
            this.price.write(output);
            output.writeFieldEnd();
        }
        if (this.updated != null) {
            output.writeFieldBegin("updated", thrift.Thrift.Type.STRING, 16);
            output.writeString(this.updated);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Recipe {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_9: string = input.readString();
                        _args.id = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_10: Map<string, Translation.Translation> = new Map<string, Translation.Translation>();
                        const metadata_1: thrift.TMap = input.readMapBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const key_3: string = input.readString();
                            const value_11: Translation.Translation = Translation.Translation.read(input);
                            value_10.set(key_3, value_11);
                        }
                        input.readMapEnd();
                        _args.name = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_12: string = input.readString();
                        _args.owner = value_12;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_13: Array<string> = new Array<string>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_14: string = input.readString();
                            value_13.push(value_14);
                        }
                        input.readListEnd();
                        _args.sources = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_15: number = input.readDouble();
                        _args.serving = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_16: Q.Q = Q.Q.read(input);
                        _args.count = value_16;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_17: Array<string> = new Array<string>();
                        const metadata_3: thrift.TList = input.readListBegin();
                        const size_3: number = metadata_3.size;
                        for (let i_3: number = 0; i_3 < size_3; i_3++) {
                            const value_18: string = input.readString();
                            value_17.push(value_18);
                        }
                        input.readListEnd();
                        _args.tags = value_17;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_19: Array<RecipeIngredient.RecipeIngredient> = new Array<RecipeIngredient.RecipeIngredient>();
                        const metadata_4: thrift.TList = input.readListBegin();
                        const size_4: number = metadata_4.size;
                        for (let i_4: number = 0; i_4 < size_4; i_4++) {
                            const value_20: RecipeIngredient.RecipeIngredient = RecipeIngredient.RecipeIngredient.read(input);
                            value_19.push(value_20);
                        }
                        input.readListEnd();
                        _args.ingredients = value_19;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_21: string = input.readString();
                        _args.category = value_21;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 10:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_22: Map<string, Array<string>> = new Map<string, Array<string>>();
                        const metadata_5: thrift.TMap = input.readMapBegin();
                        const size_5: number = metadata_5.size;
                        for (let i_5: number = 0; i_5 < size_5; i_5++) {
                            const key_4: string = input.readString();
                            const value_23: Array<string> = new Array<string>();
                            const metadata_6: thrift.TList = input.readListBegin();
                            const size_6: number = metadata_6.size;
                            for (let i_6: number = 0; i_6 < size_6; i_6++) {
                                const value_24: string = input.readString();
                                value_23.push(value_24);
                            }
                            input.readListEnd();
                            value_22.set(key_4, value_23);
                        }
                        input.readMapEnd();
                        _args.steps = value_22;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 11:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_25: Array<string> = new Array<string>();
                        const metadata_7: thrift.TList = input.readListBegin();
                        const size_7: number = metadata_7.size;
                        for (let i_7: number = 0; i_7 < size_7; i_7++) {
                            const value_26: string = input.readString();
                            value_25.push(value_26);
                        }
                        input.readListEnd();
                        _args.variationIds = value_25;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 12:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_27: string = input.readString();
                        _args.comment = value_27;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 13:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_28: string = input.readString();
                        _args.ownerName = value_28;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 14:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_29: Array<ManualItem.ManualItem> = new Array<ManualItem.ManualItem>();
                        const metadata_8: thrift.TList = input.readListBegin();
                        const size_8: number = metadata_8.size;
                        for (let i_8: number = 0; i_8 < size_8; i_8++) {
                            const value_30: ManualItem.ManualItem = ManualItem.ManualItem.read(input);
                            value_29.push(value_30);
                        }
                        input.readListEnd();
                        _args.manual = value_29;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 15:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_31: Price.Price = Price.Price.read(input);
                        _args.price = value_31;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 16:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_32: string = input.readString();
                        _args.updated = value_32;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.name !== undefined && _args.owner !== undefined && _args.sources !== undefined && _args.serving !== undefined && _args.tags !== undefined && _args.ingredients !== undefined && _args.category !== undefined && _args.steps !== undefined && _args.variationIds !== undefined && _args.comment !== undefined && _args.ownerName !== undefined && _args.manual !== undefined && _args.updated !== undefined) {
            return new Recipe(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Recipe from input");
        }
    }
}
