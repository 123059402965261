import { GetCoreIndexDB } from '@stack/frontend-core/cls/IndexedDB';

const CoreIndexedDb = GetCoreIndexDB({
  dbVersion: 4,
  dbName: 'cooking',
  onUpgradeNeeded: (event: IDBVersionChangeEvent): void => {
    const db = (event.target as IDBOpenDBRequest).result;

    if (event.oldVersion < 1) {
      db.createObjectStore(IndexedDB.OBJECT_STORE_INGREDIENTS, { keyPath: 'id' });
    }
    if (event.oldVersion < 2) {
      db.createObjectStore(IndexedDB.OBJECT_STORE_RECIPES, {keyPath: 'id'});
    }
    if (event.oldVersion < 3) {
      db.createObjectStore(IndexedDB.OBJECT_STORE_PREMENUS, {keyPath: 'id'});
    }
    if (event.oldVersion < 4) {
      db.createObjectStore(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, {keyPath: 'id'});
    }
  },
});

export default class IndexedDB extends CoreIndexedDb {
  public static OBJECT_STORE_INGREDIENTS = 'ingredients';
  public static OBJECT_STORE_RECIPES = 'recipes';
  public static OBJECT_STORE_SHOPPING_LISTS = 'shoppinglists';
  public static OBJECT_STORE_PREMENUS = 'premenus';
}
