// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';

import logo from 'assets/logo128.png';



export const Logo = (props: {className?: string}) => {
  return <img height={34} width={34} src={logo} alt="logo" className={props.className} />;
}
