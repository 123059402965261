/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as TagToMeal from "./TagToMeal";
export interface IProfileArgs {
    userId: string;
    name: string;
    color: string;
    sex?: string;
    height?: number;
    weight?: number;
    birth?: number;
    activity?: number;
    meals: Array<string>;
    tagsToMeal: Array<TagToMeal.TagToMeal>;
    price?: string;
}
export class Profile {
    public userId: string;
    public name: string;
    public color: string;
    public sex?: string;
    public height?: number;
    public weight?: number;
    public birth?: number;
    public activity?: number;
    public meals: Array<string>;
    public tagsToMeal: Array<TagToMeal.TagToMeal>;
    public price?: string;
    constructor(args: IProfileArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[name] is unset!");
        }
        if (args != null && args.color != null) {
            this.color = args.color;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[color] is unset!");
        }
        if (args != null && args.sex != null) {
            this.sex = args.sex;
        }
        if (args != null && args.height != null) {
            this.height = args.height;
        }
        if (args != null && args.weight != null) {
            this.weight = args.weight;
        }
        if (args != null && args.birth != null) {
            this.birth = args.birth;
        }
        if (args != null && args.activity != null) {
            this.activity = args.activity;
        }
        if (args != null && args.meals != null) {
            this.meals = args.meals;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[meals] is unset!");
        }
        if (args != null && args.tagsToMeal != null) {
            this.tagsToMeal = args.tagsToMeal;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[tagsToMeal] is unset!");
        }
        if (args != null && args.price != null) {
            this.price = args.price;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Profile");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.name);
            output.writeFieldEnd();
        }
        if (this.color != null) {
            output.writeFieldBegin("color", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.color);
            output.writeFieldEnd();
        }
        if (this.sex != null) {
            output.writeFieldBegin("sex", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.sex);
            output.writeFieldEnd();
        }
        if (this.height != null) {
            output.writeFieldBegin("height", thrift.Thrift.Type.I16, 5);
            output.writeI16(this.height);
            output.writeFieldEnd();
        }
        if (this.weight != null) {
            output.writeFieldBegin("weight", thrift.Thrift.Type.I16, 6);
            output.writeI16(this.weight);
            output.writeFieldEnd();
        }
        if (this.birth != null) {
            output.writeFieldBegin("birth", thrift.Thrift.Type.I16, 7);
            output.writeI16(this.birth);
            output.writeFieldEnd();
        }
        if (this.activity != null) {
            output.writeFieldBegin("activity", thrift.Thrift.Type.BYTE, 8);
            output.writeByte(this.activity);
            output.writeFieldEnd();
        }
        if (this.meals != null) {
            output.writeFieldBegin("meals", thrift.Thrift.Type.LIST, 9);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.meals.length);
            this.meals.forEach((value_1: string): void => {
                output.writeString(value_1);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.tagsToMeal != null) {
            output.writeFieldBegin("tagsToMeal", thrift.Thrift.Type.LIST, 10);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.tagsToMeal.length);
            this.tagsToMeal.forEach((value_2: TagToMeal.TagToMeal): void => {
                value_2.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.price != null) {
            output.writeFieldBegin("price", thrift.Thrift.Type.STRING, 11);
            output.writeString(this.price);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Profile {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.userId = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.name = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_5: string = input.readString();
                        _args.color = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_6: string = input.readString();
                        _args.sex = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.I16) {
                        const value_7: number = input.readI16();
                        _args.height = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I16) {
                        const value_8: number = input.readI16();
                        _args.weight = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.I16) {
                        const value_9: number = input.readI16();
                        _args.birth = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.BYTE) {
                        const value_10: number = input.readByte();
                        _args.activity = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_11: Array<string> = new Array<string>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_12: string = input.readString();
                            value_11.push(value_12);
                        }
                        input.readListEnd();
                        _args.meals = value_11;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 10:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_13: Array<TagToMeal.TagToMeal> = new Array<TagToMeal.TagToMeal>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_14: TagToMeal.TagToMeal = TagToMeal.TagToMeal.read(input);
                            value_13.push(value_14);
                        }
                        input.readListEnd();
                        _args.tagsToMeal = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 11:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_15: string = input.readString();
                        _args.price = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined && _args.name !== undefined && _args.color !== undefined && _args.meals !== undefined && _args.tagsToMeal !== undefined) {
            return new Profile(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Profile from input");
        }
    }
}
