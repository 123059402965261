import Measure from 'cls/Measure';
import Portion from 'cls/Portion';
import RawQuantity from 'cls/RawQuantity';
import Utils from 'cls/Utils';
import { Measure as MeasureEnum, Q } from 'thriftgen';
import { PickQuantityItemType } from 'ui/pick-quantity';

export default class Quantity {
  m: Measure;
  n: number;
  p: Portion;

  constructor(n: number, m: Measure, p: Portion | undefined = undefined) {
    this.n = n;
    this.m = m;
    this.p = p === undefined ? new Portion() : p;
  }

  copy(): Quantity {
    return new Quantity(this.n, this.m, this.p);
  }

  multiply(x: number) {
    this.n = x * this.n;
  }

  normalize(): Quantity {
    return Quantity.normalize(this);
  }

  get rawQuantity(): RawQuantity {
    return new RawQuantity(this.n, this.m.q, this.p);
  }

  inG(): Quantity | undefined {
    const raw = this.rawQuantity.inG();
    if (raw === undefined) { return undefined; }
    return Quantity.fromRaw(raw);
  }

  in(m: MeasureEnum): Quantity | undefined {
    const raw = this.rawQuantity.in(m);
    if (raw === undefined) { return undefined; }
    return Quantity.fromRaw(raw);
  }

  get text(): string {
    return this.m.text(this.n);
  }

  get q(): Q {
    return new Q({n: this.n, m: this.m.q});
  }

  get shortText(): string {
    return this.m.shortText(this.n);
  }

  static init(s: string | undefined, portion: Portion | undefined = undefined) {
    const p = portion || new Portion();
    if (s === undefined) {
      return new Quantity(1, Measure.all()[MeasureEnum.COUNT], p);
    }
    const parsed = Utils.parseNum(s);
    return new Quantity(parsed[0], parsed[1], p);
  }

  static init3(item: PickQuantityItemType | undefined) {
    if (item === undefined) { return new Quantity(1, Measure.all()[MeasureEnum.COUNT]) }
    return new Quantity(
      item.serving || 1,
      Measure.getDefaultMeasure(item.portions, item.measure),
      item.portions,
    );
  }


  static init4(q: Q, portion: Portion | undefined = undefined) {
    const p = portion || new Portion();
    const m = Measure.all()[q.m];
    return new Quantity(q.n, m, p);
  }

  static add(q1: Quantity, q2: Quantity): Quantity | undefined {
    const raw = RawQuantity.add(q1.rawQuantity, q2.rawQuantity);
    if (raw === undefined) { return undefined; }
    return Quantity.fromRaw(raw);
  }

  static subtract(q1: Quantity, q2: Quantity): Quantity | undefined {
    const raw = RawQuantity.subtract(q1.rawQuantity, q2.rawQuantity);
    if (raw === undefined) { return undefined; }
    return Quantity.fromRaw(raw);
  }

  static getRulesFromG(q: Quantity): {[key: number]: number} {
    return RawQuantity.getRulesFromG(q.rawQuantity);
  }

  static getRulesToG(q: Quantity): {[key: number]: number} {
    return RawQuantity.getRulesToG(q.rawQuantity);
  }

  static normalize(q: Quantity): Quantity {
    const raw = RawQuantity.normalize(q.rawQuantity);
    return Quantity.fromRaw(raw);
  }

  static fromRaw(raw: RawQuantity): Quantity {
    return Quantity.init4(new Q({n: raw.n, m: raw.m}), raw.p);
  }

  static fromQ(q: Q): Quantity {
    return Quantity.init4(new Q({n: q.n, m: q.m}), undefined);
  }

  static defaultZero(): Quantity {
    return Quantity.init4(new Q({n: 0, m: MeasureEnum.COUNT}), undefined);
  }
  static defaultOne(): Quantity {
    return Quantity.init4(new Q({n: 1, m: MeasureEnum.COUNT}), undefined);
  }
  static defaultOnePortion(): Quantity {
    return Quantity.init4(new Q({n: 1, m: MeasureEnum.PORTION}), undefined);
  }
  static defaultThreePortions(): Quantity {
    return Quantity.init4(new Q({n: 3, m: MeasureEnum.PORTION}), undefined);
  }
  static defaultCount(n: number): Quantity {
    return Quantity.init4(new Q({n: n, m: MeasureEnum.COUNT}), undefined);
  }
  static default100G(): Quantity {
    return Quantity.init4(new Q({n: 100, m: MeasureEnum.G}), undefined)
  }
}