import Ingredient from 'cls/Ingredient';
import Recipe from 'cls/Recipe';
import ShoppingList from 'cls/ShoppingList';
import Utils from 'cls/Utils';
import X from 'cls/X';
import { Q } from 'thriftgen';
import { CellarItem as ThriftCellarItem } from 'thriftgen/CellarItem';
import { PickQuantityItemType } from 'ui/pick-quantity';

export default class CellarItem extends X {
  type: string = "cellar-item";

  id: string;
  ingredientId?: string;
  recipeId?: string;
  recipe?: Recipe;
  ingredient?: Ingredient;
  date: string;
  shoppingList?: ShoppingList;
  shoppingListId?: string;
  used: boolean;
  nutrition: number = 1;
  broken?: string;

  constructor(x: ThriftCellarItem) {
    super(x.q);
    this.id = x.id;
    this.ingredientId = x.ingredientId;
    this.recipeId = x.recipeId;
    this.shoppingListId = x.shoppingListId;
    this.broken = x.broken;
    const inShoppingList = x.shoppingListId !== undefined && x.shoppingListId !== "";
    const isBroken = x.broken !== undefined && x.broken !== "";
    this.used = inShoppingList || isBroken;
    this.date = x.date;
  }

  static init = (xs: ThriftCellarItem[]) => xs.map((x) => new CellarItem(x));
  static empty(): CellarItem {
    const q = new Q({n: 1, m: 1});
    const c = new CellarItem(new ThriftCellarItem({
      date: Utils.todayDate(),
      id: '',
      q: q,
    }));
    c.setQ(q);
    return c;
  }

  get item(): PickQuantityItemType | undefined {
    if (this.ingredient !== undefined) {
      return this.ingredient;
    }
    if (this.recipe !== undefined) {
      return this.recipe;
    }
    return undefined;
  }

  setRecipes(xs: Recipe[]) {
    this.recipe = xs.find((x) => x.id === this.recipeId);
  }
  setIngredients(xs: Ingredient[]) {
    this.ingredient = xs.find((x) => x.id === this.ingredientId);
  }
  setShoppingLists(xs: ShoppingList[]) {
    this.shoppingList = xs.find((x) => x.id === this.shoppingListId);
  }
  setBroken() {
    this.broken = Utils.nowUTC();
    this.used = true;
  }
  setUnbroken() {
    this.broken = undefined;
    this.used = this.shoppingListId !== undefined && this.shoppingListId !== "";
  }
  setNoShoppingLists() {
    this.shoppingList = undefined;
    this.shoppingListId = undefined;
    this.used = false;
  }
  setShoppingList(x: ShoppingList) {
    this.shoppingListId = x.id;
    this.shoppingList = x;
    this.used = this.shoppingListId !== undefined && this.shoppingListId !== "";
  }

  get name(): string {
    if (this.ingredient !== undefined) {
      return this.ingredient.name;
    }
    if (this.recipe !== undefined) {
      return this.recipe.name;
    }
    return '?';
  }

  get url(): string {
    if (this.ingredient !== undefined) {
      return this.ingredient.url;
    }
    if (this.recipe !== undefined) {
      return this.recipe.url;
    }
    return '';
  }

  thrift() {
    return new ThriftCellarItem({
      id: this.id,
      ingredientId: this.ingredientId,
      recipeId: this.recipeId,
      date: this.date,
      q: this.q,
      broken: this.broken,
    });
  }

  copy() {
    const result = new CellarItem(new ThriftCellarItem({
      id: this.id,
      ingredientId: this.ingredientId,
      recipeId: this.recipeId,
      shoppingListId: this.shoppingListId,
      date: this.date,
      q: this.q,
      broken: this.broken,
    }));
    result.ingredient = this.ingredient;
    result.recipe = this.recipe;
    result.shoppingList = this.shoppingList;
    return result;
  }
}
