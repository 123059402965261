/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
export interface ITranslationArgs {
    sn: string;
    sg?: string;
    pn?: string;
    pg?: string;
}
export class Translation {
    public sn: string;
    public sg?: string;
    public pn?: string;
    public pg?: string;
    constructor(args: ITranslationArgs) {
        if (args != null && args.sn != null) {
            this.sn = args.sn;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[sn] is unset!");
        }
        if (args != null && args.sg != null) {
            this.sg = args.sg;
        }
        if (args != null && args.pn != null) {
            this.pn = args.pn;
        }
        if (args != null && args.pg != null) {
            this.pg = args.pg;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Translation");
        if (this.sn != null) {
            output.writeFieldBegin("sn", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.sn);
            output.writeFieldEnd();
        }
        if (this.sg != null) {
            output.writeFieldBegin("sg", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.sg);
            output.writeFieldEnd();
        }
        if (this.pn != null) {
            output.writeFieldBegin("pn", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.pn);
            output.writeFieldEnd();
        }
        if (this.pg != null) {
            output.writeFieldBegin("pg", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.pg);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Translation {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.sn = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.sg = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.pn = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.pg = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.sn !== undefined) {
            return new Translation(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Translation from input");
        }
    }
}
