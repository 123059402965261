import * as _ from "underscore";

import Constants from 'cls/Constants';
import Measure from 'cls/Measure';
import Portion from 'cls/Portion';
import Price from 'cls/Price';
import Quantity from 'cls/Quantity';
import Urls from 'cls/Urls';
import { Measure as MeasureEnum, Q } from 'thriftgen';
import { Ingredient as ThriftIngredient } from 'thriftgen/Ingredient';
import { MenuItem as ThriftMenuItem } from 'thriftgen/MenuItem';
import { Owner as ThriftOwner } from 'thriftgen/Owner';

class ApiImg {
  src: string;
  text: string;

  constructor(x: ApiImg) {
    this.src = x.src;
    this.text = x.text;
  }
}

export default class Ingredient {
  type: string = "ingredient";

  id: string;
  name: string;
  nameGenitive: string;
  pluralName: string;
  pluralNameGenitive: string;
  tags: string[];
  category?: string;
  img?: ApiImg;
  measure: string;
  portions: Portion;
  print?: string[];
  printM?: Measure[];
  season?: {start?: number, end?: number, months?: number[]}
  price: Price

  constructor(x: ThriftIngredient) {
    const language = 'pl';

    this.id = x.id;
    this.name = x.name.get(language)?.sn || "";
    this.nameGenitive = x.name.get(language)?.sg || this.name;
    this.pluralName = x.name.get(language)?.pn || this.name;
    this.pluralNameGenitive = x.name.get(language)?.pg || this.pluralName;
    this.tags = x.tags || [];
    this.category = x.category;
    this.measure = x.measure;
    this.portions = new Portion({
      medium: x.portions?.medium,
      slice: x.portions?.slice,
      sprig: x.portions?.sprig,
      bunch: x.portions?.bunch,
      stalk: x.portions?.stalk,
      ml: x.portions?.ml,
      handful: x.portions?.handful,
    });
    this.season = {start: x.season?.startDate, end: x.season?.endDate, months: x.season?.months};
    this.print = (x.printAs === undefined || x.printAs.length === 0) ? undefined : x.printAs;
    this.printM = this.print === undefined ? undefined : (this.print.map((m) => Measure.init(m)) || undefined);

    if (
      x.img !== undefined && x.img.src !== undefined &&
      x.img.src.startsWith('https://brokli.pl/media/img/ingredients/stock_520_410')
    ) {
      this.img = undefined;
    } else {
      this.img = x.img !== undefined && x.img.src !== undefined ? new ApiImg(x.img) : undefined;
    }

    this.price = new Price(x.price);

    if (this.isInSeason) {
      if (this.category === Constants.TAG_FRUIT) {
        this.tags.push(Constants.TAG_IN_SEASON_FRUIT);
      }
      if (this.category === Constants.TAG_VEGETABLE) {
        this.tags.push(Constants.TAG_IN_SEASON_VEGETABLE);
      }
    }
  }

  static init = (xs: ThriftIngredient[]) => xs.map((x) => new Ingredient(x));

  static allTags(xs: Ingredient[]): string[] {
    return _.chain(xs)
      .map((x) => x.tags)
      .flatten()
      .uniq()
      .value();
  }

  get url(): string {
    return Urls.INGREDIENT_ID(this.id);
  }
  get seasonMonths(): number[] {
    if (this.season === undefined) { return []; }
    if (this.season.months !== undefined) {
      return this.season.months;
    }
    if (this.season.start === undefined) { return []; }
    if (this.season.end === undefined) { return []; }
    // 6 - 9
    const s = this.season.start;
    const e = this.season.end;
    if (s <= e) {
      return Array
        .from(
          Array(e - s + 1).keys()
        )
        .map((x) => x + s)
    }
    // 10 - 1
    return Array.from(
      Array(12 - s + 1 + e).keys()
    ).map((x) => {
      const n = x + s - 12;
      return n <= 0 ? n + 12 : x + s - 12;
    });
  }
  get isInSeasonLastMonth(): boolean {
    if (this.season === undefined) { return false; }
    const m = new Date().getMonth() + 1;
    if (this.season.months !== undefined) {
      return false;
    }
    if (this.season.start === undefined) { return false; }
    if (this.season.end === undefined) { return false; }
    return this.season.end === m;
  }
  get isInSeason(): boolean {
    if (this.season === undefined) { return false; }
    const m = new Date().getMonth() + 1;

    if (this.season.months !== undefined) {
      return this.season.months.indexOf(m) !== -1;
    }
    if (this.season.start === undefined) { return false; }
    if (this.season.end === undefined) { return false; }
    // 6 - 9
    if (this.season.start <= this.season.end) {
      return this.season.start <= m && m <= this.season.end;
    }
    // 11 - 4
    return this.season.start <= m || m <= this.season.end;
  }
  get defaultMeasure(): string {
    const sprig = Measure.all()[MeasureEnum.SPRIG];
    if (this.measure === "count") { return "1"; }
    if (this.measure === "g") { return "100g"; }
    if (this.measure === "ml") { return "5ml"; }
    if (this.measure === "sprig") { return sprig.text(1); }
    return "1";
  }
  get defaultQ(): Q {
    if (this.measure === "count") { return new Q({n: 1, m: MeasureEnum.COUNT}); }
    if (this.measure === "g") { return new Q({n: 100, m: MeasureEnum.G}); }
    if (this.measure === "ml") { return new Q({n: 5, m: MeasureEnum.ML}); }
    if (this.measure === "sprig") { return new Q({n: 1, m: MeasureEnum.SPRIG}); }
    return new Q({n: 1, m: MeasureEnum.COUNT});
  }
  newMenuItem(owner: ThriftOwner, date: string | undefined, q: Quantity | undefined): ThriftMenuItem {
    return new ThriftMenuItem({
      id: '',
      ingredientId: this.id,
      owner: owner,
      date: date || '',
      q: q?.q || this.defaultQ,
    })
  }

  searchMatch(text: string): boolean {
    const search = (this.id + this.name).toLocaleLowerCase();
    return search.includes(text.toLowerCase());
  }

  get measureOptions(): string[] {
    const sprig = Measure.all()[MeasureEnum.SPRIG];
    if (this.measure === "count") {
      return Array(15)
        .fill(0)
        .map((_, i) => (i + 1).toString(10));
    } else if (this.measure === "g") {
      return Array(15)
        .fill(0)
        .map((_, i) => ((i + 1) * 100).toString(10) + 'g'
      );
    } else if (this.measure === "ml") {
      return Array(15)
        .fill(0)
        .map((_, i) => ((i + 1) * 5).toString(10) + 'ml');
    } else if (this.measure === "sprig") {
      return Array(15)
        .fill(0)
        .map((_, i) => sprig.text(i+1));
    }
    return [];
  }
}
