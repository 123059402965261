import { Col, Container, Row } from 'react-bootstrap';

import { Header } from 'content/landing/Header';
import { PricingPlans } from 'content/landing/PricingPlans';
import FooterScene from 'page/footer';


export const LandingPricingScene = () => {
  return (
    <>
      <Header />
      <Container fluid={true}>
        <div className="d-flex flex-column align-items-md-center justify-content-center under-nav mb-5">
          <h1 className="display-1 mt-6">{process.env.REACT_APP_BUY_SUBSCRIPTION}</h1>
          <p className="lead">{process.env.REACT_APP_BUY_SUBSCRIPTION_LEAD}</p>
        </div>
        <Row>
          <Col xs={12} lg={{offset: 1, span: 10}} className="mb-5">
            <PricingPlans />
          </Col>
        </Row>
      </Container>
      <FooterScene />
    </>
  )
};