/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as PartyPerson from "./PartyPerson";
export interface IPartyArgs {
    id: string;
    user: string;
    name: string;
    persons: Array<PartyPerson.PartyPerson>;
    created: string;
    shoppingListId?: string;
}
export class Party {
    public id: string;
    public user: string;
    public name: string;
    public persons: Array<PartyPerson.PartyPerson>;
    public created: string;
    public shoppingListId?: string;
    constructor(args: IPartyArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.user != null) {
            this.user = args.user;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[user] is unset!");
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[name] is unset!");
        }
        if (args != null && args.persons != null) {
            this.persons = args.persons;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[persons] is unset!");
        }
        if (args != null && args.created != null) {
            this.created = args.created;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[created] is unset!");
        }
        if (args != null && args.shoppingListId != null) {
            this.shoppingListId = args.shoppingListId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Party");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.user != null) {
            output.writeFieldBegin("user", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.user);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.name);
            output.writeFieldEnd();
        }
        if (this.persons != null) {
            output.writeFieldBegin("persons", thrift.Thrift.Type.LIST, 4);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.persons.length);
            this.persons.forEach((value_1: PartyPerson.PartyPerson): void => {
                value_1.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.created != null) {
            output.writeFieldBegin("created", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.created);
            output.writeFieldEnd();
        }
        if (this.shoppingListId != null) {
            output.writeFieldBegin("shoppingListId", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.shoppingListId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Party {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.id = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.user = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.name = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_5: Array<PartyPerson.PartyPerson> = new Array<PartyPerson.PartyPerson>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_6: PartyPerson.PartyPerson = PartyPerson.PartyPerson.read(input);
                            value_5.push(value_6);
                        }
                        input.readListEnd();
                        _args.persons = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.created = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_8: string = input.readString();
                        _args.shoppingListId = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.user !== undefined && _args.name !== undefined && _args.persons !== undefined && _args.created !== undefined) {
            return new Party(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Party from input");
        }
    }
}
