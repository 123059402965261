import { Action } from 'redux';

import { ActionTypes } from 'cls/redux/actions';

export interface ToggleSidebarAction extends Action {
  type: ActionTypes.ToggleSidebar,
}
export interface CloseSidebarAction extends Action {
  type: ActionTypes.CloseSidebar,
}

export type SidebarActions =
  ToggleSidebarAction |
  CloseSidebarAction

export interface SidebarState {
  isHidden: boolean;
}

export const sidebarReducer = (state: SidebarState = { isHidden: true }, action: SidebarActions): SidebarState => {
  switch (action.type) {
    case ActionTypes.ToggleSidebar:
      return {
        ...state,
        isHidden: !state.isHidden
      };
    case ActionTypes.CloseSidebar:
      return {
        ...state,
        isHidden: true
      };
    default:
      return state;
  }
};
