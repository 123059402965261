/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as Q from "./Q";
export interface ICellarItemArgs {
    id: string;
    recipeId?: string;
    ingredientId?: string;
    shoppingListId?: string;
    date: string;
    q: Q.Q;
    broken?: string;
}
export class CellarItem {
    public id: string;
    public recipeId?: string;
    public ingredientId?: string;
    public shoppingListId?: string;
    public date: string;
    public q: Q.Q;
    public broken?: string;
    constructor(args: ICellarItemArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.recipeId != null) {
            this.recipeId = args.recipeId;
        }
        if (args != null && args.ingredientId != null) {
            this.ingredientId = args.ingredientId;
        }
        if (args != null && args.shoppingListId != null) {
            this.shoppingListId = args.shoppingListId;
        }
        if (args != null && args.date != null) {
            this.date = args.date;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[date] is unset!");
        }
        if (args != null && args.q != null) {
            this.q = args.q;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[q] is unset!");
        }
        if (args != null && args.broken != null) {
            this.broken = args.broken;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("CellarItem");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.recipeId != null) {
            output.writeFieldBegin("recipeId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.recipeId);
            output.writeFieldEnd();
        }
        if (this.ingredientId != null) {
            output.writeFieldBegin("ingredientId", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.ingredientId);
            output.writeFieldEnd();
        }
        if (this.shoppingListId != null) {
            output.writeFieldBegin("shoppingListId", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.shoppingListId);
            output.writeFieldEnd();
        }
        if (this.date != null) {
            output.writeFieldBegin("date", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.date);
            output.writeFieldEnd();
        }
        if (this.q != null) {
            output.writeFieldBegin("q", thrift.Thrift.Type.STRUCT, 7);
            this.q.write(output);
            output.writeFieldEnd();
        }
        if (this.broken != null) {
            output.writeFieldBegin("broken", thrift.Thrift.Type.STRING, 8);
            output.writeString(this.broken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): CellarItem {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.id = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.recipeId = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.ingredientId = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.shoppingListId = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_5: string = input.readString();
                        _args.date = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_6: Q.Q = Q.Q.read(input);
                        _args.q = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.broken = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.date !== undefined && _args.q !== undefined) {
            return new CellarItem(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read CellarItem from input");
        }
    }
}
