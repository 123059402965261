/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
export enum EType {
    PERMISSION_DENIED = 0,
    NOT_FOUND = 1,
    NOT_VALID = 2,
    AUTHENTICATION_FAILED = 3,
    FEATURE_FLAG_OFF = 4
}
