/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
export enum Measure {
    UNKNOWN = 0,
    COUNT = 1,
    TEASPOON = 2,
    TABLESPOON = 3,
    CUP = 4,
    ML = 5,
    L = 6,
    G = 7,
    HANDFUL = 8,
    SLICE = 9,
    PINCH = 10,
    SPRIG = 11,
    BREAD_SLICE = 12,
    PORTION = 13,
    BUNCH = 14,
    STALK = 15,
    KG = 16
}
