/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as OwnerType from "./OwnerType";
export interface IOwnerArgs {
    ownerType: OwnerType.OwnerType;
    id: string;
}
export class Owner {
    public ownerType: OwnerType.OwnerType;
    public id: string;
    constructor(args: IOwnerArgs) {
        if (args != null && args.ownerType != null) {
            this.ownerType = args.ownerType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[ownerType] is unset!");
        }
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Owner");
        if (this.ownerType != null) {
            output.writeFieldBegin("ownerType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.ownerType);
            output.writeFieldEnd();
        }
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Owner {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_1: OwnerType.OwnerType = input.readI32();
                        _args.ownerType = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.id = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.ownerType !== undefined && _args.id !== undefined) {
            return new Owner(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Owner from input");
        }
    }
}
