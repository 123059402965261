/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as CellarItem from "./CellarItem";
export interface ICellarArgs {
    items: Array<CellarItem.CellarItem>;
}
export class Cellar {
    public items: Array<CellarItem.CellarItem>;
    constructor(args: ICellarArgs) {
        if (args != null && args.items != null) {
            this.items = args.items;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[items] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Cellar");
        if (this.items != null) {
            output.writeFieldBegin("items", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.items.length);
            this.items.forEach((value_1: CellarItem.CellarItem): void => {
                value_1.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Cellar {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_2: Array<CellarItem.CellarItem> = new Array<CellarItem.CellarItem>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_3: CellarItem.CellarItem = CellarItem.CellarItem.read(input);
                            value_2.push(value_3);
                        }
                        input.readListEnd();
                        _args.items = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.items !== undefined) {
            return new Cellar(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Cellar from input");
        }
    }
}
