import { newDangerToast, newSuccessToast } from '@stack/frontend-core/cls/Toast';

import { ToMenuItem } from 'cls/Interfaces';
import { Item } from 'cls/Item';
import Recipe from 'cls/Recipe';

export default class Messages {
  static addToMenuSuccessToast(item: ToMenuItem) {
    return newSuccessToast('Dodano do menu', `"${item.name}" zostało dodane do menu`);
  }
  static createdRecipeSuccessToast(item: Recipe) {
    return newSuccessToast('Dodano przepis', `Przepis "${item.name}" został stworzony`);
  }
  static updatedRecipeSuccessToast(item: Recipe) {
    return newSuccessToast('Zapisano przepis', `Przepis "${item.name}" został zapisany`);
  }
  static deleteRecipeSuccessToast(item: Recipe) {
    return newDangerToast('Usunięto przepis', `Przepis "${item.name}" został usunięty`);
  }
  static sendFeedbackSuccessToast() {
    return newSuccessToast("Wysłano informację zwrotną", "Twoja informacja zwrotna została wysłana. Dziękujemy!");
  }
  static error() {
    return newDangerToast(process.env.REACT_APP_TOAST_ERR_TITLE || '', process.env.REACT_APP_TOAST_ERR_BODY || '');
  }
  static updatedShareSuccessToast(item: Item) {
    return newSuccessToast('Zapisano ustawienia', `Ustawienia udostępniania dla "${item.name}" zostały zapisane`);
  }

  static deleteSuccessToast(item: Item) {
    switch (item.type) {
      case "recipe": {
        return newDangerToast('Usunięto przepis', `Przepis "${item.name}" został usunięty`);
      }
      case "premenu": {
        return newDangerToast('Usunięto plan', `Plan "${item.name}" został usunięty`);
      }
    }
    return newDangerToast('Usunięto obiekt', `Obiekt "${item.name}" został usunięty`);
  }
}
