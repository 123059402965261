import { Col, Container, Row } from 'react-bootstrap';

import { Feature } from 'content/landing/Feature';

const features = [1, 2, 3, 4, 5, 6].map((x) => {
  return {
    title: process.env[`REACT_APP_LANDING_F${x}_TITLE`] || '',
    description: process.env[`REACT_APP_LANDING_F${x}_SUBTITLE`] || '',
    imgs: [1, 2, 3].map((y) => {
      return {
        src: process.env[`REACT_APP_LANDING_F${x}_IMG${y}`] || '',
        header: process.env[`REACT_APP_LANDING_F${x}_IMG${y}_HEADER`] || '',
      }
    }),
  };
});

export const Features = () => {
  return <Container fluid={true} className="mb-5 p-0">
    <Row>
      <Col xs={12} lg={{offset: 1, span: 10}}>
        {features.map((x, xi) => (
          <Feature key={xi} {...x} />
        ))}
      </Col>
    </Row>
  </Container>
}