/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
export interface IApiImgArgs {
    src: string;
    text: string;
}
export class ApiImg {
    public src: string;
    public text: string;
    constructor(args: IApiImgArgs) {
        if (args != null && args.src != null) {
            this.src = args.src;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[src] is unset!");
        }
        if (args != null && args.text != null) {
            this.text = args.text;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[text] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ApiImg");
        if (this.src != null) {
            output.writeFieldBegin("src", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.src);
            output.writeFieldEnd();
        }
        if (this.text != null) {
            output.writeFieldBegin("text", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.text);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ApiImg {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.src = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.text = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.src !== undefined && _args.text !== undefined) {
            return new ApiImg(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read ApiImg from input");
        }
    }
}
