import React from 'react';
import { Card } from 'react-bootstrap';

import { Stars } from 'ui/stars';

export const Review = (props: {text: string, rating: undefined | 1 | 2 | 3 | 4 | 5, author: string}) => {
  return <Card>
    <Card.Body>
      <Stars stars={props.rating} />
      <p className="my-2">{ props.text }</p>
      <b>{ props.author }</b>
    </Card.Body>
  </Card>;
}