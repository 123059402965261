import * as _ from 'underscore';

import Ingredient from 'cls/Ingredient';
import Recipe from 'cls/Recipe';
import RecipeIngredient from 'cls/RecipeIngredient';
import ShoppingList from 'cls/ShoppingList';
import X from 'cls/X';
import { Party as ThriftParty } from 'thriftgen/Party';
import { PartyPerson as ThriftPartyPerson } from 'thriftgen/PartyPerson';

export class PartyPerson {
  factor: number;
  ingredients: RecipeIngredient[];

  constructor(x: ThriftPartyPerson) {
    this.factor = x.factor;
    this.ingredients = RecipeIngredient.init(x.ingredients, []);
  }

  setRecipes(xs: Recipe[]) {
    this.ingredients.forEach((x) => x.setRecipes(xs));
  }

  setIngredients(xs: Ingredient[]) {
    this.ingredients.forEach((x) => x.setIngredients(xs));
  }

  copy(): PartyPerson {
    const ingredients = this.ingredients.map((x) => x.copy())
    const x = new PartyPerson(this.thrift());
    x.ingredients = ingredients;
    return x;
  }

  thrift(): ThriftPartyPerson {
    return new ThriftPartyPerson({
      factor: this.factor,
      ingredients: this.ingredients.map((x) => x.thrift()),
    });
  }

  static empty(): PartyPerson {
    return new PartyPerson(new ThriftPartyPerson({
      factor: 1,
      ingredients: [],
    }));
  }

  static init(xs: ThriftPartyPerson[]): PartyPerson[] {
    return xs.map((x) => new PartyPerson(x));
  }
}

export default class Party {
  type: string = "party";

  id: string;
  name: string;
  user: string;
  created: string;
  persons: PartyPerson[];
  shoppingListId?: string;
  shoppingList?: ShoppingList;
  used: boolean;

  constructor(x: ThriftParty) {
    this.id = x.id;
    this.name = x.name;
    this.user = x.user;
    this.created = x.created;
    this.persons = PartyPerson.init(x.persons);
    this.shoppingListId = x.shoppingListId;
    this.used = x.shoppingListId !== undefined && x.shoppingListId !== "";
  }

  setShoppingLists(xs: ShoppingList[]) {
    this.shoppingList = xs.find((x) => x.id === this.shoppingListId);
  }

  setNoShoppingLists() {
    this.shoppingList = undefined;
    this.shoppingListId = undefined;
    this.used = false;
  }
  setShoppingList(x: ShoppingList) {
    this.shoppingListId = x.id;
    this.shoppingList = x;
    this.used = this.shoppingListId !== undefined && this.shoppingListId !== "";
  }

  get ingredientExamples(): {[key: string]: RecipeIngredient} {
    const ingredientExamples: {[key: string]: RecipeIngredient} = {};
    const ingredientByIdByPerson: {[key: string]: {[key: number]: X}} = {};
    this.persons.forEach((person, pi) => {
      ingredientByIdByPerson[pi] = {};
      person.ingredients.forEach((x) => {
        if (ingredientByIdByPerson[x.id] === undefined) {
          ingredientByIdByPerson[x.id] = {};
        }
        ingredientByIdByPerson[x.id][pi] = new X(x.q);
        ingredientExamples[x.id] = x;
      })
    });
    return ingredientExamples;
  }

  get ingredientByIdByPerson(): {[key: string]: {[key: number]: X}} {
    const ingredientByIdByPerson: {[key: string]: {[key: number]: X}} = {};
    const totals: {[key: string]: RecipeIngredient} = {};

    this.persons.forEach((person, pi) => {
      ingredientByIdByPerson[pi] = {};
      person.ingredients.forEach((x) => {
        if (ingredientByIdByPerson[x.id] === undefined) {
          ingredientByIdByPerson[x.id] = {};
        }
        ingredientByIdByPerson[x.id][pi] = new X(x.q);

        const ri = x.copy().multiply(person.factor);
        if (totals[x.id] === undefined) {
          totals[x.id] = ri;
        } else {
          totals[x.id] = RecipeIngredient.sum([totals[x.id], ri])[0];
        }
      })
    });

    _.keys(totals).forEach((xid) => {
      ingredientByIdByPerson[xid][-1] = new X(totals[xid].q);
    });

    return ingredientByIdByPerson;
  }

  get ingredientIds(): string[] {
    const examples = this.ingredientExamples;
    return _.chain(examples)
      .keys()
      .map((x) => [x, examples[x]?.name])
      .sortBy((x) => x[1])
      .map((x) => x[0])
      .value();
  }

  copy(): Party {
    const persons = this.persons.map((x) => x.copy());
    const x = new Party(this.thrift());
    x.persons = persons;
    if (this.shoppingList !== undefined) {
      x.setShoppingLists([this.shoppingList]);
    }
    return x;
  }

  thrift(): ThriftParty {
    return new ThriftParty({
      id: this.id,
      name: this.name,
      user: this.user,
      created: this.created,
      persons: this.persons.map((x) => x.thrift()),
      shoppingListId: this.shoppingListId,
    });
  }

  edit(field: string, value: string, i?: number) {
    if (
      field === 'name'
    ) {
      this[field] = value;
    }
  }

  remove(field: string, i: number) {
    if (
      field === 'persons'
    ) {
      this[field].splice(i, 1);
    }
  }

  addRecipeIngredient(ri: RecipeIngredient) {
    this.persons.forEach((p) => p.ingredients.push(ri.copy()) );
  }

  setRecipes(xs: Recipe[]) {
    this.persons.forEach((x) => x.setRecipes(xs));
  }

  setIngredients(xs: Ingredient[]) {
    this.persons.forEach((x) => x.setIngredients(xs));
  }

  static getUsedItems(xs: Party[], sli: string): Party[] {
    return _.chain(xs)
      .filter((x) => x.shoppingListId === sli)
      .sortBy((x) => x.name)
      .reverse()
      .value();
  }

  static getUnusedItems(xs: Party[]): Party[] {
    return _.chain(xs)
      .filter((x) => !x.used)
      .sortBy((x) => x.name)
      .value();
  }

  static empty(): Party {
    return new Party(new ThriftParty({
      id: "",
      name: "",
      user: "",
      created: "",
      persons: [PartyPerson.empty().thrift()],
    }));
  }

  static init(xs: ThriftParty[]): Party[] {
    return xs.map((x) => new Party(x));
  }
}
