import { Measure as MeasureEnum, Q } from 'thriftgen';

export default class RawQuantity {
  n: number;
  m: number;
  p: {
    medium?: number;
    slice?: number;
    sprig?: number;
    bunch?: number;
    g?: number;
    stalk?: number;
    ml?: number;
    handful?: number;
    count?: {n: number, m: number};
  };

  constructor(n: number, m: number, p: {
    medium?: number;
    slice?: number;
    sprig?: number;
    bunch?: number;
    g?: number;
    stalk?: number;
    ml?: number;
    handful?: number;
    count?: {n: number, m: number};
  }) {
    this.n = n;
    this.m = m;
    this.p = p;
  }

  multiply(x: number) {
    this.n = x * this.n;
  }

  normalize(): RawQuantity {
    return RawQuantity.normalize(this);
  }

  inG(): RawQuantity | undefined {
    const rules = RawQuantity.getRulesToG(this);
    const rule = rules[this.m];
    if (rule === undefined) {
      return undefined;
    }
    return new RawQuantity(this.n * rule, MeasureEnum.G, this.p);
  }

  in(m: MeasureEnum): RawQuantity | undefined {
    if (this.m === m) {
      return this;
    }

    const inG = this.inG();
    if (inG === undefined) {
      return undefined;
    }
    const rules = RawQuantity.getRulesFromG(this);
    const rule = rules[m];
    if (rule === undefined) {
      console.warn("Cannot translate", this);
      return undefined;
    }
    return new RawQuantity(inG.n * rule, m, this.p);
  }

  get q(): Q {
    return new Q({n: this.n, m: this.m});
  }

  static add(q1: RawQuantity, q2: RawQuantity): RawQuantity | undefined {
    if (q1.m === q2.m) {
      return new RawQuantity(q1.n + q2.n, q1.m, q1.p);
    }

    if (
      (q1.m === MeasureEnum.PORTION && q2.m === MeasureEnum.COUNT) ||
      (q2.m === MeasureEnum.PORTION && q1.m === MeasureEnum.COUNT)
    ) {
      return new RawQuantity(q1.n + q2.n, q1.m, q1.p);
    }

    let q1n = q1.inG();
    let q2n = q2.inG();

    if (q1n === undefined || q2n === undefined) {
      q1n = q1.normalize();
      q2n = q2.normalize();

      if (q1n === undefined || q2n === undefined) {
        console.warn("Cannot add two Quantities - no g", q1, q2);
        return undefined;
      }
    }

    if (q1n.m === q2n.m) {
      return new RawQuantity(q1n.n + q2n.n, q1n.m, q1n.p);
    }
    console.warn("Cannot add two Quantities", q1n, q2n);
    return undefined;
  }

  static subtract(q1: RawQuantity, q2: RawQuantity): RawQuantity | undefined {
    if (q1.m === q2.m) {
      return new RawQuantity(q1.n - q2.n, q1.m, q1.p);
    }

    let q1n = q1.inG();
    let q2n = q2.inG();

    if (q1n === undefined || q2n === undefined) {
      q1n = q1.normalize();
      q2n = q2.normalize();

      if (q1n === undefined || q2n === undefined) {
        console.warn("Cannot add two Quantities - no g", q1, q2);
        return undefined;
      }
    }

    if (q1n.m === q2n.m) {
      return new RawQuantity(q1n.n - q2n.n, q1n.m, q1n.p);
    }
    console.warn("Cannot subtract two Quantities", q1n, q2n);
    return undefined;
  }

  static getRulesFromG(q: RawQuantity): {[key: number]: number} {
    let rules: {[key: number]: number} = {}

    // g to X is value * rules[X]
    rules[MeasureEnum.G] = 1
    rules[MeasureEnum.KG] = 0.001

    if (q.p.ml !== undefined) {
      rules[MeasureEnum.PINCH] = 100 / q.p.ml * 2;
      rules[MeasureEnum.ML] = 100 / q.p.ml;
      rules[MeasureEnum.L] = 100 / q.p.ml / 1000;
      rules[MeasureEnum.TEASPOON] = 100 / q.p.ml / 5;
      rules[MeasureEnum.TABLESPOON ] = 100 / q.p.ml / 15;
      rules[MeasureEnum.CUP] = 100 / q.p.ml / 250;
    }

    if (q.p.slice !== undefined) {
      rules[MeasureEnum.SLICE] = 1 / q.p.slice;
    }
    if (q.p.sprig !== undefined) {
      rules[MeasureEnum.SPRIG] = 1 / q.p.sprig;
    }
    if (q.p.bunch !== undefined) {
      rules[MeasureEnum.BUNCH] = 1 / q.p.bunch;
    }
    if (q.p.medium !== undefined) {
      rules[MeasureEnum.COUNT] = 1 / q.p.medium;
    }
    if (q.p.stalk !== undefined) {
      rules[MeasureEnum.STALK] = 1 / q.p.stalk;
    }
    if (q.p.handful !== undefined) {
      rules[MeasureEnum.HANDFUL] = 1 / q.p.handful;
    }
    return rules;
  }

  static getRulesToG(q: RawQuantity): {[key: number]: number} {
    let rules: {[key: number]: number} = {}

    // X to g is value * rules[X]
    rules[MeasureEnum.G] = 1
    rules[MeasureEnum.KG] = 1000

    if (q.p.ml !== undefined) {
      rules[MeasureEnum.PINCH] = q.p.ml / 100 / 2;
      rules[MeasureEnum.ML] = q.p.ml / 100;
      rules[MeasureEnum.L] = q.p.ml / 100 * 1000;
      rules[MeasureEnum.TEASPOON] = q.p.ml / 100 * 5;
      rules[MeasureEnum.TABLESPOON ] = q.p.ml / 100 * 15;
      rules[MeasureEnum.CUP] = q.p.ml / 100 * 250;
    }

    if (q.p.slice !== undefined) {
      rules[MeasureEnum.SLICE] = q.p.slice;
    }
    if (q.p.sprig !== undefined) {
      rules[MeasureEnum.SPRIG] = q.p.sprig;
    }
    if (q.p.bunch !== undefined) {
      rules[MeasureEnum.BUNCH] = q.p.bunch;
    }
    if (q.p.stalk !== undefined) {
      rules[MeasureEnum.STALK] = q.p.stalk;
    }
    if (q.p.medium !== undefined) {
      rules[MeasureEnum.COUNT] = q.p.medium;
    }
    if (q.p.handful !== undefined) {
      rules[MeasureEnum.HANDFUL] = q.p.handful;
    }
    return rules;
  }

  static normalize(q: RawQuantity): RawQuantity {
    let normalizationRules: {[key: number]: [number, number]} = {}
    normalizationRules[MeasureEnum.TABLESPOON] = [15, MeasureEnum.ML];
    normalizationRules[MeasureEnum.CUP] = [250, MeasureEnum.ML];
    normalizationRules[MeasureEnum.L] = [1000, MeasureEnum.ML];
    normalizationRules[MeasureEnum.PINCH] = [0.5, MeasureEnum.ML];
    normalizationRules[MeasureEnum.TEASPOON] = [5, MeasureEnum.ML];
    normalizationRules[MeasureEnum.KG] = [1000, MeasureEnum.G];

    if (q.p.slice !== undefined) {
      normalizationRules[MeasureEnum.SLICE] = [q.p.slice, MeasureEnum.G];
    }
    if (q.p.sprig !== undefined) {
      normalizationRules[MeasureEnum.SPRIG] = [q.p.sprig, MeasureEnum.G];
    }
    if (q.p.bunch !== undefined) {
      normalizationRules[MeasureEnum.BUNCH] = [q.p.bunch, MeasureEnum.G];
    }
    if (q.p.stalk !== undefined) {
      normalizationRules[MeasureEnum.STALK] = [q.p.stalk, MeasureEnum.G];
    }
    if (q.p.medium !== undefined) {
      normalizationRules[MeasureEnum.G] = [1 / q.p.medium, MeasureEnum.COUNT];
    }
    if (q.p.ml !== undefined) {
      normalizationRules[MeasureEnum.ML] = [q.p.ml / 100, MeasureEnum.G];
    }
    if (q.p.handful !== undefined) {
      normalizationRules[MeasureEnum.HANDFUL] = [q.p.handful, MeasureEnum.G];
    }
    if (q.p.count !== undefined) {
      const qn = new RawQuantity(q.p.count.n, q.p.count.m, {}).normalize();
      normalizationRules[MeasureEnum.COUNT] = [qn.n, qn.m];
      normalizationRules[MeasureEnum.PORTION] = [qn.n, qn.m];
    }

    const rule = normalizationRules[q.m];
    if (rule === undefined) {
      return q;
    }
    return RawQuantity.normalize(
      new RawQuantity(q.n * rule[0], rule[1], q.p)
    );
  }
}
