import { Names, NamesStrings } from 'cls/NutritionNames';

export interface RecommendationAccount {
  age: number,
  sex: 'f' | 'm' | '',
  pregnant: boolean,
  lactation: boolean,
}

interface RecommendationItemApi {
  ageStart: number;
  ageEnd: number;
  sex?: 'f' | 'm';
  pregnant?: boolean;
  lactation?: boolean;
  nutrients: {[key in Names]?: string};
}

class RecommendationItem {
  ageStart: number;
  ageEnd: number;
  sex?: 'f' | 'm';
  pregnant?: boolean;
  lactation?: boolean;
  nutrients: {[key in NamesStrings]?: string};

  constructor(x: RecommendationItemApi) {
    this.ageStart = x.ageStart;
    this.ageEnd = x.ageEnd;
    this.sex = x.sex;
    this.pregnant = x.pregnant;
    this.lactation = x.lactation;
    this.nutrients = x.nutrients;
  }

  matches(user: RecommendationAccount): boolean {
    if (this.ageStart > user.age) { return false; }
    if (this.ageEnd < user.age) { return false; }
    if (this.pregnant && !user.pregnant) { return false; }
    if (this.lactation && !user.lactation) { return false; }
    if (this.sex !== undefined && this.sex !== user.sex) { return false; }
    return true;
  }
}

export interface RecommendationApi {
  name: string;
  items: RecommendationItemApi[];
}

export class Recommendation {
  name: string;
  items: RecommendationItem[];

  constructor(x: RecommendationApi) {
    this.name = x.name;
    this.items = x.items.map((x) => new RecommendationItem(x))
  }

  get(name: Names, user: RecommendationAccount): string {
    for (let item of this.items) {
      if (item.matches(user)) {
        return item.nutrients[name] || '';
      }
    }
    return '';
  }
}

export default class Recommendations {
  items: Recommendation[]

  constructor(xs: RecommendationApi[]) {
    this.items = xs.map((x) => new Recommendation(x))
  }

  get(name: Names, user: RecommendationAccount): string {
    for (let i=0; i<this.items.length; i++) {
      const r = this.items[i].get(name, user);
      if (r !== '') {
        return r;
      }
    }
    return '';
  }

  gets(names: Names[], user: RecommendationAccount): string[] {
    return names.map((x) => this.get(x, user));
  }
}
