/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
export interface ISeasonArgs {
    startDate?: number;
    endDate?: number;
    months?: Array<number>;
}
export class Season {
    public startDate?: number;
    public endDate?: number;
    public months?: Array<number>;
    constructor(args?: ISeasonArgs) {
        if (args != null && args.startDate != null) {
            this.startDate = args.startDate;
        }
        if (args != null && args.endDate != null) {
            this.endDate = args.endDate;
        }
        if (args != null && args.months != null) {
            this.months = args.months;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Season");
        if (this.startDate != null) {
            output.writeFieldBegin("startDate", thrift.Thrift.Type.I16, 1);
            output.writeI16(this.startDate);
            output.writeFieldEnd();
        }
        if (this.endDate != null) {
            output.writeFieldBegin("endDate", thrift.Thrift.Type.I16, 2);
            output.writeI16(this.endDate);
            output.writeFieldEnd();
        }
        if (this.months != null) {
            output.writeFieldBegin("months", thrift.Thrift.Type.LIST, 3);
            output.writeListBegin(thrift.Thrift.Type.I16, this.months.length);
            this.months.forEach((value_1: number): void => {
                output.writeI16(value_1);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Season {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I16) {
                        const value_2: number = input.readI16();
                        _args.startDate = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I16) {
                        const value_3: number = input.readI16();
                        _args.endDate = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_4: Array<number> = new Array<number>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_5: number = input.readI16();
                            value_4.push(value_5);
                        }
                        input.readListEnd();
                        _args.months = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new Season(_args);
    }
}
