/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
export interface IPortionArgs {
    medium?: number;
    slice?: number;
    breadSlice?: number;
    sprig?: number;
    bunch?: number;
    dash?: number;
    stalk?: number;
    ml?: number;
    handful?: number;
}
export class Portion {
    public medium?: number;
    public slice?: number;
    public breadSlice?: number;
    public sprig?: number;
    public bunch?: number;
    public dash?: number;
    public stalk?: number;
    public ml?: number;
    public handful?: number;
    constructor(args?: IPortionArgs) {
        if (args != null && args.medium != null) {
            this.medium = args.medium;
        }
        if (args != null && args.slice != null) {
            this.slice = args.slice;
        }
        if (args != null && args.breadSlice != null) {
            this.breadSlice = args.breadSlice;
        }
        if (args != null && args.sprig != null) {
            this.sprig = args.sprig;
        }
        if (args != null && args.bunch != null) {
            this.bunch = args.bunch;
        }
        if (args != null && args.dash != null) {
            this.dash = args.dash;
        }
        if (args != null && args.stalk != null) {
            this.stalk = args.stalk;
        }
        if (args != null && args.ml != null) {
            this.ml = args.ml;
        }
        if (args != null && args.handful != null) {
            this.handful = args.handful;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Portion");
        if (this.medium != null) {
            output.writeFieldBegin("medium", thrift.Thrift.Type.DOUBLE, 1);
            output.writeDouble(this.medium);
            output.writeFieldEnd();
        }
        if (this.slice != null) {
            output.writeFieldBegin("slice", thrift.Thrift.Type.DOUBLE, 2);
            output.writeDouble(this.slice);
            output.writeFieldEnd();
        }
        if (this.breadSlice != null) {
            output.writeFieldBegin("breadSlice", thrift.Thrift.Type.DOUBLE, 3);
            output.writeDouble(this.breadSlice);
            output.writeFieldEnd();
        }
        if (this.sprig != null) {
            output.writeFieldBegin("sprig", thrift.Thrift.Type.DOUBLE, 4);
            output.writeDouble(this.sprig);
            output.writeFieldEnd();
        }
        if (this.bunch != null) {
            output.writeFieldBegin("bunch", thrift.Thrift.Type.DOUBLE, 5);
            output.writeDouble(this.bunch);
            output.writeFieldEnd();
        }
        if (this.dash != null) {
            output.writeFieldBegin("dash", thrift.Thrift.Type.DOUBLE, 6);
            output.writeDouble(this.dash);
            output.writeFieldEnd();
        }
        if (this.stalk != null) {
            output.writeFieldBegin("stalk", thrift.Thrift.Type.DOUBLE, 7);
            output.writeDouble(this.stalk);
            output.writeFieldEnd();
        }
        if (this.ml != null) {
            output.writeFieldBegin("ml", thrift.Thrift.Type.DOUBLE, 8);
            output.writeDouble(this.ml);
            output.writeFieldEnd();
        }
        if (this.handful != null) {
            output.writeFieldBegin("handful", thrift.Thrift.Type.DOUBLE, 9);
            output.writeDouble(this.handful);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Portion {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_1: number = input.readDouble();
                        _args.medium = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_2: number = input.readDouble();
                        _args.slice = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_3: number = input.readDouble();
                        _args.breadSlice = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_4: number = input.readDouble();
                        _args.sprig = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_5: number = input.readDouble();
                        _args.bunch = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_6: number = input.readDouble();
                        _args.dash = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_7: number = input.readDouble();
                        _args.stalk = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_8: number = input.readDouble();
                        _args.ml = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_9: number = input.readDouble();
                        _args.handful = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new Portion(_args);
    }
}
