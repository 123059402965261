
export default class Constants {
  static DEFAULT_PRICE = 'kcal';

  static I_MENU = 'czi-rocket';

  static TAG_IN_SEASON_FRUIT = 'in_season_fruit';
  static TAG_IN_SEASON_VEGETABLE = 'in_season_veg';

  static TAG_FRUIT = '0b5e1495d9d689aaab9c5f8db5426144';
  static TAG_VEGETABLE = '0b5e1495d9d689aaab9c5f8db5427533';
  static TAG_MEAT = 'eb80d6a0b7866896ada92dccb40a1927';
  static TAG_FISH = 'acfe5e25e7d9ae8967b7f9b9378e2c11';
  static TAG_SEAFOOD = '6ae287f1d8da8d5c0ac77b3e3563c668';
  static TAG_EGG = 'e83c5453aed3c1becc26d6d8165dce60';
  static TAG_CHEESE = '771136fd6b0d281d2fd14288a79d8ba0';
  static TAG_DAIRY = '771136fd6b0d281d2fd14288a79d87d3';
  static TAG_SUGAR = '0b5e1495d9d689aaab9c5f8db5424579';
  static TAG_FLOUR = '07873b15102892ef2c0c1e446434159a';
  static TAG_SPICES_DRIED = 'eb80d6a0b7866896ada92dccb40a1d81';
  static TAG_SPICES_FRESH = 'eb80d6a0b7866896ada92dccb40a1fb0';
  static TAG_SPICES_MIXED = 'k7oYbFJmxpSGqaKHL539AhbP2CdemNei';
  static TAG_NUTS = 'e83c5453aed3c1becc26d6d8165dd43d';
  static TAG_SEEDS = 'c1dc824c1f987543b33fdd70b792885a';
  static TAG_SEEDS2 = 'eb429d13c01f8ed94f82b1ad7d91c74c';
  static TAG_DAIRY_MILK = '192283a4a91e2cdd8b4c8176fafcb9c4';
  static TAG_DAIRY_CREAM = 'ee77427233ca7a93f9d7057584b1f5b2';
  static TAG_DAIRY_YOGURT = 'a6ae51b22f6067272980b748e68e9044';
  static TAG_DAIRY_BUTTER = 'e83c5453aed3c1becc26d6d8165dd3f9';
  static TAG_DAIRY_COTTAGE_CHEESE = 'bbc6cb3615efeeb3d9c3a56d63d08285';
  static TAG_MEAT_GOOSE = 'c2a9c71b96df125725d12edf017581d3';
  static TAG_MEAT_TURKEY = '80328d18c22783aca2a8d2deaebfc776';
  static TAG_MEAT_DUCK = '82c54c4d22d800d615be23e284c572e3';
  static TAG_MEAT_CHICKEN = '55ea723bb45046654366401bd1e9cbee';
  static TAG_MEAT_RABBIT = 'c742b7e171a21e24c7eeb55f6f529917';
  static TAG_MEAT_PORK = 'c742b7e171a21e24c7eeb55f6f637089';
  static TAG_MEAT_BEEF = 'c742b7e171a21e24c7eeb55f6f6394eb';
  static TAG_FRUIT_CITRUS = '7c10d3fb00f5980dbb500b464e4e1cc3';
  static TAG_FRUIT_TREE = 'acfe5e25e7d9ae8967b7f9b9378e19af';
  static TAG_FRUIT_BUSH = 'a6ae51b22f6067272980b748e68e8e40';
  static TAG_FRUIT_BERRY = 'wrdapcdpnpq3v8ozyh7zrn6762so9101';
  static TAG_VEGETABLE_ONION = 'eb80d6a0b7866896ada92dccb40a2cea';
  static TAG_VEGETABLE_PUMPKIN = '771136fd6b0d281d2fd14288a79d97d1';
  static TAG_VEGETABLE_CABBAGE = '07873b15102892ef2c0c1e4464343959';
  static TAG_VEGETABLE_ROOT = 'f06811382117d130498dd73d790791f5';
  static TAG_VEGETABLE_LEAF = 'eb80d6a0b7866896ada92dccb40a31a5';
  static TAG_VEGETABLE_NIGHTSHADE = '9cbfe4e42bb0aa7e71bf069fd91e7f21';
  static TAG_VEGETABLE_LEGUMES = 'c67jEpYrJYi3mIHqMMWc4WedvRAgf7dL';
  static TAG_VEGETABLE_PICKLED = 'a6ae51b22f6067272980b748e68e7f02';
  static TAG_FUNGUS = 'acfe5e25e7d9ae8967b7f9b9378e1f66';

  static TAG_RECIPE_BASE = '5084db7339d0eb35164c5550d9ca8ec6';
  static TAG_RECIPE_SOUP = 'f06811382117d130498dd73d7907937a';
  static TAG_RECIPE_SOUP_WATER = 'f06811382117d130498dd73d79079779';
  static TAG_RECIPE_SOUP_CREAM = 'a6ae51b22f6067272980b748e68ea865';
  static TAG_RECIPE_SOUP_SUMMER = 'e83c5453aed3c1becc26d6d8165dead2';
  static TAG_RECIPE_BREAKFAST = '07873b15102892ef2c0c1e4464344808';
  static TAG_RECIPE_LUNCH = 'a6ae51b22f6067272980b748e68e9dd6';
  static TAG_RECIPE_BENTO = 'ef30020dbf2e67cc3b19c59c897b4463';
  static TAG_RECIPE_BENTO_500 = 'eb80d6a0b7866896ada92dccb40a0bd9';
  static TAG_RECIPE_SALAD = '0b5e1495d9d689aaab9c5f8db54267a5';
  static TAG_RECIPE_DESSERT = '0b5e1495d9d689aaab9c5f8db54252a4';
  static TAG_RECIPE_SAUCE = 'eb80d6a0b7866896ada92dccb40a2804';
  static TAG_SAUCE = 'eb80d6a0b7866896ada92dccb40a2804';
  static TAG_FAT = 'z7R0jneNGhpYB1mNWz4hosNuMg7AWHfk';
  static TAG_COCONUT = 'WAULWYnOnehU7g2UlG1E9GXsdAaVmrvp';
  static TAG_COFFEE = 'whdQ4htYH4UBX6QsXMRRgB366WjY0oDI';
  static TAG_CHOCOLATE = 'kk5OGplg39LRUdCKzW9cwlwGKcN563XX';
  static TAG_TEA = 'R4Ttt3GNdpRyh1rqdywDAGKyoNYOKoKq';

  static TAG_DRIED = '7c10d3fb00f5980dbb500b464e4e2b55';

  static TAG_GROUP_SHOPPING_LIST = [
    [Constants.TAG_FRUIT],
    [Constants.TAG_VEGETABLE],
    [Constants.TAG_MEAT],
    [Constants.TAG_FISH, Constants.TAG_SEAFOOD],
    [Constants.TAG_DAIRY],
    [Constants.TAG_SPICES_FRESH],
    ["inne"],
    [Constants.TAG_SPICES_DRIED],
  ];
  static TAG_GROUP_SHOPPING_LIST_FILTER = [
    'all',
    Constants.TAG_RECIPE_BASE,
    Constants.TAG_RECIPE_SOUP,
    Constants.TAG_RECIPE_BREAKFAST,
    Constants.TAG_RECIPE_LUNCH,
    Constants.TAG_FRUIT,
  ];
  static TAG_GROUP_PROTEIN_SUMMARY = [
    Constants.TAG_MEAT,
    Constants.TAG_DAIRY,
    Constants.TAG_FISH,
    Constants.TAG_VEGETABLE,
    Constants.TAG_FRUIT
  ];
  static TAG_GROUP_SUMMARY_INGREDIENTS = [
    'all',
    Constants.TAG_FRUIT,
    Constants.TAG_VEGETABLE,
    Constants.TAG_MEAT,
    Constants.TAG_FISH,
    Constants.TAG_SEAFOOD,
    Constants.TAG_DAIRY,
    Constants.TAG_SPICES_DRIED,
    Constants.TAG_SPICES_FRESH,
  ];
  static TAG_GROUP_SUMMARY_RECIPES = [
    'all',
    Constants.TAG_FRUIT,
    Constants.TAG_VEGETABLE,
    Constants.TAG_RECIPE_BASE,
    Constants.TAG_RECIPE_SOUP,
    Constants.TAG_RECIPE_BREAKFAST,
    Constants.TAG_RECIPE_LUNCH,
  ];

  static TAGS_CATEGORY = [
    "5084db7339d0eb35164c5550d9ca8ec6",
    "ef30020dbf2e67cc3b19c59c897b4463",
    "0b5e1495d9d689aaab9c5f8db54252a4",
    "a6ae51b22f6067272980b748e68e9dd6",
    "07873b15102892ef2c0c1e4464344808",
    "0b5e1495d9d689aaab9c5f8db54267a5",
    "7c10d3fb00f5980dbb500b464e4e2b55",
    "f06811382117d130498dd73d7907937a",
    "eb80d6a0b7866896ada92dccb40a2804",
  ]

  static PREMENU_CATEGORY = [
    Constants.TAG_RECIPE_BENTO,
    Constants.TAG_RECIPE_DESSERT,
    Constants.TAG_RECIPE_LUNCH,
    Constants.TAG_RECIPE_SALAD,
    Constants.TAG_VEGETABLE,
    Constants.TAG_RECIPE_SOUP,
    Constants.TAG_RECIPE_BREAKFAST,
    Constants.TAG_FRUIT,
  ];

  static MONTH_NAMES_3 = [
    process.env.REACT_APP_MONTH_3_1,
    process.env.REACT_APP_MONTH_3_2,
    process.env.REACT_APP_MONTH_3_3,
    process.env.REACT_APP_MONTH_3_4,
    process.env.REACT_APP_MONTH_3_5,
    process.env.REACT_APP_MONTH_3_6,
    process.env.REACT_APP_MONTH_3_7,
    process.env.REACT_APP_MONTH_3_8,
    process.env.REACT_APP_MONTH_3_9,
    process.env.REACT_APP_MONTH_3_10,
    process.env.REACT_APP_MONTH_3_11,
    process.env.REACT_APP_MONTH_3_12,
  ];
}
