import * as _ from 'underscore';

import CellarItem from 'cls/CellarItem';
import Ingredient from 'cls/Ingredient';
import Recipe from 'cls/Recipe';
import ShoppingList from 'cls/ShoppingList';
import { Cellar as ThriftCellar } from 'thriftgen/Cellar';

export default class Cellar {
  items: CellarItem[];

  constructor(x: ThriftCellar) {
    this.items = CellarItem.init(x.items);
  }

  static fromItems(items: CellarItem[]): Cellar {
    const c = new Cellar(new ThriftCellar({items: []}));
    c.items = items;
    return c
  }

  copy(): Cellar {
    const c = new Cellar(new ThriftCellar({items: []}));
    c.items = [...this.items];
    return c;
  }
  addItem(cellarItem: CellarItem) {
    this.items.push(cellarItem);
  }
  removeItem(itemId: string) {
    this.items = this.items.filter((x) => x.id !== itemId);
  }
  setRecipes(xs: Recipe[]) {
    this.items.map((x) => x.setRecipes(xs));
  }
  setIngredients(xs: Ingredient[]) {
    this.items.map((x) => x.setIngredients(xs));
  }
  setShoppingLists(xs: ShoppingList[]) {
    this.items.map((x) => x.setShoppingLists(xs));
  }

  getUsedItems(): CellarItem[] {
    return _.chain(this.items)
      .filter((x) => x.used)
      .sortBy((x) => x.shoppingList?.start)
      .reverse()
      .value();
  }

  getUnusedItems(): CellarItem[] {
    return _.chain(this.items)
      .filter((x) => !x.used)
      .sortBy((x) => x.date)
      .value();
  }

  getUnusedItemsGrouped(): CellarItem[][] {
    return _.chain(this.items)
      .filter((x) => !x.used)
      .sortBy((x) => x.date)
      .groupBy((x) => `${x.name}:${x.date}:${x.quantity.text}`)
      .values()
      .value();
  }

  getByIdsItems(ids: string[]): CellarItem[] {
    return _.chain(this.items)
      .filter((x) => ids.indexOf(x.id) !== -1)
      .sortBy((x) => x.date)
      .value();
  }
}
