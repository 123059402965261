/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as Measure from "./Measure";
export interface IQArgs {
    n: number;
    m: Measure.Measure;
}
export class Q {
    public n: number;
    public m: Measure.Measure;
    constructor(args: IQArgs) {
        if (args != null && args.n != null) {
            this.n = args.n;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[n] is unset!");
        }
        if (args != null && args.m != null) {
            this.m = args.m;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[m] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("Q");
        if (this.n != null) {
            output.writeFieldBegin("n", thrift.Thrift.Type.DOUBLE, 1);
            output.writeDouble(this.n);
            output.writeFieldEnd();
        }
        if (this.m != null) {
            output.writeFieldBegin("m", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.m);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): Q {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_1: number = input.readDouble();
                        _args.n = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_2: Measure.Measure = input.readI32();
                        _args.m = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.n !== undefined && _args.m !== undefined) {
            return new Q(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read Q from input");
        }
    }
}
