import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { Notification } from '@stack/frontend-core/cls';
import { ActionTypes as CoreActionTypes } from '@stack/frontend-core/cls/redux/actions';
import { NotificationStatus } from '@stack/frontend-core/thriftgen';
import { LoadingButton } from '@stack/frontend-core/ui';

import { StoreDispatch, StoreState } from 'cls/store';
import Utils from 'cls/Utils';

import 'page/main/style.css';

interface Props {
}
interface ReduxStateProps {
  notifications: Notification[];
}
interface ReduxDispatchProps {
  listNotifications: () => void;
  readNotification: (item: Notification) => void;
  deleteNotification: (item: Notification) => void;
}
interface State {}

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {
  componentDidMount() {
    const { listNotifications } = this.props;
    listNotifications();
  }

  onNotificationRead(item: Notification) {
    const { readNotification } = this.props;
    readNotification(item);
  }

  onReadAllNotifications() {
    const { readNotification, notifications } = this.props;
    notifications
      .filter((x) => x.status === NotificationStatus.UNREAD)
      .forEach((x) => readNotification(x));
  }

  onNotificationDelete(item: Notification) {
    const { deleteNotification } = this.props;
    deleteNotification(item);
  }

  render() {
    const { notifications } = this.props;

    const notificationsNum = notifications.filter((x) => !x.isRead).length;

    const empty = (
      <div className="d-flex flex-column align-items-center">
        <h1 className="h3 mt-3">{process.env.REACT_APP_EMPTY_NOTIFICATIONS_TITLE}</h1>
        <h3 className="h5 font-weight-normal mb-4 text-center">
          {process.env.REACT_APP_EMPTY_NOTIFICATIONS_TEXT}
        </h3>
      </div>
    );

    return (
      <div className="navbar-tool dropdown ml-3">
        <a className="navbar-tool-icon-box bg-secondary dropdown-toggle"
           href="#"
           aria-label={process.env.REACT_APP_NOTIFICATIONS}
        >
          <span className={`navbar-tool-label ${notificationsNum === 0 ? 'invisible' : ''}`}>
            { notificationsNum }
          </span>
          <i className="navbar-tool-icon czi-bell" />
        </a>
        <div className="dropdown-menu dropdown-menu-right" style={{"width": "40rem"}}>
          <div className="px-3 border-bottom d-flex justify-content-between">
            <h6 className="text-muted text-uppercase">{process.env.REACT_APP_NOTIFICATIONS}</h6>
            <div>
              <Button
                variant="link"
                className="text-sm pt-0 pb-0"
                onClick={() => this.onReadAllNotifications()}
                disabled={notifications.length === 0}
                role="button"
              >
                {process.env.REACT_APP_NOTIFICATIONS_MARK_AS_READ}
              </Button>
            </div>
          </div>
          <div className="px-2">
            <div>
              { notifications.length === 0 && empty }
              { notifications.map((notification) => (
                <div className="pt-2 pb-2 border-bottom" key={`notification-${notification.id}`}>
                  <div className={`media align-items-center pt-1 pb-1 pl-3 pr-3 ${notification.isRead ? '' : 'bg-secondary'}`}>
                    <div className="media-body d-flex justify-content-between">
                      <div>
                        <h6 className="widget-product-title mt-2">
                          { notification.title }
                        </h6>
                        <div className="widget-product-meta">
                          <span className="text-muted">
                            { Utils.prettyPrintDateTime(notification.datetime) }
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <LoadingButton
                          variant="link"
                          action="read"
                          item={notification}
                          className={`text-success p-2 ${notification.isRead ? 'invisible' : ''}`}
                          onClick={() => this.onNotificationRead(notification)}
                        >
                          <i className="czi-check" />
                        </LoadingButton>
                        <LoadingButton
                          variant="link"
                          action="delete"
                          className="text-danger p-2"
                          item={notification}
                          onClick={() => this.onNotificationDelete(notification)}
                        >
                          <i className="czi-close" />
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <a className="btn btn-secondary btn-sm btn-block" href="#">
              {process.env.REACT_APP_NOTIFICATIONS_SHOW_ALL}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): ReduxStateProps => {
  return ({
    notifications: state.notifications === null ? [] : state.notifications,
  });
};

const mapDispatchToProps = (dispatch: StoreDispatch) => {
  return {
    listNotifications: () => dispatch({ type: CoreActionTypes.ListNotifications }),
    readNotification: (item: Notification) => dispatch({ type: CoreActionTypes.ReadNotification, item }),
    deleteNotification: (item: Notification) => dispatch({ type: CoreActionTypes.DeleteNotification, item }),
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const HeaderNotifications = () => (
  <ConnectedScene  />
);

export default HeaderNotifications;
