// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';


const ContentPageHeader = (props: {
  breadcrumbs: {url?: string | undefined, name: string | undefined}[],
  title: string | undefined,
  className?: string | undefined,
}) => {

  return (
    <div className={`page-title-overlap-lg bg-dark pt-4 ${props.className || ""}`}>
      <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2 d-none d-lg-block">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li className="breadcrumb-item">
                <LinkContainer to="/">
                  <a href="/" className="text-nowrap">
                    <i className="czi-home" />
                    {process.env.REACT_APP_PAGE}
                  </a>
                </LinkContainer>
              </li>
              { props.breadcrumbs.map((x) => (
                <li
                  className={`breadcrumb-item text-nowrap ${x.url === undefined && 'active'}`}
                  key={`${x.name}:${x.url}`}
                  aria-current={x.url === undefined && "page"}
                >
                  { x.url !== undefined ?
                    <LinkContainer to={x.url}><a href={x.url}>{x.name}</a></LinkContainer> :
                    x.name
                  }
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div className="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 className="h3 text-light mb-0">{props.title}</h1>
        </div>
      </div>
    </div>
  );
}
export default ContentPageHeader;
