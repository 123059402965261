import { Col, Container, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Urls from 'cls/Urls';
import { HowItWorks } from 'content/empty/HowToStart';
import { Banner } from 'content/landing/Banner';
import { Features } from 'content/landing/Features';
import { Header } from 'content/landing/Header';
import { Reviews } from 'content/landing/Reviews';
import FooterScene from 'page/footer';


export const LandingHomepageScene = () => {
  return (
    <>
      <Header />
      <Banner />
      <Container fluid={true}>
        <Features />
      </Container>
      <Reviews />
      <Container fluid={true} className="my-5">
        <Row>
          <Col xs={12} md={{offset: 2, span: 8}} className="d-flex flex-column align-items-center">
            <HowItWorks />
            <div>
              <LinkContainer to={Urls.REGISTER}>
                <a href={Urls.REGISTER} className="btn btn-primary btn-lg my-4" type="button">
                  {process.env.REACT_APP_LANDING_BUTTON}
                </a>
              </LinkContainer>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterScene />
    </>
  )
};