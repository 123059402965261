/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as ShoppingListStatus from "./ShoppingListStatus";
import * as ManualItem from "./ManualItem";
import * as Q from "./Q";
export interface IShoppingListArgs {
    id: string;
    startDate: string;
    endDate: string;
    cellar: Array<string>;
    menu: Array<string>;
    status: ShoppingListStatus.ShoppingListStatus;
    purchased: Map<string, boolean>;
    manual: Array<ManualItem.ManualItem>;
    party: Array<string>;
    ingredients: Map<string, Q.Q>;
    updated: string;
}
export class ShoppingList {
    public id: string;
    public startDate: string;
    public endDate: string;
    public cellar: Array<string>;
    public menu: Array<string>;
    public status: ShoppingListStatus.ShoppingListStatus;
    public purchased: Map<string, boolean>;
    public manual: Array<ManualItem.ManualItem>;
    public party: Array<string>;
    public ingredients: Map<string, Q.Q>;
    public updated: string;
    constructor(args: IShoppingListArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.startDate != null) {
            this.startDate = args.startDate;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[startDate] is unset!");
        }
        if (args != null && args.endDate != null) {
            this.endDate = args.endDate;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[endDate] is unset!");
        }
        if (args != null && args.cellar != null) {
            this.cellar = args.cellar;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[cellar] is unset!");
        }
        if (args != null && args.menu != null) {
            this.menu = args.menu;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[menu] is unset!");
        }
        if (args != null && args.status != null) {
            this.status = args.status;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[status] is unset!");
        }
        if (args != null && args.purchased != null) {
            this.purchased = args.purchased;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[purchased] is unset!");
        }
        if (args != null && args.manual != null) {
            this.manual = args.manual;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[manual] is unset!");
        }
        if (args != null && args.party != null) {
            this.party = args.party;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[party] is unset!");
        }
        if (args != null && args.ingredients != null) {
            this.ingredients = args.ingredients;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[ingredients] is unset!");
        }
        if (args != null && args.updated != null) {
            this.updated = args.updated;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[updated] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ShoppingList");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.startDate != null) {
            output.writeFieldBegin("startDate", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.startDate);
            output.writeFieldEnd();
        }
        if (this.endDate != null) {
            output.writeFieldBegin("endDate", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.endDate);
            output.writeFieldEnd();
        }
        if (this.cellar != null) {
            output.writeFieldBegin("cellar", thrift.Thrift.Type.LIST, 4);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.cellar.length);
            this.cellar.forEach((value_1: string): void => {
                output.writeString(value_1);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.menu != null) {
            output.writeFieldBegin("menu", thrift.Thrift.Type.LIST, 5);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.menu.length);
            this.menu.forEach((value_2: string): void => {
                output.writeString(value_2);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.status != null) {
            output.writeFieldBegin("status", thrift.Thrift.Type.I32, 6);
            output.writeI32(this.status);
            output.writeFieldEnd();
        }
        if (this.purchased != null) {
            output.writeFieldBegin("purchased", thrift.Thrift.Type.MAP, 7);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.BOOL, this.purchased.size);
            this.purchased.forEach((value_3: boolean, key_1: string): void => {
                output.writeString(key_1);
                output.writeBool(value_3);
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        if (this.manual != null) {
            output.writeFieldBegin("manual", thrift.Thrift.Type.LIST, 8);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.manual.length);
            this.manual.forEach((value_4: ManualItem.ManualItem): void => {
                value_4.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.party != null) {
            output.writeFieldBegin("party", thrift.Thrift.Type.LIST, 9);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.party.length);
            this.party.forEach((value_5: string): void => {
                output.writeString(value_5);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ingredients != null) {
            output.writeFieldBegin("ingredients", thrift.Thrift.Type.MAP, 10);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.STRUCT, this.ingredients.size);
            this.ingredients.forEach((value_6: Q.Q, key_2: string): void => {
                output.writeString(key_2);
                value_6.write(output);
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        if (this.updated != null) {
            output.writeFieldBegin("updated", thrift.Thrift.Type.STRING, 11);
            output.writeString(this.updated);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ShoppingList {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.id = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_8: string = input.readString();
                        _args.startDate = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_9: string = input.readString();
                        _args.endDate = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_10: Array<string> = new Array<string>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_11: string = input.readString();
                            value_10.push(value_11);
                        }
                        input.readListEnd();
                        _args.cellar = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_12: Array<string> = new Array<string>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_13: string = input.readString();
                            value_12.push(value_13);
                        }
                        input.readListEnd();
                        _args.menu = value_12;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_14: ShoppingListStatus.ShoppingListStatus = input.readI32();
                        _args.status = value_14;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_15: Map<string, boolean> = new Map<string, boolean>();
                        const metadata_3: thrift.TMap = input.readMapBegin();
                        const size_3: number = metadata_3.size;
                        for (let i_3: number = 0; i_3 < size_3; i_3++) {
                            const key_3: string = input.readString();
                            const value_16: boolean = input.readBool();
                            value_15.set(key_3, value_16);
                        }
                        input.readMapEnd();
                        _args.purchased = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_17: Array<ManualItem.ManualItem> = new Array<ManualItem.ManualItem>();
                        const metadata_4: thrift.TList = input.readListBegin();
                        const size_4: number = metadata_4.size;
                        for (let i_4: number = 0; i_4 < size_4; i_4++) {
                            const value_18: ManualItem.ManualItem = ManualItem.ManualItem.read(input);
                            value_17.push(value_18);
                        }
                        input.readListEnd();
                        _args.manual = value_17;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_19: Array<string> = new Array<string>();
                        const metadata_5: thrift.TList = input.readListBegin();
                        const size_5: number = metadata_5.size;
                        for (let i_5: number = 0; i_5 < size_5; i_5++) {
                            const value_20: string = input.readString();
                            value_19.push(value_20);
                        }
                        input.readListEnd();
                        _args.party = value_19;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 10:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_21: Map<string, Q.Q> = new Map<string, Q.Q>();
                        const metadata_6: thrift.TMap = input.readMapBegin();
                        const size_6: number = metadata_6.size;
                        for (let i_6: number = 0; i_6 < size_6; i_6++) {
                            const key_4: string = input.readString();
                            const value_22: Q.Q = Q.Q.read(input);
                            value_21.set(key_4, value_22);
                        }
                        input.readMapEnd();
                        _args.ingredients = value_21;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 11:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_23: string = input.readString();
                        _args.updated = value_23;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.startDate !== undefined && _args.endDate !== undefined && _args.cellar !== undefined && _args.menu !== undefined && _args.status !== undefined && _args.purchased !== undefined && _args.manual !== undefined && _args.party !== undefined && _args.ingredients !== undefined && _args.updated !== undefined) {
            return new ShoppingList(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read ShoppingList from input");
        }
    }
}
