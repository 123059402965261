import * as _ from 'underscore';

import Ingredient from 'cls/Ingredient';
import MenuItem from 'cls/MenuItem';
import MenuItemsBase from 'cls/MenuItemsBase';
import Recipe from 'cls/Recipe';
import Utils from 'cls/Utils';
import { MenuItem as ThriftMenuItem } from 'thriftgen/MenuItem'

export default class Menu extends MenuItemsBase {
  constructor(xs: ThriftMenuItem[]) {
    const items = MenuItem.init(xs).sort(
      (a, b) => {
        if (a.prepDay === undefined || b.prepDay === undefined) {
          if (a.prepDay === undefined && b.prepDay === undefined) { return 0; }
          if (a.prepDay === undefined) { return 1; }
          return -1;
        }
        return a.prepDay.localeCompare(b.prepDay);
      }
    );
    super(items);
  }

  static allTags(xs: Menu[]): string[] {
    return _.chain(xs)
      .map((x) => MenuItem.allTags(x.items))
      .flatten()
      .uniq()
      .value();
  }

  static empty(): Menu {
    return new Menu([]);
  }

  setRecipes(xs: Recipe[]) {
    this.items.map((x) => x.setRecipes(xs));
  }
  setIngredients(xs: Ingredient[]) {
    this.items.map((x) => x.setIngredients(xs));
  }
  copy(): Menu {
    const np = new Menu([]);
    np.items = this.items.map((x) => x.copy());
    return np;
  }

  updateMenuItem(menuItem: MenuItem) {
    const oldMenuItem = this.items.find((x) => x.id === menuItem.id);
    if (oldMenuItem === undefined) {
      return;
    }
    const i = this.items.findIndex((x) => x.id === menuItem.id);
    this.items[i] = menuItem;
  }

  todayItems(): {[key: string]: MenuItem[]} {
    const groups: {[key: string]: MenuItem[]} = _.groupBy(this.items, 'prepDay');
    const items = groups[Utils.todayDate()] || [];
    return _.groupBy(items, (x) => x.meal || "");
  }

  tomorrowItems(): {[key: string]: MenuItem[]} {
    const groups: {[key: string]: MenuItem[]} = _.groupBy(this.items, 'prepDay');
    const items = groups[Utils.tomorrowDate()] || [];
    return _.groupBy(items, (x) => x.meal || "");
  }
}
