import * as React from 'react';
import * as _ from 'underscore';

import Ingredient from 'cls/Ingredient';
import { PartyPerson } from 'cls/Party';
import Portion from 'cls/Portion';
import Price from 'cls/Price';
import Quantity from 'cls/Quantity';
import Recipe from 'cls/Recipe';
import RecipeIngredient from 'cls/RecipeIngredient';
import Utils from 'cls/Utils';
import X from 'cls/X';
import { Owner as ThriftOwner, OwnerType as ThriftOwnerType } from 'thriftgen';
import { MenuItem as ThriftMenuItem } from 'thriftgen/MenuItem';
import { RecipeIngredient as ThriftRecipeIngredient } from 'thriftgen/RecipeIngredient';
import { PickQuantityItemType } from 'ui/pick-quantity';

export default class MenuItem extends X {
  type: string = "menu-item";

  id: string;
  ingredientId?: string;
  recipeId?: string;
  ownerId: string;
  ownerType: ThriftOwnerType;
  meal?: string;
  prepDay?: string;
  recipe?: Recipe;
  ingredient?: Ingredient;
  stock: boolean;
  dateUpdated?: string;
  nutrition: number = 1;

  constructor(x: ThriftMenuItem) {
    super(x.q);
    this.id = x.id;
    this.ingredientId = x.ingredientId;
    this.recipeId = x.recipeId;
    this.ownerId = x.owner.id;
    this.ownerType = x.owner.ownerType;
    this.prepDay = x.date || "";
    this.meal = x.meal;
    this.stock = x.stock === undefined ? false : x.stock;
    this.dateUpdated = x.dateUpdated;
  }

  get item(): PickQuantityItemType | undefined {
    if (this.ingredient !== undefined) {
      return this.ingredient;
    }
    if (this.recipe !== undefined) {
      return this.recipe;
    }
    return undefined;
  }

  get itemObj(): Ingredient | Recipe | undefined {
    if (this.ingredient !== undefined) {
      return this.ingredient;
    }
    if (this.recipe !== undefined) {
      return this.recipe;
    }
    return undefined;
  }

  get itemId(): string {
    if (this.ingredientId !== undefined && this.ingredientId !== "") {
      return this.ingredientId;
    }
    if (this.recipeId !== undefined && this.recipeId !== "") {
      return this.recipeId;
    }
    return "";
  }

  get datePrepDay(): string | undefined {
    if (this.prepDay?.length === 5) {
      const y = new Date().getFullYear();
      return `${y}-${this.prepDay}`
    }
    return this.prepDay;
  }

  static init = (xs: ThriftMenuItem[]) => xs.map((x) => new MenuItem(x));

  static allTags(xs: MenuItem[]): string[] {
    return _.chain(xs)
      .map((x) => {
        if (x.recipe !== undefined) {
          return Recipe.allTags([x.recipe]);
        }
        if (x.ingredient !== undefined) {
          return Ingredient.allTags([x.ingredient]);
        }
        return [];
      })
      .flatten()
      .uniq()
      .value();
  }

  static simpleText(xs: MenuItem[]): [string, string][] {
    const ys: [string, Quantity, Portion | undefined][] = xs.map((x) =>
      [(x.recipe || x.ingredient || {name: ''}).name, x.quantity.copy() || "", x.portions]
    );
    const groupsByName: {[key: string]: [string, Quantity, Portion | undefined][]} = _.groupBy(
      ys, (x) => x[0]
    );
    const result: [string, string][] = [];
    _.keys(groupsByName).forEach((name) => {
      const v: Quantity[] = groupsByName[name].map((x) => x[1]);
      const s = _.reduce(v, (x: Quantity, s: Quantity) => {
        return Quantity.add(x, s) || Quantity.defaultOne();
      });
      result.push([name, s?.text || ""]);
    });
    return result;
  }

  static simpleTextRender(xs: MenuItem[]) {
    return (
      <>
        {MenuItem.simpleText(xs).map(([name, num], ri) => (
          <span key={`plan-${ri}`}>
                      { (ri ? ', ': '') }
            { name } ({ num })
          </span>
        ))}
      </>
    );
  }

  thrift(): ThriftMenuItem {
    return new ThriftMenuItem({
      id: this.id,
      ingredientId: this.ingredientId,
      recipeId: this.recipeId,
      owner: new ThriftOwner({id: this.ownerId, ownerType: this.ownerType}),
      q: this.q,
      meal: this.meal,
      date: this.prepDay,
      stock: this.stock,
      dateUpdated: this.dateUpdated,
    });
  }

  setRecipes(xs: Recipe[]) {
    this.recipe = xs.find((x) => x.id === this.recipeId);
    if (this.recipe !== undefined) {
      this.setPortion(this.recipe.portions);
    }
  }
  setIngredients(xs: Ingredient[]) {
    this.ingredient = xs.find((x) => x.id === this.ingredientId);
    if (this.ingredient !== undefined) {
      this.setPortion(this.ingredient.portions);
    }
  }

  edit(field: string, value: string | Quantity) {
    if (typeof value === "string") {
      if (field === "ownerId" && value.endsWith(":stock")) {
        this.stock = true;
        value = value.replace(":stock", '');
      } else if (field === "ownerId") {
        this.stock = false;
      }
      if (field === "ownerId" && this.ownerId !== value) {
        this.meal = "";
      }
      if (field === 'prepDay') {
        if (this.ownerType !== ThriftOwnerType.PREMENU) {
          this[field] = value;
        } else {
          this[field] = value.substr(5);
        }
      }
      if (
        field === 'meal' ||
        field === 'ownerId'
      ) {
        this[field] = value;
      }
    } else {
      this.setQuantity(value);
    }
    this.dateUpdated = Utils.nowUTC();
  }

  get name(): string {
    if (this.ingredient !== undefined) {
      return this.ingredient.name;
    }
    if (this.recipe !== undefined) {
      return this.recipe.name;
    }
    return '?';
  }

  get portions(): Portion | undefined {
    if (this.ingredient !== undefined) {
      return this.ingredient.portions;
    }
    if (this.recipe !== undefined) {
      return this.recipe.portions;
    }
    return undefined;
  }

  get url(): string {
    if (this.ingredient !== undefined) {
      return this.ingredient.url;
    }
    if (this.recipe !== undefined) {
      return this.recipe.url;
    }
    return '';
  }

  get tags(): string[] {
    if (this.ingredient !== undefined) {
      return this.ingredient.tags;
    }
    if (this.recipe !== undefined) {
      return this.recipe.tags;
    }
    return [];
  }

  get measureOptions(): string[] {
    if (this.ingredient !== undefined) {
      return this.ingredient.measureOptions;
    }
    if (this.recipe !== undefined) {
      return this.recipe.measureOptions;
    }
    return [];
  }

  get price(): Price {
    if (this.ingredient !== undefined) {
      return this.ingredient.price;
    }
    if (this.recipe !== undefined) {
      return this.recipe.price;
    }
    return new Price();
  }

  get category(): string | undefined {
    if (this.ingredient !== undefined) {
      return this.ingredient.category;
    }
    if (this.recipe !== undefined) {
      return this.recipe.category;
    }
    return '-';
  }

  get categoryName(): string {
    const c = this.category;
    if (c === undefined) {
      return '-';
    }
    return Utils.tagName(c) || '-';
  }

  get imageUrl(): string | undefined {
    if (this.ingredient !== undefined) {
      return this.ingredient.img?.src;
    }
    return undefined;
  }

  isAfter(date: string): boolean {
    if (this.prepDay === undefined || this.prepDay === "") { return true; }
    if (this.prepDay.length === 5) {
      // no year, MM-DD
      return `${date.substr(0, 5)}${this.prepDay}` >= date;
    }
    return this.prepDay >= date;
  }

  isBefore(date: string): boolean {
    if (this.prepDay === undefined || this.prepDay === "") { return true; }
    if (this.prepDay.length === 5) {
      // no year, MM-DD
      return `${date.substr(0, 5)}${this.prepDay}` <= date;
    }
    return this.prepDay <= date;
  }

  updateAfter(date: string): boolean {
    if (this.dateUpdated === undefined || this.dateUpdated === "") { return false; }
    return date <= this.dateUpdated;
  }

  copy(): MenuItem {
    const ri = new MenuItem(this.thrift());
    ri.ingredient = this.ingredient;
    ri.recipe = this.recipe;
    return ri;
  }

  get ingredients(): RecipeIngredient[] {
    if (this.ingredient !== undefined) {
      return [
        new RecipeIngredient(
          new ThriftRecipeIngredient({
            ingredientId: this.ingredientId,
            nutrition: 1,
            q: this.q,
          }),
          [this.ingredient]
        )
      ];
    }
    if (this.recipe === undefined) {
      return [];
    }

    const r = new RecipeIngredient(
      new ThriftRecipeIngredient({
        recipeId: this.recipeId,
        nutrition: 1,
        q: this.q,
      }),
      []
    );
    r.setRecipes([this.recipe]);
    return [r];
  }

  newMenuItem(owner: ThriftOwner, date: string | undefined, quantity: Quantity | undefined): ThriftMenuItem {
    if (this.ingredientId !== undefined) {
      return new ThriftMenuItem({
        id: '',
        ingredientId: this.ingredientId,
        owner: owner,
        date: date || this.datePrepDay,
        dateUpdated: Utils.nowUTC(),
        q: quantity?.q || this.q,
      });
    }
    if (this.recipe !== undefined) {
      return new ThriftMenuItem({
        id: '',
        recipeId: this.recipeId,
        owner: owner,
        date: date || this.datePrepDay,
        dateUpdated: Utils.nowUTC(),
        q: quantity?.q || this.q,
      });
    }
    return new ThriftMenuItem({
      id: '',
      owner: owner,
      date: date || this.datePrepDay,
      dateUpdated: Utils.nowUTC(),
      q: quantity?.q || this.q,
    });
  }

  static sum(xs: MenuItem[]): MenuItem[] {
    const i: {[key: string]: MenuItem} = {};
    const r: {[key: string]: MenuItem} = {};

    xs.forEach((x) => {
      if (x.ingredientId !== undefined) {
        if (i[x.ingredientId] === undefined) {
          i[x.ingredientId] = x.copy();
        } else {
          const q1 = Quantity.init4(x.q, x.ingredient?.portions);
          const q2 = Quantity.init4(i[x.ingredientId].q, x.ingredient?.portions);
          const q12 = Quantity.add(q1, q2);
          if (q12 !== undefined) {
            i[x.ingredientId].setQuantity(q12);
          } else {
            console.warn('Cannot add', x.ingredientId);
          }
        }
      }
      if (x.recipeId !== undefined) {
        if (r[x.recipeId] === undefined) {
          r[x.recipeId] = x.copy();
        } else {
          const q1 = Quantity.init4(x.q, x.recipe?.portions);
          const q2 = Quantity.init4(r[x.recipeId].q, x.recipe?.portions);
          const q12 = Quantity.add(q1, q2);
          if (q12 !== undefined) {
            r[x.recipeId].setQuantity(q12);
          } else {
            console.warn('Cannot add', x.recipeId, x.recipe);
          }
        }
      }
    });

    return _.values(i).concat(_.values(r));
  }

  static sort(sortFieldArg: string | undefined, sortOrderAsc: boolean) {
    return (a: MenuItem, b: MenuItem) => {
      const m = sortOrderAsc ? 1 : -1;
      const sortField = sortFieldArg === undefined ? 'name' : sortFieldArg;
      if (
        sortField === 'name' ||
        sortField === 'prepDay'
      ) {
        if (a[sortField] === undefined || a[sortField] === "") { return -1; }
        if (b[sortField] === undefined || b[sortField] === "") { return 1; }
        return (a[sortField] || "").localeCompare((b[sortField] || "")) * m;
      }
      return 0;
    }
  }

  static fromRecipeIngredient(x: RecipeIngredient): MenuItem {
    const result = new MenuItem(new ThriftMenuItem({
      date: '',
      id: x.id,
      ingredientId: x.ingredientId,
      meal: '',
      owner: new ThriftOwner({id: '', ownerType: ThriftOwnerType.PARTY}),
      recipeId: x.recipeId,
      stock: false,
      q: x.q,
    }));

    if (x.ingredient !== undefined) {
      result.setIngredients([x.ingredient]);
    }
    if (x.recipe !== undefined) {
      x.setRecipes([x.recipe]);
    }
    return result;
  }

  static fromPartyPerson(x: PartyPerson): MenuItem[] {
    return x.ingredients.map((x) => MenuItem.fromRecipeIngredient(x));
  }

  static fromPartyPersons(xs: PartyPerson[]): MenuItem[] {
    return xs.flatMap((x) => MenuItem.fromPartyPerson(x));
  }
}
