import { from, Observable, of, throwError, zip} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, map, mergeMap, retry, shareReplay, switchMap } from 'rxjs/operators';
import * as thrift from  'thrift';

import { GetCoreApiWithCache } from '@stack/frontend-core/cls';

import Account, { Profile, TagToMeal } from 'cls/Account';
import Cellar from 'cls/Cellar';
import CellarItem from 'cls/CellarItem';
import IndexedDB from 'cls/IndexedDB';
import Ingredient from 'cls/Ingredient';
import { ToMenuItem } from 'cls/Interfaces';
import Menu from 'cls/Menu';
import MenuItem from 'cls/MenuItem';
import { N } from 'cls/N';
import Party from 'cls/Party';
import Premenu from 'cls/Premenu';
import Quantity from 'cls/Quantity';
import Recipe from 'cls/Recipe';
import Recommendations, { RecommendationApi } from 'cls/Recommandation';
import ShoppingList from 'cls/ShoppingList';
import Urls from 'cls/Urls';
import {
  IPortionArgs,
  ManualItem,
  Owner as ThriftOwner,
  OwnerType as ThriftOwnerType,
  Service, SR,
} from 'thriftgen';
import { Ingredient as ThriftIngredient } from 'thriftgen/Ingredient';
import { MenuItem as ThriftMenuItem } from 'thriftgen/MenuItem';
import { Party as ThriftParty } from 'thriftgen/Party';
import { ProfilePageInfo } from 'thriftgen/ProfilePageInfo';
import { Recipe as ThriftRecipe } from 'thriftgen/Recipe';
import { ShoppingListStatus } from 'thriftgen/ShoppingListStatus';

function addNewCacheItem(cacheName: string) {
  return mergeMap<string, Observable<string>>((id ) => {
    const requests = {
      [IndexedDB.OBJECT_STORE_RECIPES]: () => Api.getLocalThriftConn().getRecipe(id),
      [IndexedDB.OBJECT_STORE_PREMENUS]: () => Api.getLocalThriftConn().getPremenu(id),
      [IndexedDB.OBJECT_STORE_SHOPPING_LISTS]: () => Api.getLocalThriftConn().getShoppingList(id),
    };
    const f = requests[cacheName];

    if (f !== undefined) {
      return from(f()).pipe(
        map((y) => {
          IndexedDB.store(cacheName, [y]);
          return id;
        }),
      );
    }
    return of(id);
  });
}

function deleteCache<A>(cacheName: string, id: string) {
  return map<A, A>((x) => {
    IndexedDB.clearCachedData(cacheName, id);
    return x;
  });
}

function recacheCache<A>(cacheName: string, id: string) {
  return mergeMap<A, Observable<A>>((x: A) => {
    const requests = {
      [IndexedDB.OBJECT_STORE_RECIPES]: () => Api.getLocalThriftConn().getRecipe(id),
      [IndexedDB.OBJECT_STORE_PREMENUS]: () => Api.getLocalThriftConn().getPremenu(id),
      [IndexedDB.OBJECT_STORE_SHOPPING_LISTS]: () => Api.getLocalThriftConn().getShoppingList(id),
    };
    const f = requests[cacheName];
    if (f !== undefined) {
      return from(f()).pipe(
        map((y) => {
          IndexedDB.store(cacheName, [y]);
          return x;
        }),
      );
    }

    return of(x);
  });
}

const CoreApi = GetCoreApiWithCache(IndexedDB);

export default class Api extends CoreApi {
  static BASE = process.env.REACT_APP_API_URL;


  private static URLS = {
    NUTRITION: `${Api.BASE}/nutrition.json`,
    NUTRITION2: `${Api.BASE}/nutrition-arr.json`,
    RECOMMENDATIONS: `${Api.BASE}/recommendations.json`,
  };

  /**
   * Do http request and return Observable.
   *
   * To do http request we use fetch API.
   * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
   *
   * Then result is changed into RxJS Observables which we use to handle async.
   *
   * @param url - full request url
   * @param method - GET/POST/...
   * @param data - data to be sent to backend in request body
   */
  private static doRequest<T>(
    url: string, method: string, data: any = undefined,
  ): Observable<T> {
    const body = data !== undefined ? JSON.stringify(data) : undefined;
    return fromFetch(url, { method, body }).pipe(
      switchMap((x) => x.json()),
      catchError((err) => {
        console.error(`Http error on ${method} ${url}`, err); // eslint-disable-line no-console
        return throwError(err);
      }),
    );
  }

  static getLocalThriftConn(): Service.Client {
    const connection: thrift.XHRConnection = thrift.createXHRConnection(
      Api.API_HOST, Api.API_PORT, Api.thriftOpts,
    );
    connection.getXmlHttpRequestObject =  function() {
      try {
        const r = new XMLHttpRequest();
        r.withCredentials = true;
        return r;
      } catch (e1) { }
      throw Error("Your browser doesn't support XHR.");
    };

    return thrift.createXHRClient(Service.Client, connection);
  }

  static handleErrors(err: Error) {
    if (Api.isAuthenticationFailed(err) && window.location.pathname !== Urls.LOGIN) {
        Api.eraseCookie('sid');
        Api.eraseCookie('uid');
        window.location.replace(Urls.LOGIN);
    }
    console.error(err);
    return throwError(err);
  }

  static listAllBasicData(): Observable<[Ingredient[], Recipe[], N, Premenu | undefined]> {
    const r1: Observable<ThriftIngredient[]> = IndexedDB.getCachedData(
      () => Api.getLocalThriftConn().listIngredients(),
      IndexedDB.OBJECT_STORE_INGREDIENTS,
    );
    const r2: Observable<ThriftRecipe[]> = IndexedDB.getCachedData(
      () => Api.getLocalThriftConn().listRecipes(),
      IndexedDB.OBJECT_STORE_RECIPES,
    );
    const r3: Observable<{[key: string]: number[]}> =
      Api.doRequest<{[key: string]: number[]}>(
        Api.URLS.NUTRITION2, 'GET'
      );
    const editedPremenu = localStorage.getItem('edited-premenu');
    const r4: Observable<Premenu | undefined> = editedPremenu === null || editedPremenu === "" ?
      of(undefined) :
      from(Api.getPremenu(editedPremenu));
    return zip(r1, r2, r3, r4).pipe(
      catchError(Api.handleErrors),
      map(
        results => {
          const ingredients = Ingredient.init(results[0]);
          const portions: {[key: string]: IPortionArgs} = {};
          ingredients.forEach((x) => portions[x.id] = x.portions)

          const recipes = Recipe.init(results[1], ingredients);
          recipes.map((x) => x.setRecipes(recipes));

          const n = new N(results[2], portions);

          const editedPremenu = results[3];
          if (editedPremenu !== undefined) {
            editedPremenu.menu.setIngredients(ingredients);
            editedPremenu.menu.setRecipes(recipes);
          }

          return [ingredients, recipes, n, editedPremenu];
        }
      ));
  }

  static listEditable(type: string): Observable<SR[]> {
    return from(Api.getLocalThriftConn().listEditable(type)).pipe(
      catchError(Api.handleErrors),
    );
  }

  static getAccount(): Observable<Account> {
    const connHttp = Api.getLocalThriftConn();
    const promise = connHttp.getAccount().then((account) => {
      return new Account(account);
    });
    return from(promise).pipe(
      retry(3), shareReplay(), catchError(Api.handleErrors),
    );
  }

  static saveProfile(profile: Profile): Observable<string> {
    return from(Api.getLocalThriftConn().upsertProfile(profile.thrift())).pipe(
      catchError(Api.handleErrors),
    );
  }
  static deleteProfile(profileId: string): Observable<void> {
    return from(Api.getLocalThriftConn().removeProfileFromGroup(profileId)).pipe(
      catchError(Api.handleErrors),
    );
  }
  static getProfilePageInfo(userId: string): Observable<ProfilePageInfo> {
    return from(Api.getLocalThriftConn().getProfilePageInfo(userId)).pipe(
      retry(3),
      shareReplay(),
      catchError(Api.handleErrors),
    );
  }

  static getCellar(): Observable<Cellar> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.getCellar()).pipe(
      catchError(Api.handleErrors),
      map((x) => new Cellar(x)),
    );
  }

  static upsertRecipe(recipe: Recipe): Observable<string> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.upsertRecipe(recipe.thrift())).pipe(
      addNewCacheItem(IndexedDB.OBJECT_STORE_RECIPES),
      catchError(Api.handleErrors),
    );
  }

  static deleteRecipe(itemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.deleteRecipe(itemId)).pipe(
      deleteCache(IndexedDB.OBJECT_STORE_RECIPES, itemId),
      catchError(Api.handleErrors),
    );
  }

  static createMenuItem(
    item: ToMenuItem, tagsToMeal: TagToMeal[], editedPremenu?: Premenu,
    date?: string, q?: Quantity,
  ): Observable<ThriftMenuItem> {
    const profile = localStorage.getItem("profile") || "";
    const owner = new ThriftOwner({id: profile, ownerType: ThriftOwnerType.USER});

    if (editedPremenu !== undefined) {
      owner.id = editedPremenu.id;
      owner.ownerType = ThriftOwnerType.PREMENU;
    }

    const connHttp = Api.getLocalThriftConn();
    const menuItem = item.newMenuItem(owner, date, q);
    for (let i=0; i<tagsToMeal.length; i++) {
      if (item.tags.some((x) => tagsToMeal[i].tag === "" || x === tagsToMeal[i].tag)) {
        menuItem.meal = tagsToMeal[i].meal;
        break;
      }
    }
    return from(connHttp.upsertMenuItem(menuItem)).pipe(
      catchError(Api.handleErrors),
      map((x) => {
        menuItem.id = x;
        return menuItem;
      }),
    );
  }

  static updateMenuItem(item: MenuItem): Observable<MenuItem> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.upsertMenuItem(item.thrift())).pipe(
      catchError(Api.handleErrors),
      map(() => item),
    );
  }

  static deleteMenuItem(itemId: string): Observable<string> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.deleteMenuItem(itemId)).pipe(
      catchError(Api.handleErrors),
      map(() => itemId),
    );
  }

  static listMenuItems(itemIds: string[]): Observable<MenuItem[]> {
    return from(Api.getLocalThriftConn().listMenuItemsByIds(itemIds)).pipe(
      catchError(Api.handleErrors),
      map((xs) => xs.map((x) => new MenuItem(x))),
    );
  }

  static createShoppingList(): Observable<string> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.createShoppingList()).pipe(
      addNewCacheItem(IndexedDB.OBJECT_STORE_SHOPPING_LISTS),
      catchError(Api.handleErrors),
    );
  }
  static deleteShoppingList(itemId: string): Observable<string> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.deleteShoppingList(itemId)).pipe(
      deleteCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, itemId),
      catchError(Api.handleErrors),
      map(() => itemId),
    );
  }

  static setShoppingListStatus(itemId: string, status: ShoppingListStatus): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.setShoppingListStatus(itemId, status)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, itemId),
      catchError(Api.handleErrors),
    );
  }

  static addMenuItemToShoppingList(shoppingListId: string, menuItemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.addMenuItemToShoppingList(shoppingListId, menuItemId)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static addMenuItemsToShoppingList(shoppingListId: string, menuItemIds: string[]): Observable<void> {
    if (menuItemIds.length === 0) { return from(new Promise<void>(resolve => resolve())); }
    const first = menuItemIds[0];
    menuItemIds.shift();
    return from(Api.addMenuItemToShoppingList(shoppingListId, first)).pipe(
      mergeMap(() => Api.addMenuItemsToShoppingList(shoppingListId, menuItemIds)),
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static deleteMenuItemFromShoppingList(shoppingListId: string, menuItemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.deleteMenuItemFromShoppingList(shoppingListId, menuItemId)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static setPurchasedStatusOnShoppingList(
    shoppingListId: string, recipeIngredientId: string, status: boolean,
  ): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.setPurchasedStatusOnShoppingList(shoppingListId, recipeIngredientId, status)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static addCellarItems(cellarItem: CellarItem, howMany: number): Observable<string[]> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.addCellarItems(cellarItem.thrift(), howMany)).pipe(
      catchError(Api.handleErrors),
    );
  }

  static deleteCellarItem(cellarItemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.deleteCellarItem(cellarItemId)).pipe(
      catchError(Api.handleErrors),
    );
  }

  static setCellarItemBroken(cellarItemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.setCellarItemBroken(cellarItemId)).pipe(
      catchError(Api.handleErrors),
    );
  }

  static unsetCellarItemBroken(cellarItemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.unsetCellarItemBroken(cellarItemId)).pipe(
      catchError(Api.handleErrors),
    );
  }

  static addCellarItemToShoppingList(shoppingListId: string, cellarItemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.addCellarItemToShoppingList(shoppingListId, cellarItemId)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static deleteCellarItemFromShoppingList(shoppingListId: string, cellarItemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.deleteCellarItemFromShoppingList(shoppingListId, cellarItemId)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static addPartyToShoppingList(shoppingListId: string, partyId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.addPartyToShoppingList(shoppingListId, partyId)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static deletePartyFromShoppingList(shoppingListId: string, partyId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.deletePartyFromShoppingList(shoppingListId, partyId)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static upsertManualItemToShoppingList(shoppingListId: string, item: {id: string, text: string}): Observable<string> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.upsertManualItemOnShoppingList(shoppingListId, new ManualItem(item))).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static deleteManualItemFromShoppingList(shoppingListId: string, itemId: string): Observable<void> {
    const connHttp = Api.getLocalThriftConn();
    return from(connHttp.deleteManualItemFromShoppingList(shoppingListId, itemId)).pipe(
      recacheCache(IndexedDB.OBJECT_STORE_SHOPPING_LISTS, shoppingListId),
      catchError(Api.handleErrors),
    );
  }

  static listParties(): Observable<ThriftParty[]> {
    return from(Api.getLocalThriftConn().listParties()).pipe(
      catchError(Api.handleErrors),
    );
  }

  static upsertParty(item: Party): Observable<string> {
    return from(Api.getLocalThriftConn().upsertParty(item.thrift())).pipe(
      catchError(Api.handleErrors),
    );
  }

  static deleteParty(itemId: string): Observable<void> {
    return from(Api.getLocalThriftConn().deleteParty(itemId)).pipe(
      catchError(Api.handleErrors),
    );
  }

  static getParty(itemId: string): Observable<Party> {
    return from(Api.getLocalThriftConn().getParty(itemId)).pipe(
      catchError(Api.handleErrors),
      map(x => new Party(x)),
    );
  }

  static getRecommendations(): Observable<Recommendations> {
    return Api.doRequest<RecommendationApi[]>(
      Api.URLS.RECOMMENDATIONS, 'GET'
    ).pipe(
      catchError(Api.handleErrors),
      map((xs) => {
        return new Recommendations(xs);
      }),
    );
  }

  static listPremenus(): Observable<Premenu[]> {
    return from(
      IndexedDB.getCachedData(
        () => Api.getLocalThriftConn().listPremenus(),
        IndexedDB.OBJECT_STORE_PREMENUS,
      )
    ).pipe(
      catchError(Api.handleErrors),
      map((x) => Premenu.init(x, new Menu([]))),
    );
  }

  static deletePremenu(itemId: string): Observable<void> {
    return from(Api.getLocalThriftConn().deletePremenu(itemId)).pipe(
      deleteCache(IndexedDB.OBJECT_STORE_PREMENUS, itemId),
      catchError(Api.handleErrors),
    );
  }

  static upsertPremenu(item: Premenu): Observable<string> {
    return from(Api.getLocalThriftConn().upsertPremenu(item.thrift())).pipe(
      addNewCacheItem(IndexedDB.OBJECT_STORE_PREMENUS),
      catchError(Api.handleErrors),
    );
  }

  static getPremenu(itemId: string): Observable<Premenu> {
    const owner = new ThriftOwner({id: itemId, ownerType: ThriftOwnerType.PREMENU});

    const r1 = Api.getLocalThriftConn().getPremenu(itemId);
    const r2 = Api.getLocalThriftConn().listMenuItems(owner);

    return zip(r1, r2).pipe(
      catchError(Api.handleErrors),
      map(
        results => {
          return new Premenu(results[0], new Menu(results[1]));
        }
      ),
    );
  }

  static listMenuPremenu(itemId: string): Observable<Menu> {
    const owner = new ThriftOwner({id: itemId, ownerType: ThriftOwnerType.PREMENU});

    return from(Api.getLocalThriftConn().listMenuItems(owner)).pipe(
      catchError(Api.handleErrors),
      map((xs) => new Menu(xs)),
    );
  }

  static listMenuUser(userId: string): Observable<Menu> {
    const owner = new ThriftOwner({id: userId, ownerType: ThriftOwnerType.USER});

    return from(Api.getLocalThriftConn().listMenuItems(owner)).pipe(
      catchError(Api.handleErrors),
      map((xs) => new Menu(xs)),
    );
  }

  static addToPremenu(itemId: string, startDate: string, premenuStartDate: string, daysNum: number, factor: number): Observable<string[]> {
    return from(Api.getLocalThriftConn().usePremenu(itemId, startDate, premenuStartDate, daysNum, factor)).pipe(
      catchError(Api.handleErrors),
    );
  }

  static listShoppingLists(userId: string): Observable<ShoppingList[]> {
    const owner = new ThriftOwner({id: userId, ownerType: ThriftOwnerType.USER});

    return from(
      IndexedDB.getCachedData(
        () => Api.getLocalThriftConn().listShoppingLists(owner),
        IndexedDB.OBJECT_STORE_SHOPPING_LISTS,
      )
    ).pipe(
      catchError(Api.handleErrors),
      map((xs) => xs.map((x) => new ShoppingList(x))),
    );
  }
}
