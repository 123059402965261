export default class Portion {
  medium?: number;
  slice?: number;
  sprig?: number;
  bunch?: number;
  g?: number;
  stalk?: number;
  ml?: number;
  handful?: number;
  count?: { n: number, m: number };

  constructor(x: {
    medium?: number;
    slice?: number;
    sprig?: number;
    bunch?: number;
    g?: number;
    stalk?: number;
    ml?: number;
    handful?: number;
    count?: { n: number, m: number };
  } | undefined = undefined) {
    this.medium = x?.medium;
    this.slice = x?.slice;
    this.sprig = x?.sprig;
    this.bunch = x?.bunch;
    this.g = x?.g;
    this.stalk = x?.stalk;
    this.ml = x?.ml;
    this.handful = x?.handful;
    this.count = x?.count;
  }
}