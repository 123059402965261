/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as Q from "./Q";
export interface IRecipeIngredientArgs {
    recipeId?: string;
    ingredientId?: string;
    comment?: string;
    nutrition?: number;
    q: Q.Q;
}
export class RecipeIngredient {
    public recipeId?: string;
    public ingredientId?: string;
    public comment?: string;
    public nutrition?: number;
    public q: Q.Q;
    constructor(args: IRecipeIngredientArgs) {
        if (args != null && args.recipeId != null) {
            this.recipeId = args.recipeId;
        }
        if (args != null && args.ingredientId != null) {
            this.ingredientId = args.ingredientId;
        }
        if (args != null && args.comment != null) {
            this.comment = args.comment;
        }
        if (args != null && args.nutrition != null) {
            this.nutrition = args.nutrition;
        }
        if (args != null && args.q != null) {
            this.q = args.q;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[q] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("RecipeIngredient");
        if (this.recipeId != null) {
            output.writeFieldBegin("recipeId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.recipeId);
            output.writeFieldEnd();
        }
        if (this.ingredientId != null) {
            output.writeFieldBegin("ingredientId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.ingredientId);
            output.writeFieldEnd();
        }
        if (this.comment != null) {
            output.writeFieldBegin("comment", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.comment);
            output.writeFieldEnd();
        }
        if (this.nutrition != null) {
            output.writeFieldBegin("nutrition", thrift.Thrift.Type.DOUBLE, 5);
            output.writeDouble(this.nutrition);
            output.writeFieldEnd();
        }
        if (this.q != null) {
            output.writeFieldBegin("q", thrift.Thrift.Type.STRUCT, 6);
            this.q.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): RecipeIngredient {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.recipeId = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.ingredientId = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.comment = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_4: number = input.readDouble();
                        _args.nutrition = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_5: Q.Q = Q.Q.read(input);
                        _args.q = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.q !== undefined) {
            return new RecipeIngredient(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read RecipeIngredient from input");
        }
    }
}
