import * as _ from 'underscore';


const m: {[key: string]: number | number[]} = {
  'portion': 0,
  'moisture': 1,
  'ash': 2,
  'protein': 3,
  'fat': 4,
  'carbohydrate': 5,
  'energyKcal': 7,

  'fibre': 9,
  'starch': 10,
  'glucose': 11,
  'fructose': 12,
  'maltose': 13,
  'galactose': 14,
  'lactose': 15,
  'sucrose': 16,
  'sorbitol': 17,
  'sugars': 21,

  'minCa': 22,
  'minFe': 23,
  'minMg': 24,
  'minP': 25,
  'minK': 26,
  'minNa': 27,
  'minCu': 29,
  'minMn': 30,
  'minSe': 31,

  'vitB1': 44,
  'vitB2': 43,
  'vitB3': 40,
  'vitB4': 49,
  'vitB5': 42,
  'vitB6': 45,
  'vitB7': 48,
  'vitB9': 36,
  'vitB12': 46,
  'vitA': [33, 32, 34, 35],
  'vitD': 52,
  'vitE': [57, 58, 59, 60],
  'vitK': 55,
  'vitC': 51,

  'fattyAcidsSaturatedTotal': 61,
  'fattyAcidsMonounsaturatedTotal': 76,
  'fattyAcidsPolyunsaturatedTotal': 95,
  'fattyAcidsTrans': 125,
  'cholesterol': 126,

  'carbohydrateNoFibre': 0,
}

const units: {[key: string]: string} = {
   'moisture': 'g',
   'ash': 'g',
   'portion': 'g',
   'energyKcal': 'kCal',
   'carbohydrate': 'g',
   'carbohydrateNoFibre': 'g',
   'fat': 'g',
   'protein': 'g',
   'fibre': 'g',
   'fattyAcidsSaturatedTotal': 'g',
   'fattyAcidsMonounsaturatedTotal': 'g',
   'fattyAcidsPolyunsaturatedTotal': 'g',
   'fattyAcidsTrans': 'g',
   'cholesterol': 'mg',
   'sugars': 'g',
   'glucose': 'g',
   'fructose': 'g',
   'maltose': 'g',
   'galactose': 'g',
   'lactose': 'g',
   'starch': 'g',
   'sucrose': 'g',
   'sorbitol': 'g',
   'minCa': 'mg',
   'minFe': 'mg',
   'minMg': 'mg',
   'minP': 'mg',
   'minK': 'mg',
   'minNa': 'mg',
   'minSe': 'µg',
   'minCu': 'mg',
   'minMn': 'mg',
   'vitB1': 'mg',
   'vitB2': 'mg',
   'vitB3': 'mg',
   'vitB4': 'mg',
   'vitB5': 'mg',
   'vitB6': 'mg',
   'vitB7': 'mg',
   'vitB9': 'µg',
   'vitB12': 'mg',
   'vitA': 'µg',
   'vitD': 'mg',
   'vitE': 'mg',
   'vitK': 'µg',
   'vitC': 'mg',
}

const digits: {[key: string]: number} = {
  'moisture': 3,
  'ash': 3,
  'portion': 1,
  'energyKcal': 3,
  'carbohydrate': 3,
  'carbohydrateNoFibre': 3,
  'fat': 3,
  'protein': 3,
  'fibre': 3,
  'fattyAcidsSaturatedTotal': 3,
  'fattyAcidsMonounsaturatedTotal': 3,
  'fattyAcidsPolyunsaturatedTotal': 3,
  'fattyAcidsTrans': 3,
  'cholesterol': 3,
  'sugars': 3,
  'glucose': 3,
  'fructose': 3,
  'maltose': 3,
  'galactose': 3,
  'lactose': 3,
  'starch': 3,
  'sucrose': 2,
  'sorbitol': 2,
  'minCa': 3,
  'minFe': 3,
  'minMg': 3,
  'minP': 3,
  'minK': 3,
  'minNa': 3,
  'minSe': 3,
  'minCu': 3,
  'minMn': 3,
  'vitB1': 3,
  'vitB2': 3,
  'vitB3': 3,
  'vitB4': 3,
  'vitB5': 3,
  'vitB6': 3,
  'vitB7': 3,
  'vitB9': 3,
  'vitB12': 3,
  'vitA': 3,
  'vitD': 3,
  'vitE': 3,
  'vitK': 3,
  'vitC': 3,
}

const dividers: {[key: string]: number} = {
  'portion': 1
}

function pv(n: number | undefined, divider: number, unit: string, fractionDigits: number): string {
  if (n === undefined) {
    return '- ' + unit;
  }
  let sn = (n / divider).toFixed(fractionDigits);
  sn = sn.replace(/\.0+$/, "");
  return sn + ' ' + unit;
}


export class NEPrint {
  ne?: number[];

  constructor(ne: number[] | undefined) {
    this.ne = ne;
  }

  val(name: string): number {
    if (this.ne === undefined) { return 0; }
    const i = m[name];
    if (typeof i === 'number') {
      return this.ne[i];
    }
    const ne = this.ne;
    return _.reduce(
      i.map((x: number) => ne[x] || 0),
      (x, y) => x + y,
      0,
    );
  }

  pv(name: string, days: number = 1): {num: number | undefined, print: string} {
    const value = this.val(name);
    const unit = units[name];
    const fractionDigits = digits[name];
    const divider = dividers[name] || 1000;

    const num = value / days;

    return {num: num, print: pv(num, divider, unit, fractionDigits)}
  }
}