// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';

import { Logo } from 'ui/logo';

interface Props {
  title: string;
  text: string;
}

class InfoPageScene extends React.Component<Props> {

  render() {
    const { title, text } = this.props;
    return (
      <div className="flex-column align-items-center">
        <div className="d-flex flex-column p-4">
          <Logo />
          <h1 className="display-3 mb-4 mt-4">{ title }</h1>
          <p>{ text }</p>
        </div>
      </div>
    );
  }
}


export const CheckEmailPage = () => <InfoPageScene
  title={process.env.REACT_APP_INFO_CHECK_EMAIL_TITLE || ''}
  text={process.env.REACT_APP_INFO_CHECK_EMAIL_TEXT || ''}
/>

export const ErrorPage = () => <InfoPageScene
  title={process.env.REACT_APP_INFO_ERROR_TITLE || ''}
  text={process.env.REACT_APP_INFO_ERROR_TEXT || ''}
/>
