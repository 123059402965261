/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as Owner from "./Owner";
import * as Q from "./Q";
export interface IMenuItemArgs {
    id: string;
    owner: Owner.Owner;
    recipeId?: string;
    ingredientId?: string;
    date?: string;
    meal?: string;
    stock?: boolean;
    dateUpdated?: string;
    q: Q.Q;
}
export class MenuItem {
    public id: string;
    public owner: Owner.Owner;
    public recipeId?: string;
    public ingredientId?: string;
    public date?: string;
    public meal?: string;
    public stock?: boolean;
    public dateUpdated?: string;
    public q: Q.Q;
    constructor(args: IMenuItemArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.owner != null) {
            this.owner = args.owner;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[owner] is unset!");
        }
        if (args != null && args.recipeId != null) {
            this.recipeId = args.recipeId;
        }
        if (args != null && args.ingredientId != null) {
            this.ingredientId = args.ingredientId;
        }
        if (args != null && args.date != null) {
            this.date = args.date;
        }
        if (args != null && args.meal != null) {
            this.meal = args.meal;
        }
        if (args != null && args.stock != null) {
            this.stock = args.stock;
        }
        if (args != null && args.dateUpdated != null) {
            this.dateUpdated = args.dateUpdated;
        }
        if (args != null && args.q != null) {
            this.q = args.q;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[q] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("MenuItem");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.owner != null) {
            output.writeFieldBegin("owner", thrift.Thrift.Type.STRUCT, 2);
            this.owner.write(output);
            output.writeFieldEnd();
        }
        if (this.recipeId != null) {
            output.writeFieldBegin("recipeId", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.recipeId);
            output.writeFieldEnd();
        }
        if (this.ingredientId != null) {
            output.writeFieldBegin("ingredientId", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.ingredientId);
            output.writeFieldEnd();
        }
        if (this.date != null) {
            output.writeFieldBegin("date", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.date);
            output.writeFieldEnd();
        }
        if (this.meal != null) {
            output.writeFieldBegin("meal", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.meal);
            output.writeFieldEnd();
        }
        if (this.stock != null) {
            output.writeFieldBegin("stock", thrift.Thrift.Type.BOOL, 8);
            output.writeBool(this.stock);
            output.writeFieldEnd();
        }
        if (this.dateUpdated != null) {
            output.writeFieldBegin("dateUpdated", thrift.Thrift.Type.STRING, 9);
            output.writeString(this.dateUpdated);
            output.writeFieldEnd();
        }
        if (this.q != null) {
            output.writeFieldBegin("q", thrift.Thrift.Type.STRUCT, 10);
            this.q.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): MenuItem {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.id = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_2: Owner.Owner = Owner.Owner.read(input);
                        _args.owner = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.recipeId = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.ingredientId = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_5: string = input.readString();
                        _args.date = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_6: string = input.readString();
                        _args.meal = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_7: boolean = input.readBool();
                        _args.stock = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_8: string = input.readString();
                        _args.dateUpdated = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 10:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_9: Q.Q = Q.Q.read(input);
                        _args.q = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.owner !== undefined && _args.q !== undefined) {
            return new MenuItem(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read MenuItem from input");
        }
    }
}
