import * as _ from 'underscore';

import Constants from 'cls/Constants';
import { RecommendationAccount } from 'cls/Recommandation';
import Utils from 'cls/Utils';
import { Account as ThriftAccount } from 'thriftgen/Account'
import { Profile as ThriftProfile } from 'thriftgen/Profile'
import { TagToMeal as ThriftTagToMeal } from 'thriftgen/TagToMeal'

export class Meal {
  id: string;
  name: string;

  constructor(id: string) {
    this.id = id;
    this.name = Utils.mealNames[id] || "";
  }

  thrift(): string {
    return this.id;
  }

  static empty(): Meal {
    return new Meal("1");
  }
}

export class TagToMeal {
  tag: string;
  tagName: string;
  meal: string;
  mealName: string;

  constructor(x: ThriftTagToMeal) {
    this.tag = x.tag || "";
    this.meal = x.meal || "";
    this.mealName = Utils.mealNames[x.meal || ""] || "";
    this.tagName = Utils.tagName(x.tag);
  }

  thrift(): ThriftTagToMeal {
    return new ThriftTagToMeal({tag: this.tag, meal: this.meal});
  }

  static empty(): TagToMeal {
    return new TagToMeal(new ThriftTagToMeal({tag: "", meal: "1"}));
  }
}

export class Profile {
  type: string = "profile";

  id: string;
  name: string;
  color: string;
  sex?: 'f' | 'm';
  height?: number;
  weight?: number;
  birth?: number;
  activity?: number;
  meals: Meal[];
  tagsToMeal: TagToMeal[];
  price: string;

  constructor(x: ThriftProfile) {
    this.id = x.userId;
    this.name = x.name;
    this.color = x.color;
    this.height = x.height;
    this.weight = x.weight;
    this.birth = x.birth;
    this.activity = x.activity;
    this.meals = x.meals.map((x) => new Meal(x));
    this.tagsToMeal = x.tagsToMeal.map((x) => new TagToMeal(x));
    if (x.sex === 'f' || x.sex === 'm') {
      this.sex = x.sex;
    }
    this.price = x.price || Constants.DEFAULT_PRICE;
  }

  thrift(): ThriftProfile {
    return new ThriftProfile({
      userId: this.id,
      name: this.name,
      color: this.color,
      sex: this.sex,
      height: this.height,
      weight: this.weight,
      birth: this.birth,
      activity: this.activity,
      meals: _.uniq((this.meals || []).map((x) => x.thrift())),
      tagsToMeal: this.tagsToMeal.map((x) => x.thrift()),
      price: this.price,
    });
  }

  get bmi(): number {
    if (this.weight === undefined || this.height === undefined) { return 0; }
    return Math.round(this.weight / ((this.height / 100) * (this.height / 100)) * 100) / 100;
  }

  get years(): number | undefined {
    if (this.birth === undefined) {
      return undefined;
    }
    return new Date().getFullYear() - this.birth;
  }

  get bmr(): number {
    if (
      this.sex === undefined ||
      this.birth === undefined ||
      this.years === undefined ||
      this.height === undefined ||
      this.weight === undefined
    ) { return 0; }

    return Math.round(10 * this.weight +
      6.25 * this.height -
      5 * this.years +
      (this.sex === 'f' ? -161 : 5));
  }

  get er(): number {
    const bmr = this.bmr;
    if (bmr === 0 || this.activity === undefined) { return 0; }
    const activityRates: {[key: number]: number} = {
      0: 1.2,
      1: 1.375,
      2: 1.46,
      3: 1.55,
      4: 1.725,
      5: 1.9
    };
    if (activityRates[this.activity] === undefined) { return 0; }
    return Math.round(bmr * activityRates[this.activity]);
  }
  edit(field: string, value: string) {
    if (
      field === 'name' ||
      field === 'price' ||
      field === 'color'
    ) {
      this[field] = value;
    }
    if (
      field === 'sex' && (value === 'f' || value === 'm')
    ) {
      this[field] = value;
    }
    if (
      field === 'weight' ||
      field === 'height' ||
      field === 'activity' ||
      field === 'birth'
    ) {
      this[field] = Number.parseInt(value);
    }
  }
}

export default class Account {
  profile: string;
  shoppingListIgnored: string[];
  profiles: Profile[];
  currentProfile: Profile | undefined;

  constructor(x: ThriftAccount) {
    this.profile = localStorage.getItem("profile") || '';
    this.shoppingListIgnored = [];
    this.profiles = (x.profiles || []).map((x) => new Profile(x));
    this.currentProfile = this.profiles.find((x) => x.id === this.profile);
  }

  thrift(): ThriftAccount {
    return new ThriftAccount({
      profiles: this.profiles.map((x) => x.thrift()),
    });
  }

  copy(): Account {
    return new Account(this.thrift());
  }

  get active(): Profile | undefined {
    const profile = localStorage.getItem("profile");
    return this.profiles.find((x) => x.id === profile);
  }
  get activeI(): number {
    return this.profiles.findIndex((x) => x.id === this.profile) + 1
  }
  get mealNames(): {[key: string]: string} {
    const result: {[key: string]: string} = {};
    this.meals.forEach((x) => result[x.id] = x.name);
    return result;
  }

  get noProfile(): boolean {
    return this.profile === undefined || this.profile === "";
  }
  get isAddingNew(): boolean {
    return this.profiles.some((x) => x.id === "");
  }
  get meals(): Meal[] {
    return _.uniq([
      ...this.profiles.flatMap((x) => x.meals),
      new Meal("")
    ], false, (x) => x.id);
  }

  getRecommendations(profileId: string): RecommendationAccount {
    const profile = this.profiles.find((x) => x.id === profileId);
    if (profile === undefined) {
      return {
        age: 30,
        sex: 'f',
        pregnant: false,
        lactation: false,
      };
    }
    return {
      age: profile.years || 30,
      sex: profile.sex || '',
      pregnant: false,
      lactation: false,
    }
  }

  edit(profileId: string, field: string, value: string) {
    const p = this.profiles.find((x) => x.id === profileId);
    if (p === undefined) { return; }
    p.edit(field, value);
  }

  addProfile() {
    this.profiles.push(new Profile(new ThriftProfile({
        userId: "",
        name: "Nowy profil",
        color: "",
        meals: ["1", "4", "6"],
        tagsToMeal: [],
    })));
  }
  setProfile(profile: string) {
    this.profile = profile;
  }
}
