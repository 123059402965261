import * as _ from 'underscore';

import Cellar from 'cls/Cellar';
import CellarItem from 'cls/CellarItem';
import Ingredient from 'cls/Ingredient';
import IngredientsList from 'cls/IngredientsList';
import ManualItem from 'cls/ManualItem';
import MenuItem from 'cls/MenuItem';
import MenuItemsBase from 'cls/MenuItemsBase';
import Party from 'cls/Party';
import Quantity from 'cls/Quantity';
import RecipeIngredient from 'cls/RecipeIngredient';
import Utils from 'cls/Utils';
import { RecipeIngredient as ThriftRecipeIngredient } from 'thriftgen';
import { ManualItem as ThriftManualItem } from 'thriftgen/ManualItem';
import { ShoppingList as ThriftShoppingList } from 'thriftgen/ShoppingList';
import { ShoppingListStatus } from 'thriftgen/ShoppingListStatus';

export default class ShoppingList extends MenuItemsBase {
  type: string = "shopping-list";

  id: string;
  start: string;
  end: string;
  status: ShoppingListStatus;
  cellar: CellarItem[];
  cellarIds: string[];
  menu: string[];
  party: Party[];
  partyIds: string[];
  manual: ManualItem[];
  purchased: { [key: string]: boolean };
  ingredients: { [key: string]: Quantity };
  ingredientObjects: { [key: string]: Ingredient };

  constructor(x: ThriftShoppingList) {
    super([]);
    this.id = x.id;
    this.start = x.startDate;
    this.end = x.endDate;
    this.status = x.status;
    this.cellarIds = x.cellar || [];
    this.cellar = [];
    this.menu = x.menu;
    this.purchased = {};
    this.initPurchasedStatus();
    x.purchased.forEach((v, k) => this.purchased[k] = v);
    this.manual = x.manual.map((x) => new ManualItem(x));
    this.partyIds = x.party;
    this.party = [];
    this.ingredients = {};
    x.ingredients.forEach((v, k) => this.ingredients[k] = Quantity.fromQ(v));
    this.ingredientObjects = {};
  }

  setItems(items: MenuItem[]) {
    const byId: { [key: string]: MenuItem } = _.mapObject(
      _.groupBy(items, (x) => x.id),
      (v) => v[0]
    );
    const planItems = this.menu
      .map((x) => byId[x])
      .filter((x) => x !== undefined);

    this.items = planItems.sort(
      (a, b) => {
        if (a.prepDay === undefined || b.prepDay === undefined) {
          if (a.prepDay === undefined && b.prepDay === undefined) {
            return 0;
          }
          if (a.prepDay === undefined) {
            return 1;
          }
          return -1;
        }
        return a.prepDay.localeCompare(b.prepDay);
      }
    );
    this.initPurchasedStatus();
  }

  setIngredients(ix: Ingredient[]) {
    for (const i of ix) {
      if (this.ingredients[i.id] === undefined) {
        continue
      }
      this.ingredientObjects[i.id] = i;
      this.ingredients[i.id].p = i.portions;
      const g = this.ingredients[i.id].inG();
      if (g !== undefined) {
        this.ingredients[i.id] = g;
      }
    }
  }

  get ingredientsAsRecipeIngredients(): RecipeIngredient[] {
    if (_.keys(this.ingredientObjects).length === 0) {
      return [];
    }
    return _.keys(this.ingredients).map((k) => {
      const ingredients = [];
      if (this.ingredientObjects[k] !== undefined) {
        ingredients.push(this.ingredientObjects[k]);
      }
      return new RecipeIngredient(new ThriftRecipeIngredient({
        ingredientId: k,
        nutrition: 1,
        q: this.ingredients[k].q,
      }), ingredients);
    });
  }

  setParties(xs: Party[]) {
    this.party = this.partyIds
      .map((x) => xs.find((c) => c.id === x))
      .filter((x: Party | undefined): x is Party => x !== undefined);
  }

  setCellar(cellar: Cellar | null) {
    if (cellar === null) { return; }
    this.cellar = this.cellarIds
      .map((x) => cellar.items.find((c) => c.id === x))
      .filter((x: CellarItem | undefined): x is CellarItem => x !== undefined);
    this.initPurchasedStatus();
  }

  copy(): ShoppingList {
    const sl = new ShoppingList(this.thrift());
    sl.setItems(this.items);
    sl.setCellar(Cellar.fromItems(this.cellar));
    sl.setParties(this.party);
    return sl;
  }

  thrift(): ThriftShoppingList {
    return new ThriftShoppingList({
      id: this.id,
      startDate: this.start,
      endDate: this.end,
      status: this.status,
      cellar: this.cellarIds,
      menu: this.menu,
      purchased: new Map(_.keys(this.purchased).map((k) => [k, this.purchased[k]])),
      manual: this.manual.map((x) => new ThriftManualItem(x)),
      party: this.party.map((x) => x.id),
      ingredients: new Map(_.keys(this.ingredients).map((k) => [k, this.ingredients[k].q])),
      updated: "",
    });
  }

  addCellarItem(item: CellarItem) {
    this.cellar.push(item);
    this.cellarIds.push(item.id);
  }
  removeCellarItem(itemId: string) {
    const itemIndex = this.cellar.findIndex((x) => x.id === itemId);
    if (itemIndex === -1) { return; }
    this.cellar.splice(itemIndex, 1);
    this.cellarIds = this.cellar.map((x) => x.id);
  }

  addParty(item: Party) {
    this.party.push(item);
  }
  removeParty(itemId: string) {
    const itemIndex = this.party.findIndex((x) => x.id === itemId);
    if (itemIndex === -1) { return; }
    this.party.splice(itemIndex, 1);
  }

  setDates() {
    const dates = this.items
      .map((x) => x.prepDay)
      .filter((x): x is string => x !== undefined);
    const sorted = dates.sort();
    if (sorted.length === 0) {
      this.start = "";
      this.end = "";
      return;
    }
    this.start = sorted[0];
    this.end = sorted[sorted.length - 1];
  }

  addItem(item: MenuItem) {
    this.menu.push(item.id);
    super.addItem(item);
    this.setDates();
  }
  removeItem(itemId: string) {
    super.removeItem(itemId);
    this.setDates();
  }
  updateItem(item: MenuItem) {
    super.updateItem(item);
    this.setDates();
  }

  get prettyDates(): string {
    if (this.start === "" || this.end === "") {
      return "?? - ??";
    }
    const s = new Date(this.start);
    const e = new Date(this.end);

    const syyyy = s.getFullYear().toString();
    const smm = (s.getMonth() + 101).toString().substring(1);
    const sdd  = (s.getDate() + 100).toString().substring(1);
    const eyyyy = e.getFullYear().toString();
    const emm = (e.getMonth() + 101).toString().substring(1);
    const edd  = (e.getDate() + 100).toString().substring(1);

    if (s.getFullYear() !== e.getFullYear()) {
      return `${sdd}.${smm}.${syyyy} - ${edd}.${emm}.${eyyyy}`;
    }
    return `${sdd}.${smm} - ${edd}.${emm}`;
  }

  get dateRangeStatus(): 0 | 1 | 2 {
    if (this.start === "" || this.end === "") {
      return 2;
    }
    const s = new Date(this.start);
    const e = new Date(this.end);
    const now = new Date(Utils.todayDate());

    if (now < s) { return 2; }
    if (now > e) { return 0; }
    return 1;
  }

  static allTags(xs: ShoppingList[]): string[] {
    return _.chain(xs)
      .map((x) => MenuItem.allTags(x.items))
      .flatten()
      .uniq()
      .value();
  }

  initPurchasedStatus() {
    IngredientsList.shoppingList(this).forEach((x) => {
      if (!this.purchased[x.id]) {
        this.purchased[x.id] = false;
      }
    });
  }
  setPurchasedStatus(recipeIngredientId: string, status: boolean) {
    this.purchased[recipeIngredientId] = status;
  }
}
