import { concat, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { ActionTypes as CoreActionTypes } from '@stack/frontend-core/cls/redux';
import { LoadingItemType } from '@stack/frontend-core/cls/redux/actions';

import Messages from 'cls/Messages';
import { ActionTypes, AllActions } from 'cls/redux/actions';
import EpicType from 'cls/redux/epictype';

export function ofType<T>(key: string) {
  return (source: Observable<AllActions>): Observable<T> => source.pipe(
    filter(({ type }) => type === key),
    map((x: AllActions) => x as T),
  );
}

export function getEpic<
  ChildAction extends AllActions & {
    type: string,
    item?: LoadingItemType,
    items?: LoadingItemType[],
    action?: string,
    redirect?: (action: ChildAction, result: ApiCallResult) => string,
  },
  ApiCallResult,
>(
  actionType: ActionTypes,
  api: (action: ChildAction) => Observable<ApiCallResult>,
  resultActions: (action: ChildAction, result: ApiCallResult) => AllActions[],
): EpicType {
  return (action$: Observable<AllActions>) => action$.pipe(
    ofType<ChildAction>(actionType),
    mergeMap((action: ChildAction) => {
      return concat<AllActions[]>(
        of<AllActions>({
          type: CoreActionTypes.LoadingStart,
          item: action.item,
          items: action.items,
          action: action.action,
        }),
        api(action).pipe(
          mergeMap((result: ApiCallResult) => {
            if (action.redirect !== undefined) {
              window.location.replace(action.redirect(action, result));
            }
            return from<AllActions[]>([
              {
                type: CoreActionTypes.LoadingEnd,
                item: action.item,
                items: action.items,
                action: action.action,
              },
              ...resultActions(action, result),
            ]);
          }),
          catchError(err => {
            return concat<AllActions[]>(
              of<AllActions>({
                type: CoreActionTypes.LoadingEnd,
                item: action.item,
                items: action.items,
                action: action.action,
              }),
              of<AllActions>({
                type: CoreActionTypes.ToastStart,
                toast: Messages.error(),
              }),
              throwError(err),
            );
          }),
        ),
      )
    }),
  );
}