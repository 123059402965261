
export const Feature = (props: {
  title: string;
  description: string;
  imgs: {src: string, header: string}[];
}) => {
  return (
    <div className="d-flex flex-column align-items-start align-items-md-center mb-6 feature">
      <h3 className="text-uppercase text-md-center">{props.title}</h3>
      <p className="mb-md-3 text-md-center">{props.description}</p>

      <div className="d-none d-md-flex justify-content-around align-items-center align-self-stretch mt-2 mb-2">
        { props.imgs.map((img, imgi) => (
          img.src !== "" &&
          <h5 key={imgi} className="text-center feature-item">{img.header}</h5>
        ))}
      </div>
      <div className="d-none d-md-flex justify-content-around mt-2">
        { props.imgs.map((img, imgi) => (
          img.src !== "" &&
          <div key={imgi} className="feature-item">
            <img
              src={img.src}
              alt={props.title}
              className="img-fluid"
            />
          </div>
        ))}
      </div>

      <div className="d-md-none">
        { props.imgs.map((img, imgi) => (
          img.src !== "" &&
          <div key={imgi}>
            <h4 className="text-left mt-3">{img.header}</h4>
            <div className="mx-3">
              <img
                src={img.src}
                alt={props.title}
                className="img-fluid"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}